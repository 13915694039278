import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({ providedIn: "root" })
export class GroupService {
  constructor(private http: HttpClient) {}
  listOrganization() {
    return this.http
      .get(`${environment.apiUrl}/api/v1/group/organizations`)
      .pipe(
        map(data => {
          const organizations = data['data'].organizations || [];
          const selected_organization_id = data['data'].selected_organization_id || "";
          const all_orgs = data['data'].all_orgs || [];
          return { organizations, selected_organization_id, all_orgs};
        })
      );
  }

  searchUser(params) {
    return this.http
      .post(`${environment.apiUrl}/api/v1/group/search-user`, {params})
      .pipe(
        map(data => {
          const users = data['data'].users || [];
          return { users }
        })
      )
  }

  searchGroup(params: { id_or_name: string; excepted_id: string }) {
    return this.http
      .post(`${environment.apiUrl}/api/v1/group/search-group`, { params })
      .pipe(
        map(data => {
          const groups = data['data'].groups || [];
          return { groups };
        })
      );
  }
}
