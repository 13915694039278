import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { User } from "../models/user.model";
// import { environment } from "src/environments/environment";
import { environment } from "../../../environments/environment";
import { IParamgetValueUnique, IParamHandleCode } from "../models/function.model";
import { HelperFunctionService } from "./helper-function.service";
const wanakana = require("wanakana");

@Injectable({ providedIn: "root" })
export class HandleCodeService {
    private isTest: boolean = false;
    private isInputForm: boolean = true;
    constructor(
        private http: HttpClient,
    ) { }

    async handleCodejs(params: IParamHandleCode) {
        this.isTest = false;
        this.isInputForm = true;
        if (typeof params.isTest != "undefined") {
            this.isTest = params.isTest;
        }
        if (typeof params.isInputForm != "undefined") {
            this.isInputForm = params.isInputForm;
        }
        let dataModel: any;
        switch (params.table) {
            case "User":
                dataModel = new User(params.data);
                break;

            default:
                dataModel = new User(params.data);
                break;
        }
        const isInputForm = this.isInputForm;
        const codeJs = params.codeJs;
        const isImportCsv = params.codeJs;
        const fieldInput = params.fieldInput || null;
        const table = params.table;
        const target = params.target;
        const isWorkFlow = params.isWorkFlow;

        const helper = new HelperFunctionService(
            this.isTest, 
            this.http, 
            dataModel, 
            isWorkFlow,
            params.application_number || null
            );
        var returnFunction = await eval(`(async () => {${codeJs}})()`);
        // let dependence = this.getDependence(codeJs);

        if (this.isTest) {
            let errors = helper.getErrors();
            return {
                errors,
                returnFunction
            };
        }
        return returnFunction;
    }

    getDependence(codeJs) {
        let list = codeJs.match(/helper\.getValue\(["'](.*?)["']\)/g);
        list = list.map(e => {
            return e.replace(/helper\.getValue\(["'](.*?)["']\)/, '$1');
        });
        return list;
    }
}
