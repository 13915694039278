import {
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Component,
  ViewEncapsulation,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { ISelectOption } from "src/app/core/models/select-option.model";

@Component({
  selector: "select-multiple",
  templateUrl: "./selectMultiple.html",
  styleUrls: ["./selectMultiple.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelectMultiple implements OnInit, OnDestroy, OnChanges {
  @ViewChild("dropdownMultiple", { static: true }) dropdownMultiple;
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() value: any;
  @Input() styleClass: string;
  @Input() disabled: boolean | any;
  @Input() options: ISelectOption[];
  @Input() includeEmpty: boolean | any;
  @Input() optionFilter: boolean;
  @Output() valueChange = new EventEmitter();
  selectedOptions: ISelectOption[] = [];
  selectedOptionIDFromForm: string;

  _styleClass: string = "select-multiple-output";
  _panelStyleClass: string = "select-multiple-panel";
  _selectOptions: ISelectOption[] = [];
  _panelStyle: { [key: string]: string } = {};

  ngOnInit(): void {
    this.updateData();
    if (this.form) {
      const control = this.form.get(this.field.name);
      if (control) {
        control.valueChanges.subscribe((value) => {
          this.changeInputFromFormControl(value, control);
        });
      }
    }
    window.addEventListener("resize", this.onWindowResize);

    this.onWindowResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    setTimeout(() => {
      const panelWidth = (
        this.dropdownMultiple.el.nativeElement.getElementsByClassName(
          "select-multiple-output"
        )[0] || {}
      ).offsetWidth;
      const panelStyle = {};
      if (panelWidth) {
        panelStyle["width"] = `${panelWidth}px`;
      }
      if (Object.keys(panelStyle).length) {
        this._panelStyle = panelStyle;
      } else {
        this._panelStyle = {};
      }
    });
  };

  updateData() {
    let tempOptions: ISelectOption[] = [];
    if (this.options && this.options.length) {
      tempOptions = [...this.options];
    } else if (this.field && this.field.options && this.field.options.length) {
      tempOptions = [...this.field.options];
    } else {
      tempOptions = [];
    }
    if (this.includeEmpty) {
      // add empty to first
      const first = tempOptions[0];
      if (first && first.ID) {
        tempOptions.unshift({ ID: '', Name: '' });
      }
    } else {
      // remove empty from first
      const first = tempOptions[0];
      if (first && !first.ID) {
        tempOptions.shift();
      }
    }
    if (JSON.stringify(tempOptions) !== JSON.stringify(this._selectOptions)) {
      this._selectOptions = tempOptions;
    }
    let IDs: any = [];
    if (this.value && this.value.length) {
      if ((typeof this.value) === 'string') {
        if (this.value.includes(";")) {
          IDs = this.value.split(";");
        } else {
          IDs[0] = this.value;
        }
      } else {
        IDs = this.value;
      }
    } else if (this.selectedOptionIDFromForm) {
      IDs = this.selectedOptionIDFromForm.split(";");
    }

    this.selectedOptions = tempOptions.filter((o: ISelectOption) =>
      IDs.includes(o.ID)
    );
    const classList = ["select-multiple-output"];
    if (this.styleClass) {
      classList.push(this.styleClass);
    }
    this._styleClass = classList.join(" ");
    if (
      this.form &&
      this.field &&
      this.field.name &&
      this.disabled === undefined &&
      this.field.disable === undefined
    ) {
      const control = this.form.get(this.field.name);
      if (control) {
        this.field.disable = control.disabled;
      }
    }
  }

  onChangeSelectOption() {
    const IDs = (this.selectedOptions || []).map((o: ISelectOption) => o.ID);
    this.valueChange.emit(IDs);

    if (this.form) {
      const control = this.form.get(this.field.name);
      if (control) {
        control.setValue(IDs.join(";"));
      }
    }
  }

  changeInputFromFormControl(value, control?: AbstractControl) {
    this.selectedOptionIDFromForm = value || "";
    const IDs = this.selectedOptionIDFromForm.split(";");
    this.selectedOptions = this._selectOptions.filter((o: any) =>
      IDs.includes(o.ID)
    );
    if (control && this.field) {
      this.field.disable = control.disabled;
    }
  }

  convertOption(option) {
    return JSON.stringify(option);
  }
}
