import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

declare var $: any;

@Component({
  selector: "app-modal",
  templateUrl: "modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, OnChanges {
  @Input() dismiss;
  @Input() headerClass?: string;

  _headerClass: string = 'modal-header';

  constructor() {}

  ngOnInit() {
    this.updateFromComponentInput();
    $(document).ready(function () {
      let modalContent: any = $(".modal-content");
      let modalHeader = $(".header-modal-content");
      modalHeader.addClass("cursor-all-scroll");
      modalContent.draggable({
        handle: ".header-modal-content",
        containment: "#parent",
        scroll: false,
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateFromComponentInput();
  }

  updateFromComponentInput() {
    const classList = ['modal-header']
    if (this.headerClass) {
      classList.push(this.headerClass);
    }
    this._headerClass = classList.join(" ");
  }
}
