import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { createHttpParams } from "../../blocks/utils/http-helper";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class GroupManagerService {
  constructor(private http: HttpClient) {}

  getGroupList(param) {
    const params = createHttpParams({
      page: param.page,
      limit: param.numberPerPage,
      orderField: param.order_by,
      orderBy: param.order_direction,
      UpdateFlags: param.search_update_flag,
      ExpiredPassword: param.ExpiredPassword,
      LockFlag: param.LockFlag,
      DeleteFlag: param.DeleteFlag,
      UpdateUserID: param.updateUser,
      EffectiveDate: param.EffectiveDate,
      UpdateDate: param.UpdateDate,
      fieldSearch: param.search_by_field,
      valueSearch: param.search
    });

    return this.http
      .get(`${environment.apiUrl}/api/v1/group/get_list`, { params})
      .pipe(
        map(data => {
          const users = data["data"]["items"].map(user => user);
          const total = data["data"]["total"];
          const headers = data["data"]["headers"];
          const validations = data["data"]["validations"];
          const displaySetting = data["data"]["display_setting"];
          return { users, total, headers, validations,displaySetting };
        })
      );
  }

  getConditionToSearch() {
    return this.http
      .get(`${environment.apiUrl}/api/v1/group/get_conditions`)
      .pipe(
        map(data => {
          const fieldSearchKeywords = data["fields"];
          const extractionIds = data["extractionIds"];
          const updateUserName = data["admins"];
          const attributes = data['attributes'];
          const attributeMultipleSelect = data['attribute_multiple_select']
          const isShowUpdateFlagAndExternalID = data['isShowUpdateFlagAndExternalID']
          const allOrgs = data['all_orgs'];

          return {
            fieldSearchKeywords,
            extractionIds,
            updateUserName,
            attributes,
            isShowUpdateFlagAndExternalID,
            attributeMultipleSelect,
            allOrgs,
          };
        })
      );
  }

  listMemberInGroup(groupID) {
   return this.http
      .get(`${environment.apiUrl}/api/v1/group/${encodeURIComponent(groupID)}/list-user`)
      .pipe(
        map(data => {
          const users = data['data'].users || [];
          return { users }
        })
      );
  }

  createGroup(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/group/create`, data);
  }

  updateGroup(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/group/update`, data);
  }

  getGroupDetail(groupID) {
    return this.http
      .get(`${environment.apiUrl}/api/v1/group/${encodeURIComponent(groupID)}/show`)
      .pipe(
        map(user => user["data"]),
        map(user => {
          return user;
        })
      );
  }

  downloadTemplate() {
    return this.http.get(`${environment.apiUrl}/api/v1/group/download`, {
        observe: "response",
        responseType: "blob" as "json"
    });
}

importCsv(file, delimiter) {
    const formData = new FormData();
    formData.append('file', file);
    const params = {
      delimiter: delimiter,
    }
    return this.http
      .post(`${environment.apiUrl}/api/v1/group/import`, formData, {
        params: createHttpParams(params),
      })
    .pipe(
      map(data => {
          const totalRow = data['data']['totalRow']
          const error = data['data']['error']
          return {totalRow, error};
      })
    );
}

extractCsv(param, newParams) {
    const params = createHttpParams({
      name_file: newParams.name_file,
      end_character: newParams.end_character,
      page: param.page,
      limit: param.numberPerPage,
      orderField: param.order_by,
      orderBy: param.order_direction,
      UpdateFlags: param.search_update_flag,
      ExpiredPassword: param.ExpiredPassword,
      LockFlag: param.LockFlag,
      DeleteFlag: param.DeleteFlag,
      UpdateUserID: param.updateUser,
      EffectiveDate: param.EffectiveDate,
      UpdateDate: param.UpdateDate,
      fieldSearch: param.search_by_field,
      valueSearch: param.search
    });
    return this.http.get(`${environment.apiUrl}/api/v1/group/extract`, {
        params,
        observe: "response",
        responseType: "blob" as "json"
    });
}

  deleteGroup(groupId) {
    return this.http
        .delete(`${environment.apiUrl}/api/v1/group/${encodeURIComponent(groupId)}/delete`);
  }

  listGroupAssignInGroup(groupID: string) {
    return this.http
      .get(
        `${environment.apiUrl}/api/v1/group/${encodeURIComponent(
          groupID
        )}/list-assigned-group`
      )
      .pipe(
        map(data => {
          const groups = data['data'].groups || [];
          return { groups };
        })
      );
  }
}
