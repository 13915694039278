import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { StateStorageService } from "src/app/services/state-storage.service";

@Component({
  selector: "app-user-login",
  templateUrl: "./user-login-saml.component.html",
})
export class UserLoginSamlComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private authenService: AuthService,
    private stateStorageService: StateStorageService
  ) {}

  ngOnInit() {
    this.getTokenFromSaml();
  }
  ngOnDestroy(): void {}

  private async getTokenFromSaml() {
    const samlToken = this.authenService.getTokenFromCookie();
    if (samlToken) {
      const redirect = this.stateStorageService.getUrl();
      if (redirect) {
        this.stateStorageService.storeUrl(null);
        this.router.navigate([redirect]);
      } else {
        this.router.navigate(["/user"]);
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }
}
