import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Common } from "src/app/core/constants/common";

@Injectable()
export class IpAccessInterceptor implements HttpInterceptor {
  constructor(
    private router: Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: any) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status == Common.HTTP_NOT_ALLOW_ACCESS) {
              this.router.navigate(["404"]);
            }
          }
        }
      )
    );
  }
}
