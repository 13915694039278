import { SelectMultiple } from "./atoms/selectMultiple/selectMultiple";
import { DropDownComponent } from "./atoms/dropdown";
import { CheckBoxComponent } from "./atoms/checkbox";
import { ModalComponent } from "./modal/modal.component";
import { NgModule } from "@angular/core";
import { SharedLibsModule } from "./shared-libs.module";
import { TextBoxComponent } from "./atoms/textbox";
import { SelectFilter } from "./atoms/selectFilter/selectFilter";
import { ExportCSVComponent } from "./organisms/export-csv/export-csv.component";
import { ImportCSVComponent } from "./organisms/import-csv/import-csv.component";
import { IncrementalSearch } from "./atoms/incremental-search/incremental-search";
import {ExportCSVUserComponent } from "./organisms/export-csv-user/export-csv-user.component";
import { SearchUserDialogAdminComponent } from "./organisms/search-user-dialog/search-user-dialog.component";
import { SearchGroupDialogComponent } from './organisms/search-group-dialog/search-group-dialog.component';

@NgModule({
  imports: [SharedLibsModule],
  declarations: [
    ModalComponent,
    TextBoxComponent,
    CheckBoxComponent,
    DropDownComponent,
    SelectFilter,
    SelectMultiple,
    IncrementalSearch,
    ExportCSVComponent,
    ImportCSVComponent,
    ExportCSVUserComponent,
    SearchUserDialogAdminComponent,
    SearchGroupDialogComponent,
  ],
  exports: [
    ModalComponent,
    TextBoxComponent,
    CheckBoxComponent,
    DropDownComponent,
    SelectFilter,
    SelectMultiple,
    IncrementalSearch,
    ExportCSVComponent,
    ImportCSVComponent,
    ExportCSVUserComponent,
    SearchUserDialogAdminComponent,
    SearchGroupDialogComponent,
  ],
})
export class AktSharedCommonModule {}
