import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'dropdown',
    template: `
      <div [formGroup]="form" class="w-100">
      <select
        *ngIf="!field.check"
        class="form_dynamic_control"
        [id]="field.name"
        [disabled]="field.disable"
          [formControlName]="field.name" (change)="onChangeEvent($event)">
          <option *ngFor="let opt of field.options" [value]="opt.ID">{{opt.label}}</option>
        </select>
        <select-filter
          *ngIf="field.check"
          class="select-filter-admin"
          styleClass="form_dynamic_control"
          [form]="form"
          [field]="field"
          (valueChange)="onChangeEvent($event)"
          [options]="listOptions"
          [filter]="false"
          [virtualScroll]="false"
        ></select-filter>
      </div>
      `,
      styleUrls: ['./style.scss']
    })

export class DropDownComponent implements OnChanges {
  @Input() options = [];
    @Input() field: any = {};
    @Input() form: UntypedFormGroup;
    @Output() onChange = new EventEmitter();
    listOptions: any [];
    constructor() {
    }

    ngOnInit() {
      this.listOptions = this.field.options.map((u) => ({
        ID: u.ID,
        Name: u.label
      }))
    }

    public onChangeEvent($event) {
      this.onChange.emit($event);
    }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.listOptions = this.field.options.map(u => ({
        ID: u.ID,
        Name: u.label,
      }));
    }
  }
}
