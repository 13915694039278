export const setting_basic = {
  keyspider_basic_setting: 'Keyspider基本設定',
  keep : '保存',
  number_of_purchased_licenses : '購入ライセンス数',
  enter_the_number_of_licenses_you_have_purchased : 'ご購入いただいているライセンス数を入力します。',
  multifactor_authentication_when_accessing_the_administrator_screen : '管理者画面アクセス時の多要素認証',
  when_enabled_twofactor_authentication_email_transmission_of_passcode_will_be_enabled_when_accessing_the_management_screen : '有効にすると管理画面のアクセス時に二段階認証（パスコードのメール送信）が有効になります。',
  valid : '有効',
  invalid : '無効',
  passcode_length : 'パスコードの長さ',
  passcode_valid_time : 'パスコードの有効時間',
  minutes : '分',
  expiration_check_time : '有効期限切れチェック時刻',
  set_the_time_to_automatically_expire_from_the_user_expiration_date_item : 'ユーザーの「有効期限」項目から自動で有効期限切れにする時刻を設定します。',
  time : '時',
  send_expiration_check_email : '有効期限切れチェックメール送信',
  send_a_warning_email_to_users_who_are_about_to_expire : '有効期限切れが近いユーザーに警告のメールを送信します。',
  allow_profile_editing_on_the_user_screen : '利用者画面でのプロファイル編集を許可する',
  when_set_to_enable_users_can_edit_their_own_information_on_the_user_screen : '「有効」にすると利用者画面でユーザーが自分自身の情報を編集できるようになります。',
  workflow_privileged_user : 'ワークフローの特権ユーザ',
  select_users_who_can_view_and_approve_all_requests_used_to_process_requests_with_no_approvers : 'すべての申請を閲覧でき、かつ承認ができるユーザーを選択します。承認者が不在になった申請の処理に使用します。',
  user_user_management_authority : '利用者ユーザー管理の権限',
  user_user_management_rights_can_be_added_display_items_can_be_restricted_in_user_screen_settings_for_each_added_authority : '利用者ユーザー管理の権限を追加することができます。追加した権限ごとに「利用者画面設定」で表示項目を制限できます。',
  this_permission_is_being_used_and_cannot_be_deleted : 'この権限が利用されていますので削除できません',
  addition : '追加',
  do_not_permission_screen : 'この画面にアクセスする権限がありません。',
  this_user_is_already_selected : 'このユーザは既に選択されています。',
  this_permission_is_already_entered : 'この権限は既に入力されています。',
  updated_data : 'データを更新しました。',
};