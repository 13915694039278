import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class LoadingService {
  loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  setShowLoading(loading: boolean) {
    this.loading.next(loading);
  }
}
