import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GroupService } from '../services/group.service';
import { IGroup } from '../models/group-model';
import { ISelectOption } from 'src/app/core/models/select-option.model';

@Component({
  selector: 'app-search-group-dialog',
  templateUrl: './search-group-dialog.component.html',
  styleUrls: ['./search-group-dialog.component.scss'],
})
export class SearchGroupDialogComponent {
  @ViewChild('searchGroupDialog', { static: true })
  searchGroupDialog: ElementRef;
  modalReference: NgbModalRef;
  @Input() public groupId: string;
  @Output() onAddGroup = new EventEmitter<IGroup[]>();
  searchGroupInput: ISelectOption = undefined;
  searchGroupResults: IGroup[] = [];
  selectedGroupListToAdd: IGroup[] = [];
  constructor(
    private modalService: NgbModal,
    private groupService: GroupService
  ) {}

  showModal() {
    this.searchGroupInput = {};
    this.searchGroupResults = [];
    this.selectedGroupListToAdd = [];
    this.modalReference = this.modalService.open(this.searchGroupDialog, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'search-group-assign-dialog',
      backdropClass: 'search-group-assign-dialog-backdrop',
    });
  }

  closeModal() {
    this.modalReference.close();
  }

  handleSearchGroup(query: string) {
    const params: { id_or_name: string; excepted_id: string } = {
      id_or_name: query,
      excepted_id: this.groupId,
    };
    this.groupService.searchGroup(params).subscribe(
      res => {
        this.searchGroupResults = res.groups;
      },
      () => {
        this.searchGroupResults = [];
      }
    );
  }

  handleChangeGroup() {
    if (!this.searchGroupInput) {
      return;
    }
    if (
      !this.selectedGroupListToAdd.find(
        (gr: IGroup) => gr.ID == this.searchGroupInput.ID
      )
    ) {
      this.selectedGroupListToAdd.push({
        ID: this.searchGroupInput.ID,
        Name: this.searchGroupInput.Name,
      });
    }
  }

  deleteSelectedGroup(index: number) {
    this.selectedGroupListToAdd.splice(index, 1);
  }

  clickedSubmit() {
    this.onAddGroup.emit(this.selectedGroupListToAdd);
    this.closeModal();
  }

  clickedCancel() {
    this.closeModal();
  }
}
