import { common } from "./ja/common";
import { auto_authority } from "./ja/pages/auto_authority";
import { group_assign } from "./ja/pages/group_assign";
import { user } from "./ja/pages/user";
import { setting_permission_user } from "./ja/pages/setting_permission_user";
import { user_profile } from "./ja/pages/user_profile";
import { daily_report } from "./ja/pages/daily_report";
import { user_effective } from "./ja/pages/user_effective";
import { master_manager } from "./ja/pages/master_manager";
import { saas_link_setting } from "./ja/pages/saas_link_setting";
import { user_attribute } from "./ja/pages/user_attribute";
import { automatic_group_creation } from "./ja/pages/automatic_group_creation";
import { inventory } from "./ja/pages/inventory";
import { log } from "./ja/pages/log";
import { mail_setting } from "./ja/pages/mail_setting";
import { password_policy } from "./ja/pages/password_policy";
import { saml_setting } from "./ja/pages/saml_setting";
import { setting_basic } from "./ja/pages/setting_basic";
import { user_display_setting } from "./ja/pages/user_display_setting";
import { validation } from "./ja/pages/validation";
import { auto_group } from "./ja/pages/auto_group";
import { licenses } from "./ja/pages/licenses";
import { change_password } from "./ja/pages/change_password";
import { setting_basic_ini } from "./ja/pages/setting_basic_ini";
import { rpa } from "./ja/pages/rpa";
import { sidebar } from "./ja/pages/sidebar";
import { login } from "./ja/pages/login";
import { user_login } from "./ja/pages/user_login";
import { workflow_user_apply } from "./ja/pages/workflow_user_apply";
import { import_export_csv } from "./ja/pages/import_export_csv";
import { dashboard } from "./ja/pages/dashboard";
import { functions } from "./ja/pages/functions";

export default {
  common,
  pages: {
    auto_authority,
    group_assign,
    user,
    setting_permission_user,
    daily_report,
    user_effective,
    user_profile,
    master_manager,
    inventory,
    saas_link_setting,
    log,
    mail_setting,
    password_policy,
    saml_setting,
    user_attribute,
    automatic_group_creation,
    setting_basic,
    user_display_setting,
    validation,
    auto_group,
    licenses,
    change_password,
    setting_basic_ini,
    rpa,
    sidebar,
    login,
    user_login,
    workflow_user_apply,
    import_export_csv,
    dashboard,
    functions
  },
};
