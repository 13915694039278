import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// text,email,tel,textarea,password,
@Component({
  selector: 'textbox',
  template: `
      <div [formGroup]="form">
        <input
          *ngIf="!field.multiline"
          [autocomplete]="autocomplete"
          [attr.type]="field.type"
          class="form_dynamic_control"
          [attr.disabled]="field.disabled ? true: null"
          [id]="field.name"
          [name]="field.name"
          [formControlName]="field.name"
          [placeholder]="!!field.placeholder ? field.placeholder : ''"
          readonly
          onfocus="this.removeAttribute('readonly');">
        <textarea
          *ngIf="field.multiline"
          [class.is-invalid]="isDirty && !isValid"
          [formControlName]="field.name"
          [id]="field.name"
          [rows]="field.rows || 9"
          class="form-control"
          [placeholder]="field.placeholder"
          [attr.disabled]="field.disabled ? true: null"
          [attr.maxlength]="field.maxlength ? field.maxlength : null"
        ></textarea>
      </div>
    `,
  styleUrls: ['./style.scss']
})
export class TextBoxComponent {
  @Input() field: any = {};
  @Input() autocomplete: any = 'off';
  @Input() form: UntypedFormGroup;
  @Input() disabled;

  get isValid() {
    return this.form.controls[this.field.name].valid;
  }
  get isDirty() { return this.form.controls[this.field.name].dirty; }

  constructor() {
  }
}
