import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  @Input() targetElement;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.targetElement) {
      setTimeout(() => this.el.nativeElement.querySelector(`.${this.targetElement}`).focus(), 0);
    } else {
      this.el.nativeElement.focus();
    }
  }
}
