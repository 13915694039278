import {
  Component,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef
} from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CODE_CHARACTER_TYPE, END_CHARACTER_TYPE } from "../models/csv-model";
@Component({
  selector: "app-export-csv-user",
  templateUrl: "./export-csv-user.component.html",
  styleUrls: ["./export-csv-user.component.scss"],
})
export class ExportCSVUserComponent {
  @ViewChild('exportCSVUserModal', { static: true }) exportCSVUserModal: ElementRef;
  @Output() onCloseModals = new EventEmitter();
  @Output() onExportCsv = new EventEmitter();
  @Input() public tableName;
  modalReference: NgbModalRef;
  fileName: string;
  params: any;
  codeCharacter: any;
  endCharacter: any;
  codeCharacterType = CODE_CHARACTER_TYPE;
  endCharacterType = END_CHARACTER_TYPE;
  constructor(
    private modalService: NgbModal,
  ) {}

  showModal() {
    this.fileName = this.tableName
    this.codeCharacter = this.codeCharacterType.SJIS
    this.endCharacter= this.endCharacterType.COMMA
    this.modalReference = this.modalService.open(this.exportCSVUserModal, {
      backdrop: "static",
      keyboard: false,
      size: "xl",
      windowClass: "ue-user-dialog ksm-custom-modal--size-110-r",
    });
  }

  closeModal() {
    this.modalReference.close();
  }

  extractCsv() {
    this.params = {
      name_file: this.fileName || this.tableName,
      code_character: this.codeCharacter,
      end_character: this.endCharacter
    }
    this.onExportCsv.emit(this.params);
    this.closeModal();
  }
}
