export const user_effective = {

    announcement_information_management : "発令情報管理",
    company_administrator : "（企業管理者）",
    registration_date : "登録日",
    from : "から",
    to : "まで",
    date_of_issue : "発令日",
    registrant_search : "登録者検索",
    full_name : "氏名",
    target_search : "対象者検索",
    affiliation : "所属組織",
    status : "ステータス",
    not_reflected : "未反映",
    reflected : "反映済み",
    cancel : "取消",
    synchronous_execution : "同期実行",
    subject : "件",
    no_matching_data_found : "該当データが見つかりません",
    acquiring_data : "データ取得中",
    proclamation : "発令",
    registered_date : "登録日時",
    target_audience : "対象者",
    target_person_name : "対象者氏名",
    registered_person : "登録者",
    changer : "更新者",
    situation : "状態",
    do_not_permission_screen : "この画面にアクセスする権限がありません。",
    invoked_a_synchronous_command : "同期コマンドを呼び出しました。",
    none : "なし",
    add : {
        edit : "編集",
        basic_information : "基本情報",
        proclamation : "発令",
        date_of_issue : "発令日",
        reflection_date : "反映日",
        registered_person : "登録者",
        changer : "更新者",
        situation : "状態",
        system_usage_authority : "システム利用権限",
        assigned_license : "割当てライセンス",
        custom_field : "カスタム項目",
        role_field : "システム利用権限",
        external_field : "更新フラグ・外部ID",
        restoration : "復元",
        cancel : "取消",
        keep : "保存",
        close_up : "閉じる",
        please_enter_the_date_of_issue : "発令日を入力してください。",
        please_enter : "を入力してください。",
        data_registered : "データを登録しました。",
        restored : "復元しました",
        canceled : "取消しました",
    }

}