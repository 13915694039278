export const setting_basic_ini = {
  keyspider_basic_setting: 'Keyspider動作設定',
  basic_setting : '基本設定',
  keep : '保存',
  capture_process : '取込処理',
  extraction_output_processing : '抽出・出力処理',
  db_master: 'マスターDBマッピング',
  keyspider_ini_file_storage: 'KeySpider.iniファイル格納ディレクトリ',
  do_not_permission_screen : 'この画面にアクセスする権限がありません。',
  updated_data : 'データを更新しました。',
};