export const saml_setting = {
    title: 'シングルサインオン設定',
    no_data: '該当データが見つかりません',
    loading: 'データ取得中',
    update_title: '編集',
    create_title: '新規登録',
    column_name: {
        name: 'IdP名',
        label: 'IdP表示名',
        key: 'NameID属性',
        entity_id: 'エンティティID',
        sso_url: '認証エンドポイントURL',
        sl_url: 'SLOエンドポイントURL',
        x509: '署名用のIdP証明書',
        metadata: 'Metadata',
        is_public: 'ステータス'
    },
    msg_success: 'Successfully',
    popup: {
        save: '保存',
        delete: '削除',
        close: '閉じる',
        delete_confirm: '確認を削除',
        msg_confirm: 'このレコードを削除しますか？',
        accept: 'はい',
        cancel: 'いいえ',
    },
    msg_err: {
        required: 'を入力してください。',
        not_url: 'URLの形式が正しくありません。',
        url: 'フィールドの値はURLではありません',
    },
    msg_create_success: 'データを登録しました。',
    msg_update_success: 'データを更新しました。',
};
