import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input
} from "@angular/core";
import { CODE_CHARACTER_TYPE, END_CHARACTER_TYPE } from "../models/csv-model";
@Component({
  selector: "app-export-csv",
  templateUrl: "./export-csv.component.html",
  styleUrls: ["./export-csv.component.scss"],
})
export class ExportCSVComponent implements OnInit {
  @Output() onCloseModals = new EventEmitter();
  @Output() onExportCsv = new EventEmitter();
  @Input() public tableName;
  fileName: string;
  params: any;
  codeCharacter: any;
  endCharacter: any;
  codeCharacterType = CODE_CHARACTER_TYPE;
  endCharacterType = END_CHARACTER_TYPE;
  constructor(

  ) { }

  ngOnInit() { 
    this.fileName = this.tableName
    this.codeCharacter = this.codeCharacterType.SJIS
    this.endCharacter= this.endCharacterType.COMMA
  }

  extractCsv() {
    this.params = {
      name_file: this.fileName || this.tableName,
      code_character: this.codeCharacter,
      end_character: this.endCharacter
    }
    this.onExportCsv.emit(this.params);
    this.closeModal();
  }

  closeModal() {
    this.onCloseModals.emit();
  }
}
