export const password_policy = {
    title: 'パスワードポリシー設定',
    save: '反映',
    permission_change_or_reset: 'パスワード変更、リセットの権限',
    allow_change: 'パスワードの変更をユーザーに許可する',
    allow_reset: 'パスワードのリセットをユーザーに許可する',
    password_expiration: 'パスワードの有効期限',
    password_expiration_day: 'パスワードの有効期限（日数で指定）',
    lock_account_on_expiration: '有効期限切れでアカウントをロックする',
    execution_time_check_expiration: '有効期限切れチェック処理実行時刻',
    unlock_with_password_reset: 'パスワードリセットでロックを解除する',
    password_complexity: 'パスワードの複雑さ',
    min_password_length: 'パスワードの最低文字数',
    max_password_length: 'パスワードの最大文字数',
    from_the_following: '以下の中から',
    use_more_than_one: 'つ以上を使う',
    upper_character: '英字（大文字）A～Z',
    lower_character: '英字（小文字）a～z',
    number_character: '数字　0～9',
    symbol_character: '記号&#40;!"#$%&#38;\'&#40;&#41;*+,-./:;&#60;=&#62;?@&#91;¥&#93;^_&#123;|&#125;¯&#41;',
    limit_reuse_password: '過去のパスワードの再利用制限回数',
    err_msg: {
        min_password_length: 'パスワードの最大文字数の設定値より小さい値を設定していください。',

    }
};
