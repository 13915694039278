export const rpa = {
  rpa_link: 'RPA連携',
  csv_file: 'CSVファイル',
  saas_selection: 'SaaS選択',
  file_selection : 'ファイル選択',
  action : 'アクション',
  execution : '実行',
  cooperation_history : '連携履歴',
  response : 'レスポンス',
  status : 'ステータス',
  registered_date : '登録日時',
  data : 'データ',
  there_is_no_data : 'データがありません',
  csv_bulk_import : 'CSV一括インポート',
  register_csv_records_one_by_one : 'CSVのレコードを1件ずつ登録'
};