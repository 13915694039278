export const licenses = {
  role_license_settings : 'ロール（ライセンス）設定',
  company_administrator : '（企業管理者）',
  create_new : '新規作成',
  subject : '件',
  no_matching_data_found : '該当データが見つかりません',
  acquiring_data : 'データ取得中',
  edit : '編集',
  sign_up : '新規登録',
  delete : '削除',
  normal : '正常',
  external : '外部',
  name : '名称',
  service : 'サービス',
  send_value_to_service : 'サービスへの送信値',
  number_of_users : '保有ユーザー数',
  add: {
    keep : '保存',
    delete : '削除',
    restoration : '復元',
    close_up : '閉じる',
    please_enter : 'を入力してください。',
    data_registered : 'データを登録しました。',
    updated_data : 'データを更新しました。',
    the_license_is_removed : 'ライセンスが削除されます。',
    are_you_sure_you_want_to_delete_it : '本当に削除してよろしいでしょうか？',
    update_modified_by_modified_date_modified_flag : '更新者、更新日付、更新フラグを更新する',
    update_flag : '更新フラグ',
    verification : '確認',
    yes : 'はい',
    no : 'いいえ',
  }
};