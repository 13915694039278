export const user_display_setting = {
  display_item_setting : '表示項目設定',
  unique_key_attribute : '一意キー属性（ユーザー項目)',
  keep : '保存',
  the_selected_value_is_not_required : '選択した値は必須項目ではありません。',
  make_it_a_required_item_on_the_validation_setting_screen : 'バリデーション設定画面で必須項目にしてください。',
  reflect : '反映',
  user : 'ユーザー',
  no_matching_data_found : '該当データが見つかりません',
  acquiring_data : 'データ取得中',
  organization_information_management : '組織情報管理',
  group_management : 'グループ管理',
  privilege_assignment_rule : '権限割当てルール',
  group_assignment_rule : 'グループ配属ルール',
  project_ID: '項目ID',
  project_name: '項目名称',
  public: '公開',
  show_in_list: '一覧に表示',
  required: '必須',
  hour: '時',
  minutes: '分',
  user_defined_function: 'ユーザー定義関数',
  second: '秒',
  do_not_permission_screen: 'この画面にアクセスする権限がありません。',
  no_data_to_update: '更新するデータがありません。',
  no_enter_comma: '項目名称にコンマを使うことができません。',
  update_data: 'データを更新しました。',
  cannot_be_made_private_msg: '他の機能でこの属性を利用していますので非公開することができません'
};
