import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { IParamgetValueUnique } from "../models/function.model";


export class HelperFunctionService {

    private isTest: boolean = false;
    private isWorkFlow: boolean = false;
    private http: HttpClient;
    private data: object;
    private errors = [];
    private application_number:any = null;

    constructor(
        isTest: boolean,
        http: HttpClient,
        data: object,
        isWorkFlow: boolean,
        application_number: any = null,
    ) {
        this.isTest = isTest;
        this.http = http;
        this.data = data;
        this.isWorkFlow = isWorkFlow;
        this.application_number = application_number;
    }

    async checkUnique(params: IParamgetValueUnique) {        params.isTest = this.isTest;
        params.isWorkFlow = this.isWorkFlow;
        if(params.isWorkFlow){
            params.application_number = this.application_number,
            params.apply_content = params.apply_content || this.data['apply_content']
        }
        let res: any = await this.http
            .post(`${environment.apiUrl}/api/v1/function/check-value-unique`, params)
            .toPromise();

        return res.data;
    }

    getValue(key) {
        if (typeof this.data[key] === 'undefined') {
            this.errors.push('Property ' + key + ' is not exits');
        }
        return this.data[key];
    }

    getErrors() {
        return this.errors
    }
}
