export const user_profile = {
    login_user_information : "プロファイル",
    edit : "編集",
    basic_information : "基本情報",
    custom_field : "カスタム項目",
    system_usage_authority : "システム利用権限",
    detail : "詳細",
    assigned_license : "割当てライセンス",
    confirm : "確定",
    clear : "クリア",
    close_up : "閉じる",
    delete : "削除",
    lock : "ロック",
    normal : "正常",
    external : "外部",
    uppercase_alphabet : "アルファベット大文字",
    capital_letters : "アルファベット大文字、",
    lower_case : "小文字",
    lowercase : "小文字、",
    numbers : "数字",
    numbers_2 : "数字、",
    symbol : "記号",
    must_contain_all_char_msg : "パスワードには、次の種類の文字を全て使う必要があります。（",
    the_password_should_be : "パスワードには、次の",
    from_kind_letters : "種類の文字から",
    you_must_use_more_than_one_type_of_character : "種類以上の文字を使う必要があります。（",
    password_changed : "パスワードを変更しました。",

}