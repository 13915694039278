import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {finalize, first} from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { StateStorageService } from "src/app/services/state-storage.service";
import {TranslateService} from "@ngx-translate/core";
import {CookieHelper} from "../../core/helper/cookie-helper";
import {Common} from "../../core/constants/common";
import {lang} from "../../../lang";
import {LoadingService} from "../../loading/service/loading";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ['../../../styles.scss', 'login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  submitted = false;
  returnUrl: string;
  error = "";
  authenticationError = false;
  showFormLogin: boolean = false;
  checkIpDone = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private stateStorageService: StateStorageService,
    private translate: TranslateService,
    private loadingService: LoadingService,
  ) {
    Object.keys(lang).forEach((langKey: string) => {
      this.translate.setTranslation(langKey, lang[langKey]);
    });
    this.translate.use(Common.LOCALE_DEFAULT);
    CookieHelper.setCookie('locale', Common.LOCALE_DEFAULT, 365);
  }

  ngOnInit() {
    this.checkIpAddress();
    this.route.queryParams.subscribe((params: any) => {
      if (
        params &&
        params["login-from-user"] &&
        params["user-id"] &&
        params.expire &&
        params.token
      ) {
        this.showFormLogin = false;
        const userID: string = params["user-id"];
        const expire: string = params.expire;
        const loginToken: string = params.token;
        this.loginFromUser(userID, expire, loginToken);
      } else {
        this.showFormLogin = true;
        this.loginForm = this.formBuilder.group({
          userId: ["", Validators.required],
          password: ["", Validators.required],
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = params.returnUrl || "/";
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
  this.loadingService.setShowLoading(true);
    this.authService
      .loginAdmin({
        user_id: this.f.userId.value,
        password: this.f.password.value,
      })
      .pipe(finalize(() => {
        this.loadingService.setShowLoading(false);
      }))
      .subscribe(
        () => {
          this.authenticationError = false;
          this.onLoginSuccess();
        },
        () => {
          this.authenticationError = true;
        }
      );
  }

  private loginFromUser(userID: string, expire: string, loginToken: string) {
    this.authService
      .loginAdmin({
        user_id: userID,
        expire: expire,
        token: loginToken,
      })
      .pipe(first())
      .subscribe(
        () => {
          this.onLoginSuccess();
        },
        () => {
          this.authService.logoutAdmin();
        }
      );
  }

  private onLoginSuccess() {
    let redirect = this.stateStorageService.getUrl();
    if (redirect) {
      this.stateStorageService.storeUrl(null);
    } else {
      redirect = '/dashboard';
    }
    this.router.navigate([redirect]).then(() => {
      window.location.reload();
    });
  }

  get userId() {
    return this.loginForm.controls.userId;
  }

  get password() {
    return this.loginForm.controls.password;
  }

  checkIpAddress() {
    return new Promise((resolve) => {
      this.authService.checkIpAddress()
      .pipe(
        finalize(() => (this.checkIpDone = true))
      ).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          resolve(err);
        }
      );
    });
  }
}
