export const auto_group = {
  group_assignment_rule : 'グループ配属ルール',
  company_administrator : '（企業管理者）',
  item_selection : '項目選択',
  search_value : '＝検索値',
  update_date : '更新日',
  changer : '更新者',
  create_new : '新規作成',
  subject : '件',
  other_than : '以下以外',
  dot : '・',
  all : '全て',
  no_matching_data_found : '該当データが見つかりません',
  acquiring_data : 'データ取得中',
  edit : '編集',
  sign_up : '新規登録',
  condition_registration : '条件登録',
  target_group : '対象グループ',
  name : '名称',
  affiliation : '所属組織',
  external : '外部',
  extract_attribute_item_and_add_name : '属性項目を抽出し、名前を追加する',
  space : 'ー',
  get_column_display_type : 'カラムの表示タイプを取得する',
  field_name : 'フィールド名',
  group: 'グループ', 
  add: {
    all : '全て',
    only : '以下のみ',
    other_than : '以下以外',
    attribute_master : '属性マスタ',
    addition : '追加',
    conditions : '条件',
    is_equal_to : '以下と等しい',
    is_not_equal_to : '以下と等しくない',
    value : '値',
    group_name : 'グループ名',
    add_group : 'グループ追加',
    add_condition : '条件追加',
    confirm : '確定',
    delete : '削除',
    clear : 'クリア',
    close_up : '閉じる',
    please_enter : 'を入力してください。',
    user_attribute_registration : 'ユーザー属性の登録',
    deleted_because_it_is_not_registered_data : '登録しないデータのため、削除',
    ignore_attribute_check_state_if_all_are_selected : '全てが選択されている場合は、属性のチェック状態を無視する',
    i_deleted_the_data : 'データを削除しました。',
    data_registered : 'データを登録しました。',
    updated_data : 'データを更新しました。',
    update_flag : '更新フラグ',
    with_update : '更新あり',
    no_update: '更新なし',
    generate_information_for_selection_forms : '選択フォーム用の情報を生成する',
    none : 'なし',
    password_update_date : 'パスワード更新日',
    update_date : '更新日',
    user_attribute_information : 'ユーザー属性情報',
    user_attributes : 'ユーザー属性',
    get_field_information_that_stores_data : 'データを格納するフィールド情報を取得する',
    determine_if_the_corresponding_form_type_is_a_multiline_form : '該当のフォームタイプが複数行のフォームかを判定する',
    form_type : 'フォームタイプ',
    determine_if_the_corresponding_field_is_a_user_attribute : '該当のフィールドがユーザー属性かどうかを判定する',
    field : 'フィールド',
  }
};