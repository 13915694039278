import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { createHttpParams } from '../blocks/utils/http-helper';

@Injectable({ providedIn: 'root' })
export class ResetPassService {

    constructor(
        private http: HttpClient,
    ) {
    }

    sendMail(email) {
        return this.http.post<any>(`${environment.apiUrl}/api/v1/send_mail`, { email})
            .pipe(map(data => {
                return data;
            }));
    }

    resetPass(params) {
        const token = params.token;
        const password = params.password;
        const confirm_password = params.confirm_password;
        return this.http.post<any>(`${environment.apiUrl}/api/v1/update_password`, { password, confirm_password, token })
            .pipe(map(data => {
                return data;
            }));
    }

    checkExpireUrl(token) {
        const params = createHttpParams({
            token
          });
        return this.http.get<any>(`${environment.apiUrl}/api/v1/check_expire_token`, {params})
        .pipe(map(data => {
            return data;
        }));
    }

}
