export const import_export_csv = {
    it_is_being_processed_please_wait : '処理中です。お待ちください。',
    please_drag_and_drop_files_here_or : 'ここにファイルをドラッグ＆ドロップしてください。または',
    select_files : 'ファイルを選択',
    character_code : '文字コード',
    delimiter : '区切り文字',
    comma : 'カンマ',
    tab : 'タブ',
    execution : '実行',
    close_up : '閉じる',
    successfully_imported : '件のインポートに成功しました。',
    line : '行目',
    rerun : '再実行',
    please_choose_a_file: 'ファイルを選択してください。',
    file_name : 'ファイル名',
    export : 'エクスポート',
    import : 'インポート',
    success : '成功',
    failure : '失敗',
};