export const common = {
  project_name: 'フロントエンド',
  validation: {
    required: '{{attribute}}を入力してください。',
    distinct: '{{attribute}}フィールドの値が重複しています。',
    unique: '{{attribute}}は既に登録されています。'
  },
  modal: {
    confirm: '確定',
    clear: 'クリア',
    close: '閉じる',
  },
  toast: {
    success: 'データを更新しました。',
  }
};
