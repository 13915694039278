import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { createHttpParams } from "../../blocks/utils/http-helper";

@Injectable({ providedIn: "root" })
export class GroupAssignManagerService {
  constructor(private http: HttpClient) { }

  getGroupAssignList(param) {
    const params = {
      page: param.page,
      limit: param.numberPerPage,
      order_by: param.order_by,
      order_direction: param.order_direction,
      UpdateFlags: param.search_update_flag,
      // ExpiredPassword: param.ExpiredPassword,
      // LockFlag: param.LockFlag,
      DeleteFlag: param.DeleteFlag,
      UpdateUserID: param.updateUser,
      // EffectiveDate: param.EffectiveDate,
      UpdateDate: param.UpdateDate,
      fieldSearch: param.search_by_field,
      valueSearch: param.search
    };

    return this.http
      .post(`${environment.apiUrl}/api/v1/user_to_group/get_list`, params)
      .pipe(
        map(data => {
          const users = data["data"]["items"].map(user => user);
          const total = data["data"]["total"];
          const headers = data["data"]["headers"];
          const validations = data["data"]["validations"];
          return { users, total, headers, validations };
        })
      );
  }

  getConditionToSearch() {
    return this.http
      .get(`${environment.apiUrl}/api/v1/user_to_group/get_conditions`)
      .pipe(
        map(data => {

          const field = data["data"]["fields"];
          const extractionIds = data["data"]["extractionIds"];
          const organization = data["data"]["organizations"];
          const attribute = data["data"]["attributes"];
          const admins = data["data"]["admins"];
          const role = data["data"]["saas"];
          const setting = data["data"]["setting"];
          const groups = data["data"]["groups"];
          const conditionField = data["data"]["condition_field"];
          const systemSettingFieldID = data["data"]["system_setting_field_id"];
          const configSystemSettingFieldsID = data["data"]["config_system_setting_fields_id"];
          const requiredFields = data["data"]["required_fields"];
          // const users = data['data']['items'].map(user => new UserListModel(user));
          // const pagination = data['meta'].pagination;
          return { groups, field, extractionIds, organization, attribute, admins, role, setting, conditionField, systemSettingFieldID, configSystemSettingFieldsID, requiredFields };
        })
      );
  }

  createGroupAssign(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/user_to_group/create`, data);
  }

  updateGroupAssign(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/user_to_group/update`, data);
  }

  getGroupAssignDetail(groupId, userId) {
    const httpParams = { Group_ID: groupId, User_ID: userId };
    return this.http
      .post(`${environment.apiUrl}/api/v1/user_to_group/show`, httpParams)
      .pipe(
        map(user => user["data"]),
        map(user => {
          return user;
        })
      );
  }
  getGroupDetail(userId) {
    const httpParams = { User_ID: userId };
    return this.http
      .post(`${environment.apiUrl}/api/v1/user_to_group/get_group`, httpParams)
      .pipe(
        map(user => user["data"]),
        map(user => {
          return user;
        })
      );
  }

  downloadTemplate() {
    return this.http.get(`${environment.apiUrl}/api/v1/user_to_group/download`, {
      observe: "response",
      responseType: "blob" as "json"
    });
  }

  importCsv(file, delimiter) {
    const formData = new FormData();
    formData.append('file', file);
    const params = {
      delimiter: delimiter,
    }
    return this.http.post(`${environment.apiUrl}/api/v1/user_to_group/import`,
    formData, { params:  createHttpParams(params) })
    .pipe(
      map(data => {
          const totalRow = data['data']['totalRow']
          const error = data['data']['error']
          return {totalRow, error};
      })
    );
  }

  extractCsv(param, newParams) {
    let p: any;
    const fieldSearch = param.search_by_field;
    const searchKeyword = encodeURIComponent(param.search);
    const params = {
        name_file: newParams.name_file,
        end_character: newParams.end_character,
        page: param.page,
        // page_size: param.numberPerPage,
        order_by: param.order_by,
        order_direction: param.order_direction,
        search_organization: param.search_organization,
        search_update_flag: param.search_update_flag,
        is_expired_date: param.isExpiredDate,
        ExpiredPassword: param.ExpiredPassword,
        LockFlag: param.LockFlag,
        DeleteFlag: param.DeleteFlag,
        check_sub_organization: param.check_sub_organization,
        UpdateUserID: param.UpdateUserID,
        search_role: param.search_role,
        delete_flags_1: param.delete_flags_1,
        delete_flags_0: param.delete_flags_0,
        isUpdateFlags: param.isUpdateFlags,
        selectedFieldDate: param.selectedFieldDate,
        input_date_end: param.input_date_end,
        input_date_start: param.input_date_start,
        count_group: param.count_group ? 1 : 0,
        // EffectiveDate: param.EffectiveDate,
        // UpdateDate: param.UpdateDate,
        managed_org: param.managedOrg,
        fieldSearch,
    };

    if (fieldSearch !== "" && searchKeyword !== "") {
        params[fieldSearch] = searchKeyword;
    }

    return this.http.post(`${environment.apiUrl}/api/v1/user_to_group/extract`, params, {
      observe: "response",
      responseType: "blob" as "json"
    });
  }

  getUserList(param) {
    const fieldSearch = param.search_by_field;
    const searchKeyword = encodeURIComponent(param.search);
    const params = {
      page: param.page,
      page_size: param.numberPerPage,
      order_by: param.order_by,
      order_direction: param.order_direction,
      search_organization: param.search_organization,
      check_sub_organization: param.check_sub_organization,
      delete_flags_0: param.delete_flags_0,
      managed_org: param.managedOrg,
      fieldSearch,
    };

    if (fieldSearch !== "" && searchKeyword !== "") {
      params[fieldSearch] = searchKeyword;
    }

    return this.http
        .get(`${environment.apiUrl}/api/v1/user_to_group/list-user`, { params: createHttpParams(params)})
        .pipe(
            map(data => {
                const users = data["data"]["items"].map(user => user);
                const total = data["data"]["total"];
                const fields = data["data"]["fields"];
                const validations = data["data"]["validations"];
                const required_fields = data["data"]["required_fields"];
                return { users, total, fields, validations, required_fields };
            })
        );
    }
}
