export const functions = {
  user_defined_function: 'ユーザー定義関数',
  description: '説明',
  applies_to: '適用先',
  function: '関数',
  sign_up : 'ユーザー定義関数新規作成',
  edit : 'ユーザー定義関数',
  screen_display_setting : '画面表示設',
  mapping_setting : 'マッピング設定',
  create_new : '新規作成',
  subject : '件',
  no_matching_data_found : '該当データが見つかりません',
  acquiring_data : 'データ取得中',
  delete : '削除',
  normal : '正常',
  external : '外部',
  name : '関数名',
  service : 'サービス',
  send_value_to_service : 'サービスへの送信値',
  cannot_delete:'この機能は使われていますので削除できません。',
  help_msg_name:'定義する関数の名前を入力します。',
  help_msg_description:'この関数についての説明を入力します。',
  help_msg_applies_to:'マスター管理の画面に適用する場合は「画面表示設定」を選択します。マッピング関数に使用する場合は「マッピング設定」を選択します（現在「画面表示設定」のみ使用可能です。',
  help_msg_code:'関数のコードを記載します。予期せぬ動作をする可能性があるため、メーカーまたは正規代理店から提供されたコードを使用してください。',

  add: {
    keep : '保存',
    delete : '削除',
    restoration : '復元',
    close_up : '閉じる',
    please_enter : 'を入力してください。',
    data_registered : 'データを登録しました。',
    updated_data : 'データを更新しました。',
    is_removed : '機能が削除されます。',
    are_you_sure_you_want_to_delete_it : '本当に削除してよろしいでしょうか？',
    update_modified_by_modified_date_modified_flag : '更新者、更新日付、更新フラグを更新する',
    update_flag : '更新フラグ',
    verification : '確認',
    yes : 'はい',
    no : 'いいえ',
  }
};