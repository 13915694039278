import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./layouts/login/login.component";
import { UserLoginComponent } from "./layouts/user-login/user-login.component";
import { UserLoginSamlComponent } from "./layouts/user-login-saml/user-login-saml.component";
import { PasswordSendComponent } from "./layouts/reset-pass/password-send/password-send.component";
import { PasswordResetComponent } from "./layouts/reset-pass/password-reset/password-reset.component";
import { PageNotfoundComponent } from "./layouts/page-notfound/page-notfound.component";
import { LoginRouteAccessGuard } from "./guards/login.guard";
import { ERole } from './core/user/account.model';

const routes: Routes = [
  {
    path: "",
    data: {
      isUserRoute: true,
    },
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
  {
    path: "",
    data: {
      isAdminRoute: true,
    },
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: "admin-login",
    component: LoginComponent,
    data: {
      isPublicRoute: true,
    },
    canActivate: [LoginRouteAccessGuard],
  },
  {
    path: "login",
    component: UserLoginComponent,
    data: {
      isPublicRoute: true,
      authorities: [ERole.USER],
    },
    canActivate: [LoginRouteAccessGuard],
  },
  {
    path: "user-login",
    redirectTo: "",
    pathMatch: "full",
  },
  {
    path: "login-saml-success",
    component: UserLoginSamlComponent,
    data: {
      isPublicRoute: true,
    },
    canActivate: [LoginRouteAccessGuard],
  },
  {
    path: "password-send",
    component: PasswordSendComponent,
    data: {
      isPublicRoute: true,
    },
  },
  {
    path: "password-reset",
    component: PasswordResetComponent,
    data: {
      isPublicRoute: true,
    },
  },
  {
    path: "404",
    component: PageNotfoundComponent,
    data: {
      isPublicRoute: true,
    },
  },
  {
    path: "**",
    pathMatch: "full",
    component: PageNotfoundComponent,
    data: {
      isPublicRoute: true,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: "reload",
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
