import { ERoleToRole } from "./../core/user/account.model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import * as jwt_decode from "jwt-decode";

@Injectable({ providedIn: "root" })
export class AccountService {
  private userIdentity: any;
  private authenticated = false;
  private authenticationState = new Subject<any>();

  constructor(private http: HttpClient) {}

  // fetch(): Observable<HttpResponse<Account>> {
  //     return this.http.get<Account>(`${environment.apiUrl}auth/me`, { observe: 'response' });
  // }

  save(account: any): Observable<HttpResponse<any>> {
    return this.http.post(environment.apiUrl + "api/account", account, {
      observe: "response",
    });
  }

  authenticate(identity) {
    this.userIdentity = identity;
    this.authenticated = identity !== null;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[] | number[]): boolean {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return false;
    }
    const user = jwt_decode(token);
    return !!(authorities as any[])
      .map<boolean>((authority: any) => {
        const roles: string[] = ERoleToRole[authority] || [];
        return !!roles.includes(user.role);
      })
      .filter((hasAuthority: boolean) => hasAuthority).length;
  }

  hasAuthority(authority: string): Promise<boolean> {
    if (!this.authenticated) {
      return Promise.resolve(false);
    }

    return this.identity().then(
      (id) => {
        return Promise.resolve(
          id.authorities && id.authorities.includes(authority)
        );
      },
      () => {
        return Promise.resolve(false);
      }
    );
  }

  identity(force?: boolean): Promise<any> {
    if (force) {
      this.userIdentity = undefined;
    }

    // check and see if we have retrieved the userIdentity data from the server.
    // if we have, reuse it by immediately resolving
    if (this.userIdentity) {
      return Promise.resolve(this.userIdentity);
    }

    // return this.fetch()
    //     .toPromise()
    //     .then(response => {
    //         const account = response.body['data'] && new Account(response.body['data']);
    //         if (account) {
    //             this.userIdentity = account;
    //             this.authenticated = true;
    //             // After retrieve the account info, the language will be changed to
    //             // the user's preferred language configured in the account setting
    //             const langKey = this.sessionStorage.retrieve('locale') || this.userIdentity.langKey || 'en';
    //         } else {
    //             this.userIdentity = null;
    //             this.authenticated = false;
    //         }
    //         this.authenticationState.next(this.userIdentity);
    //         return this.userIdentity;
    //     })
    //     .catch(err => {
    //         this.userIdentity = null;
    //         this.authenticated = false;
    //         this.authenticationState.next(this.userIdentity);
    //         return null;
    //     });
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isIdentityResolved(): boolean {
    return this.userIdentity !== undefined;
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  getImageUrl(): string {
    return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
  }
}
