export const automatic_group_creation = {
    list: {
        group_auto_generation_rule : "グループ自動生成ルール",
        create_new : "新規作成",
        execution : "実行",
        no_matching_data_found : "該当データが見つかりません",
        acquiring_data : "データ取得中",
        rule_name : "ルール名",
        member_update : "メンバー更新",
        last_execution_date_and_time : "前回実行日時",
        scheduled_next_execution_date : "次回実行予定日時",
        invoked_a_synchronous_command : "同期コマンドを呼び出しました。",
    },
    add: {
        organization_attribute_as_group_id : "グループIDとする組織属性",
        organization_id : "組織ID",
        a_string_to_add_to_the_id : "IDに追加する文字列",
        rule_creation : "ルール作成",
        rule_name : "ルール名称",
        custom_field : "カスタム項目",
        organization_attribute_for_group_name : "グループ名称とする組織属性",
        organization_name : "組織名称",
        
        a_string_to_append_to_the_name : "名称に追加する文字列",
        execution_time : "実行時刻",
        not_specified_manual_execution : "指定しない（手動実行）",
        monthly_run : "月次実行",
        date : "日付：",
        day : "日",
        times_of_day : "時刻：",
        time : "時",
        execution_date_and_time_specification : "実行日時指定",
        select_date : "日付を選択：",
        group_member_update : "グループメンバー更新",
        do_not_update : "更新しない",
        update : "更新する",
        include_secondary_affiliations : "副所属を含める",
        membership_conditions : "メンバー条件",
        addition : "追加",
        add_condition : "条件追加",
        confirm : "確定",
        delete : "削除",
        clear : "クリア",
        close_up : "閉じる",
        please_enter_the_organization_attribute : "グループIDとする組織属性を入力してください。",
        enter_member_criteria : "メンバー条件{{value}}を入力してください。",
        a_rule_has_been_created : "ルールが作成されました。",
        rules_have_been_updated : "ルールが更新されました。",
        are_you_sure_you_want_to_delete_the_rule : "ルールを削除してもいいですか？",
        confirmation : "確認",
        yes : "はい",
        no : "いいえ",
        please_enter_the_rule_name_ : "ルール名称を入力してください。",
        please_enter_the_organization_attribute_that_will_be_the_group_name : "グループ名称とする組織属性を入力してください。",
        please_enter_the_date : "日付を入力してください。",
        please_enter_the_time : "時刻を入力してください。",
        please_enter_a_date : "日付を選択を入力してください。",
    }
}