import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { createHttpParams } from "../../blocks/utils/http-helper";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class Service {
  constructor(private http: HttpClient) {}

  getList(param) {
    const params = createHttpParams({
      page: param.page,
      limit: param.numberPerPage,
    });
    return this.http
      .get(`${environment.apiUrl}/api/v1/saml-setting`, { params})
      .pipe(
        map((data: any) => {
          return data.data;
        })
      );
  }

  create(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/saml-setting/create`, data);
  }

  update(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/saml-setting/update`, data);
  }
  updateStatusOnlyLoginSaml(data) {
    return this.http.post(
      `${environment.apiUrl}/api/v1/saml-setting/update-status-only-login-saml`,
      data
    );
  }

  getDetail(ID) {
    return this.http
      .get(`${environment.apiUrl}/api/v1/saml-setting/${encodeURIComponent(ID)}/show`)
      .pipe(
        map(data => data["data"]),
        map(data => {
          return data;
        })
      );
  }
  getMetadata(idpName) {
    return this.http
      .get(`${environment.apiUrl}/saml2/${idpName}/metadata`, { responseType: 'text' })
      .pipe(
        map(data => data),
        map(data => {
          return data;
        })
      );
  }

  checkExistedDefaultIdp() {
    return this.http
      .get(`${environment.apiUrl}/api/v1/check-existed-default-idp`)
      .pipe(
        map(data => data['data']),
        map(data => {
          return data;
        })
      );
  }

  findSaml(idpName) {
    return this.http
      .get(`${environment.apiUrl}/api/v1/find-saml/${idpName}`)
      .pipe(
        map(data => data['data']),
        map(data => {
          return data;
        })
      );
  }
}
