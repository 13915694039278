import {Component, OnInit, OnDestroy, AfterContentInit} from '@angular/core';
@Component({
  selector: 'app-page-notfound',
  templateUrl: './page-notfound.component.html',
  styleUrls: ["./page-notfound.component.scss"],
})
export class PageNotfoundComponent implements OnInit, OnDestroy, AfterContentInit {
  constructor() {}
  loadScripts() {
    const dynamicScripts = [
      '/assets/js/particles.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('body')[0].appendChild(node);
    }
  }
  ngOnInit() {
  }
  ngAfterContentInit(){
    this.loadScripts();
  }
  ngOnDestroy(): void {}
}
