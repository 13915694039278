import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { ISelectOption } from "src/app/core/models/select-option.model";
import { IUser, IOrganization} from "../models/group-model";
import { GroupService } from "../services/group.service";

@Component({
  selector: "search-user-dialog-admin",
  templateUrl: "./search-user-dialog.component.html",
  styleUrls: ["./search-user-dialog.component.scss"],
})
export class SearchUserDialogAdminComponent implements OnInit, OnDestroy {
  @ViewChild("searchUserDialogAdmin", { static: true }) searchUserDialogAdmin: ElementRef;
  @Output() onAddUser = new EventEmitter<IUser[]>();
  modalReference: NgbModalRef;
  organizationsSubscriptions: Subscription;
  organizations: IOrganization[] = [];
  selectedOrganizationId: string = "";
  selectedUserListToAdd: IUser[] = [];
  searchUserSubscription: Subscription;
  searchUserInput: ISelectOption = undefined;
  searchUserResults: IUser[] = [];

  constructor(
    private modalService: NgbModal,
    private groupService: GroupService
  ) {}

  ngOnInit(): void {
    this.getListOrganization();
  }

  ngOnDestroy(): void {
    this.organizationsSubscriptions &&
      this.organizationsSubscriptions.unsubscribe();
    this.modalReference && this.modalReference.close();
  }

  getListOrganization() {
    this.organizationsSubscriptions = this.groupService.listOrganization()
    .pipe()
    .subscribe(data => {
      this.organizations = data.organizations;
    });
  }

  showModal() {
    this.selectedOrganizationId = "";
    this.searchUserInput = {};
    this.searchUserResults = [];
    this.selectedUserListToAdd = [];
    this.modalReference = this.modalService.open(this.searchUserDialogAdmin, {
      size: "lg",
      backdrop: "static",
      windowClass: "wl-search-user-dialog",
      backdropClass: "wl-search-user-dialog-backdrop",
    });
  }

  handleChangeOrganization() {
    this.searchUserInput = null;
    this.searchUserResults = [];
  }

  handleSearchUser(query: string) {
    let idOrName = typeof query === 'string' ? query : '';
      this.searchUserSubscription && this.searchUserSubscription.unsubscribe();
      this.searchUserSubscription = this.groupService
      .searchUser({
        organization_id: this.selectedOrganizationId,
        id_or_name: idOrName,
      })
      .subscribe(
        (result) => {
          this.searchUserResults = result.users;
        },
        () => {
          this.searchUserResults = [];
        }
      );
    
  }

  handleChangeUser() {
    if (!this.searchUserInput) {
      return;
    }
    if (
      !this.selectedUserListToAdd.find(
        (u: IUser) => u.ID == this.searchUserInput.ID
      )
    ) {
      this.selectedUserListToAdd.push({
        ID: this.searchUserInput.ID,
        Name: this.searchUserInput.Name,
      });
    }
  }

  deleteSelectedUser(index: number) {
    this.selectedUserListToAdd.splice(index, 1);
  }

  clickedSubmit() {
    this.onAddUser.emit(this.selectedUserListToAdd);
    this.closeModal();
  }

  clickedCancel() {
    this.closeModal();
  }

  closeModal() {
    this.modalReference.close();
  }
}
