export const daily_report = {
    daily_operational_report : "デイリー運用レポート",
    update : "更新",
    synchronization_result_report : "同期実行結果レポート",
    user : "ユーザー",
    effectiveness : "有効",
    pause : "一時停止",
    invalid : "無効",
    license : "ライセンス",
    purchase_license: "購入ライセンス：",
    service : "サービス",
    service_name : "サービス名",
    no_matching_data_found : "該当データが見つかりません",
    number : "件数",
    the_day_before_ratio : "前日比",
    organization_role_group : "組織・ロール・グループ",
    organization : "組織",
    roll : "ロール",
    group : "グループ",
    no_data_for_the_day : "当日データが存在しません",
    acquiring_data : "データ取得中",
    synchronization_result_report_user : "同期実行結果レポート（ユーザー）",
    direction : "方向",
    name : "名称",
    process : "処理",
    last_sync_time : "前回同期時刻",
    new : "新規",
    delete : "削除",
    error : "エラー",
    next_sync_schedule : "次回同期予定",
    input : "入力",
    output : "出力",
    synchronous_execution : "同期実行",
    difference_extraction : "差分抽出",
    synchronization_log : "同期実行ログ",
    subject : "件",
    processing_date : "処理日",
    from : "から",
    to : "まで",
    result : "結果",
    cooperation_destination : "連携先",
    subject_2 : "対象",
    it_has_been_processed : "処理されたID",
    detail : "詳細",
    times_of_day : "時刻",
    detailed_log : "詳細ログ",
    close_up : "閉じる",
    situation : "状態",
    start : "開始",
    end : "終了",
    next_time : "次回",
    cloud_service_scim : "クラウドサービス（SCIM）",
    rdbms_oracledb_postgresql_etc : "RDBMS（OracleDB,PostgreSQLなど）",
    file : "CSVファイル",
    trust_login : "GMOトラストログイン",
    do_not_permission_screen : "この画面にアクセスする権限がありません。",
    year : "年",
    moon : "月",
    date : "日時点",
    normal : "正常",
    invoked_the_run_command : "実行コマンドを呼び出しました。"
};
  