export const inventory = {
  id_inventory : "ID棚卸し",
  create_new : "新規作成",
  rule_name : "ルール名称",
  process : "処理",
  last_execution_date_and_time : "前回実行日時",
  next_scheduled : "次回実行予定",
  past_output_data : "過去の出力データ",
  execution : "実行",
  send_email : "メール送信",
  list_output : "リスト出力",
  display : "表示",
  no_rule_registered : "ルールが登録されていません。",
  acquiring_data : "データ取得中",
  edit : "編集",
  sign_up : "新規登録",
  detail : "詳細",
  are_you_sure_you_want_to_send_an_email_to_the_target_person : "対象者にメールを送信してもよろしいですか？",
  yes : "はい",
  no : "いいえ",
  confirmation : "確認",
  csv_output : "CSVを出力しました",
  sent_an_email_to_the_target : "対象者にメールを送信しました",
  schedule: {
    run_date_search : "実行日検索",
    from : "から",
    to : "まで",
    download : "ダウンロード",
    delete : "削除",
    close_up : "閉じる",
    csv_the_file_is_deleted : "CSVファイルが削除されます。",
    are_you_sure_you_want_to_delete_it : "本当に削除してよろしいでしょうか？",
    confirmation : "確認",
    yes : "はい",
    no : "いいえ",
  },
  add: {
    name : "名称",
    condition_specification : "条件指定",
    last_updated : "最終更新日",
    not_specified : "指定しない",
    specify : "指定する",
    date_not_updated : "日更新されていない",
    date_of_expiry : "有効期限",
    expired : "期限切れ",
    employment_status : "雇用形態",
    all_users : "全ユーザー　",
    department : "部門",
    all_departments : "全部門",
    usage_rights : "利用権限",
    assigned_license : "割当てライセンス",
    item_name : "項目名",
    please_enter : "を入力してください。",
    addition : "追加",
    execution_process : "実行処理",
    output_list_for_administrator : "管理者向けにリストを出力する",
    notify_the_target_person_by_email : "対象者にメール通知する　",
    execution_time : "実行時刻",
    not_specified_manual_execution : "指定しない（手動実行）",
    monthly_run : "月次実行",
    date : "日付：",
    day : "日",
    times_of_day : "時刻：",
    time : "時",
    please_enter_the_date : "日付を入力してください。",
    please_enter_the_time : "時刻を入力してください。",
    execution_date_and_time_specification : "実行日時指定",
    select_date : "日付を選択：",
    please_enter_a_date : "日付を選択を入力してください。",
    past_time_cannot_be_selected : "過去の時刻が選択できません。",
    keep : "保存",
    save_and_run : "保存して実行",
    delete : "削除",
    close_up : "閉じる",
    saved_the_rule : "ルールを保存しました",
    save_csv_output : "保存してCSVを出力しました",
    saved_and_emailed_to_target : "保存して対象者にメールを送信しました",
    updated_data : "データを更新しました。",
    are_you_sure_you_want_to_delete_this_rule : "このルールを削除してもよろしいですか？",
    yes : "はい",
    no : "いいえ",
    confirmation : "確認",
    are_you_sure_you_want_to_send_an_email_to_the_target_person : "対象者にメールを送信してもよろしいですか？",
  }
};
