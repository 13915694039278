export enum ERole {
    ADMIN = 1,
    USER = 2,
}

export const ERoleToRole = {
    [ERole.ADMIN]: ['admin', 'super'],
    [ERole.USER]: ['user'],
};

export interface IAccount {
    email: string[];
    phone: string[];
    role: number; // temporarily use number
    user_id: string;
    username: string[];

    status?: string;
}

export class Account {
    public activated: boolean;
    public authorities: string[];
    public firstName: string;
    public langKey: string;
    public lastName: string;
    public login: string;
    public imageUrl: string;

    public role: number;
    public email: string[];
    public phone: string[];
    public userId: string;
    public userName: string[];
    status?: string;

    constructor(account?: IAccount) {
        this.email = account ? account.email : [];
        this.phone = account ? account.phone : [];
        this.role = account ? account.role : 0;
        this.userId = account ? account.user_id : '';
        this.userName = account ? account.username : [];
        this.status = account ? account.status : '';
    }
}
