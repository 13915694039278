export const auto_authority = {
  header: '権限割当てルール',
  header_company: '（企業管理者）',
  delete_success: 'データを削除しました。',
  forms: {
    label_condition: '属性マスタ{{index}}',
  },
  name: "名称",
  affiliation: "所属組織",
  group_membership: "所属グループ",
  external: "外部",
  all: "(全て)",
  update_date: "更新日",
  changer: "更新者",
  auto_authority_list: {
    search_value_item_selection: '検索値項目選択',
    search_value: '＝検索値',
    update_date: '更新日',
    changer: '更新者',
    create_new: '新規作成',
    subject: '件',
    other_than: '以下以外',
    all: '全て',
    can_be: 'あり',
    none: 'なし',
    do_nothing: '何もしない',
    no_matching_data_found: '該当データが見つかりません',
    acquiring_data: 'データ取得中',
    edit: '編集',
    sign_up: '新規登録',
    condition_registration: '条件登録',
    target_service: '対象サービス',
  }
};
