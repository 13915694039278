export const group_assign = {
    group_member_management: "グループメンバー管理",
    company_administrator: "（企業管理者）",
    string_search: "ユーザー検索",
    item: "項目",
    search_value: "検索値",
    department_search: "部門検索",
    search_including_child_organizations: "子組織を含めて検索　",
    search_including_concurrent_departments: "副所属を含めて検索",
    create_new: "新規作成",
    import: "インポート",
    export: "エクスポート",
    template: "テンプレート",
    subject: "件",
    delete: "削除",
    no_matching_data_found: "該当データが見つかりません",
    acquiring_data: "データ取得中",
    edit: "配属グループ編集",
    sign_up: "新規登録",
    normal : "正常",
    external : "外部",
    number_of_groups_in_assignment : "配属中のグループ数",
    update_flag : "更新フラグ",
    with_updates : "更新あり",
    no_updates : "更新なし",
    user : "ユーザー",
    group : "グループ",
    update_date : "更新日",
    changer : "更新者",
    delete_flag : "削除フラグ",
    csv_import_was_successful : "CSVインポートが成功しました。",
    some_data_could_not_be_imported_due_to_validation_check_errors : "バリデーションチェックによるエラーでいくつかのデータがインポートできませんでした。エラーログを確認してください。",
    the_format_of_the_csv_file_is_invalid : "CSVファイルのフォーマットが不正です。",
    not_all_data_could_be_imported_due_to_validation_check_errors : "バリデーションチェックによるエラーで全てのデータがインポートできませんでした。エラーログを確認してください。",
    add: {
        user_id: "ユーザーID",
        display_name: "表示名",
        main_department: "主所属部門",
        assignment_group: "配属先グループ",
        create_new: "新規作成",
        add_destination_group: "配属先グループ追加",
        group_to_be_added: "配属追加するグループ",
        delete: "削除",
        unassignment: "配属解除",
        click_view_or_search: "これ以外にも配属されているグループがあります。「表示」をクリックするか検索してください。",
        click_view_or_search_pre: "これ以外にも配属していたグループがあります。「表示」をクリックするか検索してください。",
        display: "表示",
        non_representation : "非表示",
        group_search: "グループ検索",
        not_found: "該当のデータがみつまりません",
        shows_groups_previous: "以前に配属していたグループも表示する。",
        previously_assigned_group: "以前に配属していたグループ",
        release_date: "配属解除日：",
        return: "復帰",
        cancel: "閉じる",
        keep: "保存",
        restoration: "復元",
        data_registered : "データを登録しました。",
        this_id_combination_has_already_been_registered : "このIDの組み合わせはすでに登録済みです。",
        updated_data : "データを更新しました。",
        group : "グループ",
        update_flag : "更新フラグ",
        with_update : "更新あり",
        no_updates : "更新なし",
        group_id : "グループID",
        corresponding_data_not_found : "該当のデータが見つかりません",
        this_group_will_be_the_one_you_were_previously_assigned_to : "このグループは以前に配属したものになります。",
        this_group_has_been_added : "このグループは選択されています。"
    }
};
  