export const log = {
  log: {
    management_screen_audit_log : "管理画面監査ログ",
    csv_export : "CSVエクスポート",
    select_target_administrator : "対象管理者の選択",
    specifying_the_target_period : "対象期間の指定",
    subject : "件",
    comma : "、",
    no_matching_data_found : "該当データが見つかりません",
    acquiring_data : "データ取得中",
    detail : "詳細",
    date : "日付",
    time : "時間",
    admin_username : "管理者ユーザー名",
    access_details : "アクセス内容",
    close_up : "閉じる",
    hour : "時",
    minutes : "分",
    seconds : "秒",
    do_not_permission_screen : "この画面にアクセスする権限がありません。",
  },
  user_log: {
    user_audit_log : "利用者監査ログ",
    csv_export : "CSVエクスポート",
    target_audience_selection : "対象ユーザの選択",
    specifying_the_target_period : "対象期間の指定",
    subject : "件",
    no_data : "データはありません。",
    date : "日付",
    time : "時間",
    a_user : "ユーザ",
    content : "内容",
    hour : "時",
    minutes : "分",
    seconds : "秒",
    do_not_permission_screen : "この画面にアクセスする権限がありません。",
  }
};
