import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { createHttpParams } from "../../blocks/utils/http-helper";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class OrgManagerService {
  constructor(private http: HttpClient) {}

  getOrgList(param) {
    const params = createHttpParams({
      page: param.page,
      limit: param.numberPerPage,
      orderField: param.order_by,
      orderBy: param.order_direction,
      UpdateFlags: param.search_update_flag,
      ExpiredPassword: param.ExpiredPassword,
      LockFlag: param.LockFlag,
      DeleteFlag: param.DeleteFlag,
      UpdateUserID: param.updateUser,
      EffectiveDate: param.EffectiveDate,
      UpdateDate: param.UpdateDate,
      fieldSearch: param.search_by_field,
      valueSearch: param.search
    });

    return this.http
      .get(`${environment.apiUrl}/api/v1/organization/get_list`, { params })
      .pipe(
        map(data => {
          const users = data["data"]["items"].map(user => user);
          const total = data["data"]["total"];
          const headers = data["data"]["headers"];
          const validations = data["data"]["validations"];
          const displaySetting = data["data"]['display_setting'] || [];
          return { users, total, headers, validations, displaySetting };
        })
      );
  }

  getConditionToSearch() {
    return this.http
      .get(`${environment.apiUrl}/api/v1/organization/get_conditions`)
      .pipe(
        map(data => {
          const fieldSearchKeywords = data["fieldSearchKeywords"];
          const extractionIds = data["extractionIds"];
          const updateUserName = data["UpdateUserName"];
          const headers = data["headers"];
          const attributes = data["attributes"];
          const attributeMultipleSelect = data["attribute_multiple_select"];
          const isShowUpdateFlagAndExternalID = data['isShowUpdateFlagAndExternalID']

          return { fieldSearchKeywords, extractionIds, updateUserName, headers, attributes, isShowUpdateFlagAndExternalID, attributeMultipleSelect};
        })
      );
  }

  createOrganization(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/organization/create`, data);
  }

  updateOrganization(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/organization/update`, data);
  }

  getOrganizationDetail(organizationID) {
    return this.http
      .get(`${environment.apiUrl}/api/v1/organization/${encodeURIComponent(organizationID)}/show`)
      .pipe(
        map(user => user["data"]),
        map(user => {
          return user;
        })
      );
  }

  getDropDownList(param?){
    const params = createHttpParams({
      except_organization_id: param,
    });
    return this.http
      .get(`${environment.apiUrl}/api/v1/organization/dropdown`,{params})
      .pipe(
        map(data => {
          const dropDownList = data['data'];
          return { dropDownList };
        })
      );
  }

  downloadTemplate() {
      return this.http.get(`${environment.apiUrl}/api/v1/organization/download`, {
          observe: "response",
          responseType: "blob" as "json"
      });
  }

  importCsv(file, delimiter) {
      const formData = new FormData();
      formData.append('file', file);
      const params = {
        delimiter: delimiter,
      }
      return this.http.post(`${environment.apiUrl}/api/v1/organization/import`, 
        formData, 
        { params:  createHttpParams(params) })
        .pipe(
          map(data => {
              const totalRow = data['data']['totalRow']
              const error = data['data']['error']
              return {totalRow, error};
          })
        );
  }

  extractCsv(param, newParams) {
      const params = createHttpParams({
        name_file: newParams.name_file,
        end_character: newParams.end_character,
        page: param.page,
        limit: param.numberPerPage,
        orderField: param.order_by,
        orderBy: param.order_direction,
        UpdateFlags: param.search_update_flag,
        ExpiredPassword: param.ExpiredPassword,
        LockFlag: param.LockFlag,
        DeleteFlag: param.DeleteFlag,
        UpdateUserID: param.updateUser,
        EffectiveDate: param.EffectiveDate,
        UpdateDate: param.UpdateDate,
        fieldSearch: param.search_by_field,
        valueSearch: param.search
      });
      return this.http.get(`${environment.apiUrl}/api/v1/organization/extract`, {
          params,
          observe: "response",
          responseType: "blob" as "json"
      });
  }

  deleteOrg(orgId) {
    return this.http
        .delete(`${environment.apiUrl}/api/v1/organization/${encodeURIComponent(orgId)}/delete`);
  }
}
