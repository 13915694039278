import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { lang } from "../lang";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "front-end";

  constructor(private translate: TranslateService) {}

  async ngOnInit(): Promise<void> {
    Object.keys(lang).forEach((langKey: string) => {
      this.translate.setTranslation(langKey, lang[langKey]);
    });
  }
}
