import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: any) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (this.routeData().isPublicRoute) return;
            if (err.status === 401) {
              console.log('logout because missing permission from api');
              if (this.routeData().isUserRoute) {
                this.authService.logoutUser();
              } else {
                this.authService.logoutAdmin();
              }
            }
            if (err.status == 412) {
              console.log('logout because missing permission from api');
              this.authService.logoutAdmin();
            }
          }
        }
      )
    );
  }

  private routeData(): RouteData {
    const data: RouteData = {};
    if (!this.route) return data;
    if (!this.route.root) return data;
    if (!this.route.root.firstChild) return data;
    if (!this.route.root.firstChild.snapshot) return data;
    return this.route.root.firstChild.snapshot.data || data;
  }
}

interface RouteData {
  isUserRoute?: boolean;
  isAdminRoute?: boolean;
  isPublicRoute?: boolean;
}
