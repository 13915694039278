export const sidebar = {
    close_menu : 'メニューを閉じる',
    daily_operational_report : 'デイリー運用レポート',
    master_management : 'マスター管理　',
    user_information_management : 'ユーザー情報管理',
    announcement_information_management : '発令情報管理',
    attribute_master_management : 'カスタムマスター管理',
    organization_information_management : '組織情報管理',
    group_management : 'グループ管理',
    group_member_management : 'グループメンバー管理',
    authority_information_management : '権限情報管理',
    rule_management : 'ルール管理',
    privilege_assignment_rule : '権限割当てルール',
    group_autogeneration_rule : 'グループ自動生成ルール',
    group_assignment_rule : 'グループ配属ルール',
    audit_function : '監査機能',
    functions : 'ユーザー定義関数',
    inventory : 'ID棚卸し',
    management_screen_audit_log : '管理画面監査ログ',
    user_audit_log : '利用者監査ログ',
    screen_display_settings : '画面表示設定',
    display_item_setting : '表示項目設定',
    user_screen_settings : '利用者画面設定',
    input_item_control_setting : '入力項目制御設定',
    various_function_settings : '各種機能設定',
    basic_setting : 'Keyspider基本設定',
    linked_service : '連携サービス',
    role_license_settings : 'ロール（ライセンス）設定',
    password_policy_settings : 'パスワードポリシー設定',
    email_sending_settings : 'メール送信設定',
    single_signon_settings : 'シングルサインオン設定',
    change_admin_password : '管理者パスワード変更',
    operation_setting : 'Keyspider動作設定',
    alignment : 'RPA連携',
    message_definition : 'メッセージ定義',
    logout : 'ログアウト',
};
