import { ERole } from "./../core/user/account.model";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
} from "@angular/router";
import { AccountService } from "../services/account.service";

@Injectable({ providedIn: "root" })
export class LoginRouteAccessGuard implements CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate(): boolean | Promise<boolean> {
    if (this.accountService.hasAnyAuthority([ERole.ADMIN])) {
      this.router.navigate(['/dashboard']);
      return false;
    }
    if (this.accountService.hasAnyAuthority([ERole.USER])) {
      this.router.navigate(["/"]);
      return false;
    }

    return true;
  }
}
