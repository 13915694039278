export const mail_setting = {
    title: 'メール送信設定',
    save: '反映',
    close: '閉じる',
    mail_delivery: {
        title: 'メール配信',
        smtp_username: 'SMTP Username',
        smtp_password: 'SMTP Password',
        smtp_encryption: {
            label: 'SMTP Encryption',
            ssl: 'SSL',
            tls: 'TLS'
        },
        smtp_hostname: 'SMTP Hostname',
        smtp_port: 'SMTP Port',
    },
    mail_template: {
        title: 'メールテンプレート',
        status_true: '有効',
        status_false: '無効',
        no_data: '該当データが見つかりません',
        loading: 'データ取得中',
        column_name: {
            status: 'ステータス',
            type: 'メールテンプレートKEY',
            timing: 'メール配信タイミング（日前）',
            from_address: 'Fromメールアドレス',
            title: 'タイトル',
        },
    },
    msg_success: 'データを更新しました。',
    popup: {
        save: '編集',
        email_key: 'メールテンプレートKEY',
        timing: 'メール配信タイミング',
        time_left: '日前',
        status: {
            label: 'ステータス',
            true: '有効',
            false: '無効',
        },
        send_email_test: 'テストメール送信',
        from_email: 'Fromメールアドレス',
        email_title: 'タイトル',
        email_content: '本文',
        msg_err: {
            required: 'を入力してください。',
            format: 'のフォーマットが不正です。',
        },
        msg_success: {
            send_test: 'メール送信成功！'
        }
    }
};
