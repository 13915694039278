import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { finalize, takeUntil } from "rxjs/operators";
import { StateStorageService } from "src/app/services/state-storage.service";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { CookieHelper } from "../../core/helper/cookie-helper";
import { Common } from "src/app/core/constants/common";
import {TranslateService} from "@ngx-translate/core";
import {lang} from "../../../lang";
import {LoadingService} from "../../loading/service/loading";
import { Service as SamlService } from 'src/app/saml-setting/service/service';
import { IdpHelper } from 'src/app/core/helper/idp-helper';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: "app-user-login",
  templateUrl: "./user-login.component.html",
  styleUrls: ['../../../user-workflow.scss', './user-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserLoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  ngUnsubscribe = new Subject();
  submitted = false;
  returnUrl: string;
  error = "";
  authenticationError = false;
  samlUserNotFoundError = false;
  showReset: boolean;
  samlMethodList: { name: string; label: string }[] = [];
  fields: any = {};
  errors = {};
  snapshot: { authorities?: [] } = {};
  nonExistedDefaultIdp = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenService: AuthService,
    private stateStorageService: StateStorageService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private samlService: SamlService,
    private accountService: AccountService,
    activatedRoute: ActivatedRoute
  ) {
    Object.keys(lang).forEach((langKey: string) => {
      this.translate.setTranslation(langKey, lang[langKey]);
    });
    this.translate.use(Common.LOCALE_DEFAULT);
    CookieHelper.setCookie('locale', Common.LOCALE_DEFAULT, 365);
    this.snapshot = activatedRoute.snapshot.data;
  }

  ngOnInit() {
    this.checkExistedDefaultIdp();
    this.route.queryParams.subscribe((params: any) => {
      if (params && params.redirect) {
        this.stateStorageService.storeUrl(params.redirect);
      } else if (params && params.redirect_code) {
        this.stateStorageService.storeUrl(
          Common.REDIRECT_CODE[params.redirect_code]
        );
      } else {
        this.stateStorageService.storeUrl(null);
      }
    });
    this.loadingService.setShowLoading(true);
    this.getConfig();
    this.getPageConfigs();
    this.showSamlUserNotFoundError();
    this.loginForm = this.formBuilder.group({
      userId: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.unsubscribe();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.error = '';
    // stop here if form is invalid
    this.errors = {};
    if (this.loginForm.invalid) {
      this.validateInput();
      return;
    }

    this.loadingService.setShowLoading(true);
    this.authenService
      .login(this.f.userId.value, this.f.password.value)
      .pipe(finalize(() => {
        this.loadingService.setShowLoading(false);
      }))
      .subscribe(
        () => {
          this.authenticationError = false;

          const redirect = this.stateStorageService.getUrl();
          if (redirect) {
            this.stateStorageService.storeUrl(null);
            this.router.navigate([redirect]);
          } else {
            this.router.navigate(["/user"]).then(() => {
              window.location.reload();
            });
          }
        },(e) => {
          let msg = e.error.message;
          this.error = msg;
          this.authenticationError = true;
        }
      );
  }
  private validateInput() {
    const passwordControl = this.loginForm.get('password');
    if (passwordControl && passwordControl.hasError('required')) {
      this.errors['password'] = this.translate.instant(
        'pages.user_login.msg_required_password'
      );
    }
    const userIdControl = this.loginForm.get('userId');
    if (userIdControl && userIdControl.hasError('required')) {
      this.errors['userId'] = this.translate.instant(
        'pages.user_login.please_enter_your_user_id'
      );
    }
  }
  get userId() {
    return this.loginForm.controls.userId;
  }

  get password() {
    return this.loginForm.controls.password;
  }

  private getConfig() {
    this.authenService
      .checkReset()
      .pipe(
        finalize(() => (this.loadingService.setShowLoading(false))),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data) => {
        this.showReset = data.config.AllowReset;
      });
  }
  private getPageConfigs() {
    this.authenService
      .getPageConfigs("user-login")
      .pipe(
        finalize(() => (this.loadingService.setShowLoading(false))),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data) => {
        const { config, samlSetting = [] } = data;
        this.samlMethodList = samlSetting.map((item) => {
          return {
            name: item.name,
            label: item.label ? item.label : item.name,
          };
        });
        this.fields = config;
      });
  }
  private showSamlUserNotFoundError() {
    const cookieUserNotFoundError = CookieHelper.getCookie(
      "saml_error_user_not_found"
    );
    if (cookieUserNotFoundError) {
      this.samlUserNotFoundError = true;
      CookieHelper.eraseCookie("saml_error_user_not_found");
    }
  }

  public onSamlLogin(method) {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/#/login-saml-success`;
    const exportUrl = `${environment.apiUrl}/saml2/${method.name}/login?redirect=${encodeURIComponent(redirectUrl)}&name=${method.name}`;
    window.location.href = exportUrl;
  }

  checkExistedDefaultIdp() {
    const hasAnyAuthority = this.accountService.hasAnyAuthority(
      this.snapshot.authorities
    );
    if (hasAnyAuthority) {
      return;
    } else {
      this.route.queryParams.subscribe(
        async (query: {
          login?: string;
          logout?: number;
          login_saml_failed?: number;
        }) => {
          if (query.logout || query.login_saml_failed) {
            this.nonExistedDefaultIdp = true;
            return;
          } else {
            const result = await IdpHelper.checkExistedDefaultIdp(
              query,
              this.samlService
            );
            if (result) {
              this.nonExistedDefaultIdp = false;
              IdpHelper.onSamlLogin(result);
            } else {
              this.nonExistedDefaultIdp = true;
            }
          }
        }
      );
    }
  }
}
