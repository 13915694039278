import { environment } from 'src/environments/environment';
import { Service } from 'src/app/saml-setting/service/service';

export class IdpHelper {
  static async checkExistedDefaultIdp(
    query: { login?: string } = {},
    service: Service
  ) {
    let status = '';
    if (query.login) {
      if (query.login === 'default') {
        status = undefined;
      } else {
        await new Promise(resolve => {
          service.findSaml(query.login).subscribe(data => {
            if (data) {
              status = query.login;
              resolve(status);
            } else {
              window.location.href = `${window.location.protocol}//${window.location.host}/#/login?login_saml_failed=1`;
            }
          });
        });
      }
      return status;
    } else {
      await new Promise(resolve => {
        service.checkExistedDefaultIdp().subscribe((nameIdp: string | null) => {
          if (nameIdp) {
            status = nameIdp;
          } else {
            status = undefined;
          }
          resolve(status);
        });
      });
      return status;
    }
  }

  static onSamlLogin(name) {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/#/login-saml-success`;
    const exportUrl = `${
      environment.apiUrl
    }/saml2/${name}/login?redirect=${encodeURIComponent(
      redirectUrl
    )}&name=${name}`;
    window.location.href = exportUrl;
  }
}
