import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./layouts/login/login.component";
import { NgxWebstorageModule } from "ngx-webstorage";
import { UserLoginComponent } from "./layouts/user-login/user-login.component";
import { UserLoginSamlComponent } from "./layouts/user-login-saml/user-login-saml.component";
import { AuthInterceptor } from "./blocks/interceptor/auth.interceptor";
import { SharedModule } from "./shared/shared.module";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { PasswordSendComponent } from "./layouts/reset-pass/password-send/password-send.component";
import { PasswordResetComponent } from "./layouts/reset-pass/password-reset/password-reset.component";
import { AuthExpiredInterceptor } from "./blocks/interceptor/auth-expired.interceptor";
import { AccordionModule } from "primeng/accordion";
import { PageNotfoundComponent } from "./layouts/page-notfound/page-notfound.component";
import { TranslateModule } from "@ngx-translate/core";
import { IpAccessInterceptor } from "./blocks/interceptor/ip-access.interceptor";
import {LoadingComponent} from './loading/loading.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserLoginComponent,
    UserLoginSamlComponent,
    PasswordSendComponent,
    PasswordResetComponent,
    PageNotfoundComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxWebstorageModule.forRoot(),
    SharedModule,
    AccordionModule,
    NgbModule,
    TranslateModule.forRoot({
      defaultLanguage: "ja",
    }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IpAccessInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
