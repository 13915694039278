
export interface IUser {
  ID?:string;
  displayName?:string;
  mail?:string;
  employeeNumber?:string;
  EffectiveDate?:string;
  expireDate?:string;
  // Password?:string;
  surname?:string;
  givenName?:string;
  surnameLocal?:string;
  givenNameLocal?:string;
  CompanyName?:string;
  department?:string;
  OrganizationID1?:string;
  OrganizationID2?:string;
  OrganizationID3?:string;
  OrganizationID4?:string;
  OrganizationID5?:string;
  jobTitle?:string;
  jobTitleText?:string;
  employeeType?:string;
  employeeTypeText?:string;
  businessPhones?:string;
  DeleteFlag?:string;
  leaveOfAbsenceFlag?:string;
  secondmentFlag?:string;
  LockFlag?:string;
  UpdateFlags?:string;
  UpdateUserID?:string;
  UpdateDate?:string;
  Name?:string;
  userPrincipalName?:string;
  externalID?:string;
  external389DSID?:string;
  externalBOXID?:string;
  externalCSVID?:string;
  externalLDAPID?:string;
  externalRDBID?:string;
  externalSFID?:string;
  externalTLID?:string;
  externalZIAID?:string;
  businessCategory?:string;
  jobTitleCode?:string;
  birthday?:string;
  employeeHireDate?:string;
  postalCode?:string;
  country?:string;
  city?:string;
  state?:string;
  streetAddress?:string;
  mobilePhone?:string;
  departmentName?:string;
  employeeTypeName?:string;
  userMaintRoleFlag?:string;
  userWfRoleFlag?:string;
  userManagementRole?:string;
  userWorkFlowRole?:string;
  created_at?:string;
  updated_at?:string;
  createdByAdminFlag?:string;
  isMaintenance?:number;
  keySpiderAdminFlag?:number;
  createdAt?:string;
  updatedAt?:string;
  is_maintaince?:string;
  externalL389DSID?:string;
  externalGWID?:string;
  address?:string;
  keyspiderManagementRole?:string;
  customColumn01?:string;
  customColumn02?:string;
  customColumn03?:string;
  customColumn04?:string;
  customColumn05?:string;
  customColumn06?:string;
  customColumn07?:string;
  customColumn08?:string;
  customColumn09?:string;
  customColumn10?:string;
  customColumn11?:string;
  customColumn12?:string;
  customColumn13?:string;
  customColumn14?:string;
  customColumn15?:string;
  customColumn16?:string;
  customColumn17?:string;
  customColumn18?:string;
  customColumn19?:string;
  customColumn20?:string;
  customColumn21?:string;
  customColumn22?:string;
  customColumn23?:string;
  customColumn24?:string;
  customColumn25?:string;
  customColumn26?:string;
  customColumn27?:string;
  customColumn28?:string;
  customColumn29?:string;
  customColumn30?:string;
  customColumn31?:string;
  customColumn32?:string;
  customColumn33?:string;
  customColumn34?:string;
  customColumn35?:string;
  customColumn36?:string;
  customColumn37?:string;
  customColumn38?:string;
  customColumn39?:string;
  customColumn40?:string;
  customColumn41?:string;
  customColumn42?:string;
  customColumn43?:string;
  customColumn44?:string;
  customColumn45?:string;
  customColumn46?:string;
  customColumn47?:string;
  customColumn48?:string;
  customColumn49?:string;
  customColumn50?:string;
  customColumn51?:string;
  customColumn52?:string;
  customColumn53?:string;
  customColumn54?:string;
  customColumn55?:string;
  customColumn56?:string;
  customColumn57?:string;
  customColumn58?:string;
  customColumn59?:string;
  customColumn60?:string;
  customColumn61?:string;
  customColumn62?:string;
  customColumn63?:string;
  customColumn64?:string;
  customColumn65?:string;
  customColumn66?:string;
  customColumn67?:string;
  customColumn68?:string;
  customColumn69?:string;
  customColumn70?:string;
  customColumn71?:string;
  customColumn72?:string;
  customColumn73?:string;
  customColumn74?:string;
  customColumn75?:string;
  customColumn76?:string;
  customColumn77?:string;
  customColumn78?:string;
  customColumn79?:string;
  customColumn80?:string;
  customColumn81?:string;
  customColumn82?:string;
  customColumn83?:string;
  customColumn84?:string;
  customColumn85?:string;
  customColumn86?:string;
  customColumn87?:string;
  customColumn88?:string;
  customColumn89?:string;
  customColumn90?:string;
  customColumn91?:string;
  customColumn92?:string;
  customColumn93?:string;
  customColumn94?:string;
  customColumn95?:string;
  customColumn96?:string;
  customColumn97?:string;
  customColumn98?:string;
  customColumn99?:string;
  "RoleFlag-0"?:string;
  "RoleFlag-1"?:string;
  "RoleFlag-2"?:string;
  "RoleFlag-3"?:string;
  "RoleFlag-4"?:string;
  "RoleFlag-5"?:string;
  "RoleFlag-6"?:string;
  "RoleFlag-7"?:string;
  "RoleFlag-8"?:string;
  "RoleFlag-9"?:string;
  "RoleFlag-10"?:string;
  "RoleFlag-11"?:string;
  "RoleFlag-12"?:string;
  "RoleFlag-13"?:string;
  "RoleFlag-14"?:string;
  "RoleFlag-15"?:string;
  "RoleFlag-16"?:string;
  "RoleFlag-17"?:string;
  "RoleFlag-18"?:string;
  "RoleFlag-19"?:string;
  "RoleFlag-20"?:string;
  "RoleFlag-21"?:string;
  "RoleFlag-22"?:string;
  "RoleFlag-23"?:string;
  "RoleFlag-24"?:string;
  "RoleFlag-25"?:string;
  "RoleFlag-26"?:string;
  "RoleFlag-27"?:string;
  "RoleFlag-28"?:string;
  "RoleFlag-29"?:string;
  "RoleFlag-30"?:string;
  "RoleFlag-31"?:string;
  "RoleFlag-32"?:string;
  "RoleFlag-33"?:string;
  "RoleFlag-34"?:string;
  "RoleFlag-35"?:string;
  "RoleFlag-36"?:string;
  "RoleFlag-37"?:string;
  "RoleFlag-38"?:string;
  "RoleFlag-39"?:string;
  "RoleFlag-40"?:string;
  "RoleFlag-41"?:string;
  "RoleFlag-42"?:string;
  "RoleFlag-43"?:string;
  "RoleFlag-44"?:string;
  "RoleFlag-45"?:string;
  "RoleFlag-46"?:string;
  "RoleFlag-47"?:string;
  "RoleFlag-48"?:string;
  "RoleFlag-49"?:string;
  "RoleFlag-50"?:string;
  "RoleFlag-51"?:string;
  "RoleFlag-52"?:string;
  "RoleFlag-53"?:string;
  "RoleFlag-54"?:string;
  "RoleFlag-55"?:string;
  "RoleFlag-56"?:string;
  "RoleFlag-57"?:string;
  "RoleFlag-58"?:string;
  "RoleFlag-59"?:string;
  "RoleFlag-60"?:string;
  "RoleFlag-61"?:string;
  "RoleFlag-62"?:string;
  "RoleFlag-63"?:string;
  "RoleFlag-64"?:string;
  "RoleFlag-65"?:string;
  "RoleFlag-66"?:string;
  "RoleFlag-67"?:string;
  "RoleFlag-68"?:string;
  "RoleFlag-69"?:string;
  "RoleFlag-70"?:string;
  "RoleFlag-71"?:string;
  "RoleFlag-72"?:string;
  "RoleFlag-73"?:string;
  "RoleFlag-74"?:string;
  "RoleFlag-75"?:string;
  "RoleFlag-76"?:string;
  "RoleFlag-77"?:string;
  "RoleFlag-78"?:string;
  "RoleFlag-79"?:string;
  "RoleFlag-80"?:string;
  "RoleFlag-81"?:string;
  "RoleFlag-82"?:string;
  "RoleFlag-83"?:string;
  "RoleFlag-84"?:string;
  "RoleFlag-85"?:string;
  "RoleFlag-86"?:string;
  "RoleFlag-87"?:string;
  "RoleFlag-88"?:string;
  "RoleFlag-89"?:string;
  "RoleFlag-90"?:string;
  "RoleFlag-91"?:string;
  "RoleFlag-92"?:string;
  "RoleFlag-93"?:string;
  "RoleFlag-94"?:string;
  "RoleFlag-95"?:string;
  "RoleFlag-96"?:string;
  "RoleFlag-97"?:string;
  "RoleFlag-98"?:string;
  "RoleFlag-99"?:string;
}

export class User implements IUser {
  ID?:string;
  displayName?:string;
  mail?:string;
  employeeNumber?:string;
  EffectiveDate?:string;
  expireDate?:string;
  // Password?:string;
  surname?:string;
  givenName?:string;
  surnameLocal?:string;
  givenNameLocal?:string;
  CompanyName?:string;
  department?:string;
  OrganizationID1?:string;
  OrganizationID2?:string;
  OrganizationID3?:string;
  OrganizationID4?:string;
  OrganizationID5?:string;
  jobTitle?:string;
  jobTitleText?:string;
  employeeType?:string;
  employeeTypeText?:string;
  businessPhones?:string;
  DeleteFlag?:string;
  leaveOfAbsenceFlag?:string;
  secondmentFlag?:string;
  LockFlag?:string;
  UpdateFlags?:string;
  UpdateUserID?:string;
  UpdateDate?:string;
  Name?:string;
  userPrincipalName?:string;
  externalID?:string;
  external389DSID?:string;
  externalBOXID?:string;
  externalCSVID?:string;
  externalLDAPID?:string;
  externalRDBID?:string;
  externalSFID?:string;
  externalTLID?:string;
  externalZIAID?:string;
  businessCategory?:string;
  jobTitleCode?:string;
  birthday?:string;
  employeeHireDate?:string;
  postalCode?:string;
  country?:string;
  city?:string;
  state?:string;
  streetAddress?:string;
  mobilePhone?:string;
  departmentName?:string;
  employeeTypeName?:string;
  userMaintRoleFlag?:string;
  userWfRoleFlag?:string;
  userManagementRole?:string;
  userWorkFlowRole?:string;
  created_at?:string;
  updated_at?:string;
  createdByAdminFlag?:string;
  isMaintenance?:number;
  keySpiderAdminFlag?:number;
  createdAt?:string;
  updatedAt?:string;
  is_maintaince?:string;
  externalL389DSID?:string;
  externalGWID?:string;
  address?:string;
  keyspiderManagementRole?:string;
  customColumn01?:string;
  customColumn02?:string;
  customColumn03?:string;
  customColumn04?:string;
  customColumn05?:string;
  customColumn06?:string;
  customColumn07?:string;
  customColumn08?:string;
  customColumn09?:string;
  customColumn10?:string;
  customColumn11?:string;
  customColumn12?:string;
  customColumn13?:string;
  customColumn14?:string;
  customColumn15?:string;
  customColumn16?:string;
  customColumn17?:string;
  customColumn18?:string;
  customColumn19?:string;
  customColumn20?:string;
  customColumn21?:string;
  customColumn22?:string;
  customColumn23?:string;
  customColumn24?:string;
  customColumn25?:string;
  customColumn26?:string;
  customColumn27?:string;
  customColumn28?:string;
  customColumn29?:string;
  customColumn30?:string;
  customColumn31?:string;
  customColumn32?:string;
  customColumn33?:string;
  customColumn34?:string;
  customColumn35?:string;
  customColumn36?:string;
  customColumn37?:string;
  customColumn38?:string;
  customColumn39?:string;
  customColumn40?:string;
  customColumn41?:string;
  customColumn42?:string;
  customColumn43?:string;
  customColumn44?:string;
  customColumn45?:string;
  customColumn46?:string;
  customColumn47?:string;
  customColumn48?:string;
  customColumn49?:string;
  customColumn50?:string;
  customColumn51?:string;
  customColumn52?:string;
  customColumn53?:string;
  customColumn54?:string;
  customColumn55?:string;
  customColumn56?:string;
  customColumn57?:string;
  customColumn58?:string;
  customColumn59?:string;
  customColumn60?:string;
  customColumn61?:string;
  customColumn62?:string;
  customColumn63?:string;
  customColumn64?:string;
  customColumn65?:string;
  customColumn66?:string;
  customColumn67?:string;
  customColumn68?:string;
  customColumn69?:string;
  customColumn70?:string;
  customColumn71?:string;
  customColumn72?:string;
  customColumn73?:string;
  customColumn74?:string;
  customColumn75?:string;
  customColumn76?:string;
  customColumn77?:string;
  customColumn78?:string;
  customColumn79?:string;
  customColumn80?:string;
  customColumn81?:string;
  customColumn82?:string;
  customColumn83?:string;
  customColumn84?:string;
  customColumn85?:string;
  customColumn86?:string;
  customColumn87?:string;
  customColumn88?:string;
  customColumn89?:string;
  customColumn90?:string;
  customColumn91?:string;
  customColumn92?:string;
  customColumn93?:string;
  customColumn94?:string;
  customColumn95?:string;
  customColumn96?:string;
  customColumn97?:string;
  customColumn98?:string;
  customColumn99?:string;
  "RoleFlag-0"?:string;
  "RoleFlag-1"?:string;
  "RoleFlag-2"?:string;
  "RoleFlag-3"?:string;
  "RoleFlag-4"?:string;
  "RoleFlag-5"?:string;
  "RoleFlag-6"?:string;
  "RoleFlag-7"?:string;
  "RoleFlag-8"?:string;
  "RoleFlag-9"?:string;
  "RoleFlag-10"?:string;
  "RoleFlag-11"?:string;
  "RoleFlag-12"?:string;
  "RoleFlag-13"?:string;
  "RoleFlag-14"?:string;
  "RoleFlag-15"?:string;
  "RoleFlag-16"?:string;
  "RoleFlag-17"?:string;
  "RoleFlag-18"?:string;
  "RoleFlag-19"?:string;
  "RoleFlag-20"?:string;
  "RoleFlag-21"?:string;
  "RoleFlag-22"?:string;
  "RoleFlag-23"?:string;
  "RoleFlag-24"?:string;
  "RoleFlag-25"?:string;
  "RoleFlag-26"?:string;
  "RoleFlag-27"?:string;
  "RoleFlag-28"?:string;
  "RoleFlag-29"?:string;
  "RoleFlag-30"?:string;
  "RoleFlag-31"?:string;
  "RoleFlag-32"?:string;
  "RoleFlag-33"?:string;
  "RoleFlag-34"?:string;
  "RoleFlag-35"?:string;
  "RoleFlag-36"?:string;
  "RoleFlag-37"?:string;
  "RoleFlag-38"?:string;
  "RoleFlag-39"?:string;
  "RoleFlag-40"?:string;
  "RoleFlag-41"?:string;
  "RoleFlag-42"?:string;
  "RoleFlag-43"?:string;
  "RoleFlag-44"?:string;
  "RoleFlag-45"?:string;
  "RoleFlag-46"?:string;
  "RoleFlag-47"?:string;
  "RoleFlag-48"?:string;
  "RoleFlag-49"?:string;
  "RoleFlag-50"?:string;
  "RoleFlag-51"?:string;
  "RoleFlag-52"?:string;
  "RoleFlag-53"?:string;
  "RoleFlag-54"?:string;
  "RoleFlag-55"?:string;
  "RoleFlag-56"?:string;
  "RoleFlag-57"?:string;
  "RoleFlag-58"?:string;
  "RoleFlag-59"?:string;
  "RoleFlag-60"?:string;
  "RoleFlag-61"?:string;
  "RoleFlag-62"?:string;
  "RoleFlag-63"?:string;
  "RoleFlag-64"?:string;
  "RoleFlag-65"?:string;
  "RoleFlag-66"?:string;
  "RoleFlag-67"?:string;
  "RoleFlag-68"?:string;
  "RoleFlag-69"?:string;
  "RoleFlag-70"?:string;
  "RoleFlag-71"?:string;
  "RoleFlag-72"?:string;
  "RoleFlag-73"?:string;
  "RoleFlag-74"?:string;
  "RoleFlag-75"?:string;
  "RoleFlag-76"?:string;
  "RoleFlag-77"?:string;
  "RoleFlag-78"?:string;
  "RoleFlag-79"?:string;
  "RoleFlag-80"?:string;
  "RoleFlag-81"?:string;
  "RoleFlag-82"?:string;
  "RoleFlag-83"?:string;
  "RoleFlag-84"?:string;
  "RoleFlag-85"?:string;
  "RoleFlag-86"?:string;
  "RoleFlag-87"?:string;
  "RoleFlag-88"?:string;
  "RoleFlag-89"?:string;
  "RoleFlag-90"?:string;
  "RoleFlag-91"?:string;
  "RoleFlag-92"?:string;
  "RoleFlag-93"?:string;
  "RoleFlag-94"?:string;
  "RoleFlag-95"?:string;
  "RoleFlag-96"?:string;
  "RoleFlag-97"?:string;
  "RoleFlag-98"?:string;
  "RoleFlag-99"?:string;

  constructor(user?: IUser) {
    this.ID = user ? (user.ID||'') : '';
    this.displayName = user ? (user.displayName||'') : '';
    this.mail = user ? (user.mail||'') : '';
    this.employeeNumber = user ? (user.employeeNumber||'') : '';
    this.EffectiveDate = user ? (user.EffectiveDate||'') : '';
    this.expireDate = user ? (user.expireDate||'') : '';
    // this.Password = user ? (user.Password||'') : '';
    this.surname = user ? (user.surname||'') : '';
    this.givenName = user ? (user.givenName||'') : '';
    this.surnameLocal = user ? (user.surnameLocal||'') : '';
    this.givenNameLocal = user ? (user.givenNameLocal||'') : '';
    this.CompanyName = user ? (user.CompanyName||'') : '';
    this.department = user ? (user.department||'') : '';
    this.OrganizationID1 = user ? (user.OrganizationID1||'') : '';
    this.OrganizationID2 = user ? (user.OrganizationID2||'') : '';
    this.OrganizationID3 = user ? (user.OrganizationID3||'') : '';
    this.OrganizationID4 = user ? (user.OrganizationID4||'') : '';
    this.OrganizationID5 = user ? (user.OrganizationID5||'') : '';
    this.jobTitle = user ? (user.jobTitle||'') : '';
    this.jobTitleText = user ? (user.jobTitleText||'') : '';
    this.employeeType = user ? (user.employeeType||'') : '';
    this.employeeTypeText = user ? (user.employeeTypeText||'') : '';
    this.businessPhones = user ? (user.businessPhones||'') : '';
    this.DeleteFlag = user ? (user.DeleteFlag||'') : '';
    this.leaveOfAbsenceFlag = user ? (user.leaveOfAbsenceFlag||'') : '';
    this.secondmentFlag = user ? (user.secondmentFlag||'') : '';
    this.LockFlag = user ? (user.LockFlag||'') : '';
    this.UpdateFlags = user ? (user.UpdateFlags||'') : '';
    this.UpdateUserID = user ? (user.UpdateUserID||'') : '';
    this.UpdateDate = user ? (user.UpdateDate||'') : '';
    this.Name = user ? (user.Name||'') : '';
    this.userPrincipalName = user ? (user.userPrincipalName||'') : '';
    this.externalID = user ? (user.externalID||'') : '';
    this.external389DSID = user ? (user.external389DSID||'') : '';
    this.externalBOXID = user ? (user.externalBOXID||'') : '';
    this.externalCSVID = user ? (user.externalCSVID||'') : '';
    this.externalLDAPID = user ? (user.externalLDAPID||'') : '';
    this.externalRDBID = user ? (user.externalRDBID||'') : '';
    this.externalSFID = user ? (user.externalSFID||'') : '';
    this.externalTLID = user ? (user.externalTLID||'') : '';
    this.externalZIAID = user ? (user.externalZIAID||'') : '';
    this.businessCategory = user ? (user.businessCategory||'') : '';
    this.jobTitleCode = user ? (user.jobTitleCode||'') : '';
    this.birthday = user ? (user.birthday||'') : '';
    this.employeeHireDate = user ? (user.employeeHireDate||'') : '';
    this.postalCode = user ? (user.postalCode||'') : '';
    this.country = user ? (user.country||'') : '';
    this.city = user ? (user.city||'') : '';
    this.state = user ? (user.state||'') : '';
    this.streetAddress = user ? (user.streetAddress||'') : '';
    this.mobilePhone = user ? (user.mobilePhone||'') : '';
    this.departmentName = user ? (user.departmentName||'') : '';
    this.employeeTypeName = user ? (user.employeeTypeName||'') : '';
    this.userMaintRoleFlag = user ? (user.userMaintRoleFlag||'') : '';
    this.userWfRoleFlag = user ? (user.userWfRoleFlag||'') : '';
    this.userManagementRole = user ? (user.userManagementRole||'') : '';
    this.userWorkFlowRole = user ? (user.userWorkFlowRole||'') : '';
    this.created_at = user ? (user.created_at||'') : '';
    this.updated_at = user ? (user.updated_at||'') : '';
    this.createdByAdminFlag = user ? (user.createdByAdminFlag||'') : '';
    this.isMaintenance = user ? (user.isMaintenance||0) : 0;
    this.keySpiderAdminFlag = user ? (user.keySpiderAdminFlag||0) : 0;
    this.createdAt = user ? (user.createdAt||'') : '';
    this.updatedAt = user ? (user.updatedAt||'') : '';
    this.is_maintaince = user ? (user.is_maintaince||'') : '';
    this.externalL389DSID = user ? (user.externalL389DSID||'') : '';
    this.externalGWID = user ? (user.externalGWID||'') : '';
    this.address = user ? (user.address||'') : '';
    this.keyspiderManagementRole = user ? (user.keyspiderManagementRole||'') : '';
    this.customColumn01 = user ? (user.customColumn01||'') : '';
    this.customColumn02 = user ? (user.customColumn02||'') : '';
    this.customColumn03 = user ? (user.customColumn03||'') : '';
    this.customColumn04 = user ? (user.customColumn04||'') : '';
    this.customColumn05 = user ? (user.customColumn05||'') : '';
    this.customColumn06 = user ? (user.customColumn06||'') : '';
    this.customColumn07 = user ? (user.customColumn07||'') : '';
    this.customColumn08 = user ? (user.customColumn08||'') : '';
    this.customColumn09 = user ? (user.customColumn09||'') : '';
    this.customColumn10 = user ? (user.customColumn10||'') : '';
    this.customColumn11 = user ? (user.customColumn11||'') : '';
    this.customColumn12 = user ? (user.customColumn12||'') : '';
    this.customColumn13 = user ? (user.customColumn13||'') : '';
    this.customColumn14 = user ? (user.customColumn14||'') : '';
    this.customColumn15 = user ? (user.customColumn15||'') : '';
    this.customColumn16 = user ? (user.customColumn16||'') : '';
    this.customColumn17 = user ? (user.customColumn17||'') : '';
    this.customColumn18 = user ? (user.customColumn18||'') : '';
    this.customColumn19 = user ? (user.customColumn19||'') : '';
    this.customColumn20 = user ? (user.customColumn20||'') : '';
    this.customColumn21 = user ? (user.customColumn21||'') : '';
    this.customColumn22 = user ? (user.customColumn22||'') : '';
    this.customColumn23 = user ? (user.customColumn23||'') : '';
    this.customColumn24 = user ? (user.customColumn24||'') : '';
    this.customColumn25 = user ? (user.customColumn25||'') : '';
    this.customColumn26 = user ? (user.customColumn26||'') : '';
    this.customColumn27 = user ? (user.customColumn27||'') : '';
    this.customColumn28 = user ? (user.customColumn28||'') : '';
    this.customColumn29 = user ? (user.customColumn29||'') : '';
    this.customColumn30 = user ? (user.customColumn30||'') : '';
    this.customColumn31 = user ? (user.customColumn31||'') : '';
    this.customColumn32 = user ? (user.customColumn32||'') : '';
    this.customColumn33 = user ? (user.customColumn33||'') : '';
    this.customColumn34 = user ? (user.customColumn34||'') : '';
    this.customColumn35 = user ? (user.customColumn35||'') : '';
    this.customColumn36 = user ? (user.customColumn36||'') : '';
    this.customColumn37 = user ? (user.customColumn37||'') : '';
    this.customColumn38 = user ? (user.customColumn38||'') : '';
    this.customColumn39 = user ? (user.customColumn39||'') : '';
    this.customColumn40 = user ? (user.customColumn40||'') : '';
    this.customColumn41 = user ? (user.customColumn41||'') : '';
    this.customColumn42 = user ? (user.customColumn42||'') : '';
    this.customColumn43 = user ? (user.customColumn43||'') : '';
    this.customColumn44 = user ? (user.customColumn44||'') : '';
    this.customColumn45 = user ? (user.customColumn45||'') : '';
    this.customColumn46 = user ? (user.customColumn46||'') : '';
    this.customColumn47 = user ? (user.customColumn47||'') : '';
    this.customColumn48 = user ? (user.customColumn48||'') : '';
    this.customColumn49 = user ? (user.customColumn49||'') : '';
    this.customColumn50 = user ? (user.customColumn50||'') : '';
    this.customColumn51 = user ? (user.customColumn51||'') : '';
    this.customColumn52 = user ? (user.customColumn52||'') : '';
    this.customColumn53 = user ? (user.customColumn53||'') : '';
    this.customColumn54 = user ? (user.customColumn54||'') : '';
    this.customColumn55 = user ? (user.customColumn55||'') : '';
    this.customColumn56 = user ? (user.customColumn56||'') : '';
    this.customColumn57 = user ? (user.customColumn57||'') : '';
    this.customColumn58 = user ? (user.customColumn58||'') : '';
    this.customColumn59 = user ? (user.customColumn59||'') : '';
    this.customColumn60 = user ? (user.customColumn60||'') : '';
    this.customColumn61 = user ? (user.customColumn61||'') : '';
    this.customColumn62 = user ? (user.customColumn62||'') : '';
    this.customColumn63 = user ? (user.customColumn63||'') : '';
    this.customColumn64 = user ? (user.customColumn64||'') : '';
    this.customColumn65 = user ? (user.customColumn65||'') : '';
    this.customColumn66 = user ? (user.customColumn66||'') : '';
    this.customColumn67 = user ? (user.customColumn67||'') : '';
    this.customColumn68 = user ? (user.customColumn68||'') : '';
    this.customColumn69 = user ? (user.customColumn69||'') : '';
    this.customColumn70 = user ? (user.customColumn70||'') : '';
    this.customColumn71 = user ? (user.customColumn71||'') : '';
    this.customColumn72 = user ? (user.customColumn72||'') : '';
    this.customColumn73 = user ? (user.customColumn73||'') : '';
    this.customColumn74 = user ? (user.customColumn74||'') : '';
    this.customColumn75 = user ? (user.customColumn75||'') : '';
    this.customColumn76 = user ? (user.customColumn76||'') : '';
    this.customColumn77 = user ? (user.customColumn77||'') : '';
    this.customColumn78 = user ? (user.customColumn78||'') : '';
    this.customColumn79 = user ? (user.customColumn79||'') : '';
    this.customColumn80 = user ? (user.customColumn80||'') : '';
    this.customColumn81 = user ? (user.customColumn81||'') : '';
    this.customColumn82 = user ? (user.customColumn82||'') : '';
    this.customColumn83 = user ? (user.customColumn83||'') : '';
    this.customColumn84 = user ? (user.customColumn84||'') : '';
    this.customColumn85 = user ? (user.customColumn85||'') : '';
    this.customColumn86 = user ? (user.customColumn86||'') : '';
    this.customColumn87 = user ? (user.customColumn87||'') : '';
    this.customColumn88 = user ? (user.customColumn88||'') : '';
    this.customColumn89 = user ? (user.customColumn89||'') : '';
    this.customColumn90 = user ? (user.customColumn90||'') : '';
    this.customColumn91 = user ? (user.customColumn91||'') : '';
    this.customColumn92 = user ? (user.customColumn92||'') : '';
    this.customColumn93 = user ? (user.customColumn93||'') : '';
    this.customColumn94 = user ? (user.customColumn94||'') : '';
    this.customColumn95 = user ? (user.customColumn95||'') : '';
    this.customColumn96 = user ? (user.customColumn96||'') : '';
    this.customColumn97 = user ? (user.customColumn97||'') : '';
    this.customColumn98 = user ? (user.customColumn98||'') : '';
    this.customColumn99 = user ? (user.customColumn99||'') : '';
    this["RoleFlag-0"] = user ? (user["RoleFlag-0"]||'') : '' ;
    this["RoleFlag-1"] = user ? (user["RoleFlag-1"]||'') : '' ;
    this["RoleFlag-2"] = user ? (user["RoleFlag-2"]||'') : '' ;
    this["RoleFlag-3"] = user ? (user["RoleFlag-3"]||'') : '' ;
    this["RoleFlag-4"] = user ? (user["RoleFlag-4"]||'') : '' ;
    this["RoleFlag-5"] = user ? (user["RoleFlag-5"]||'') : '' ;
    this["RoleFlag-6"] = user ? (user["RoleFlag-6"]||'') : '' ;
    this["RoleFlag-7"] = user ? (user["RoleFlag-7"]||'') : '' ;
    this["RoleFlag-8"] = user ? (user["RoleFlag-8"]||'') : '' ;
    this["RoleFlag-9"] = user ? (user["RoleFlag-9"]||'') : '' ;
    this["RoleFlag-10"] = user ? (user["RoleFlag-10"]||'') : '' ;
    this["RoleFlag-11"] = user ? (user["RoleFlag-11"]||'') : '' ;
    this["RoleFlag-12"] = user ? (user["RoleFlag-12"]||'') : '' ;
    this["RoleFlag-13"] = user ? (user["RoleFlag-13"]||'') : '' ;
    this["RoleFlag-14"] = user ? (user["RoleFlag-14"]||'') : '' ;
    this["RoleFlag-15"] = user ? (user["RoleFlag-15"]||'') : '' ;
    this["RoleFlag-16"] = user ? (user["RoleFlag-16"]||'') : '' ;
    this["RoleFlag-17"] = user ? (user["RoleFlag-17"]||'') : '' ;
    this["RoleFlag-18"] = user ? (user["RoleFlag-18"]||'') : '' ;
    this["RoleFlag-19"] = user ? (user["RoleFlag-19"]||'') : '' ;
    this["RoleFlag-20"] = user ? (user["RoleFlag-20"]||'') : '' ;
    this["RoleFlag-21"] = user ? (user["RoleFlag-21"]||'') : '' ;
    this["RoleFlag-22"] = user ? (user["RoleFlag-22"]||'') : '' ;
    this["RoleFlag-23"] = user ? (user["RoleFlag-23"]||'') : '' ;
    this["RoleFlag-24"] = user ? (user["RoleFlag-24"]||'') : '' ;
    this["RoleFlag-25"] = user ? (user["RoleFlag-25"]||'') : '' ;
    this["RoleFlag-26"] = user ? (user["RoleFlag-26"]||'') : '' ;
    this["RoleFlag-27"] = user ? (user["RoleFlag-27"]||'') : '' ;
    this["RoleFlag-28"] = user ? (user["RoleFlag-28"]||'') : '' ;
    this["RoleFlag-29"] = user ? (user["RoleFlag-29"]||'') : '' ;
    this["RoleFlag-30"] = user ? (user["RoleFlag-30"]||'') : '' ;
    this["RoleFlag-31"] = user ? (user["RoleFlag-31"]||'') : '' ;
    this["RoleFlag-32"] = user ? (user["RoleFlag-32"]||'') : '' ;
    this["RoleFlag-33"] = user ? (user["RoleFlag-33"]||'') : '' ;
    this["RoleFlag-34"] = user ? (user["RoleFlag-34"]||'') : '' ;
    this["RoleFlag-35"] = user ? (user["RoleFlag-35"]||'') : '' ;
    this["RoleFlag-36"] = user ? (user["RoleFlag-36"]||'') : '' ;
    this["RoleFlag-37"] = user ? (user["RoleFlag-37"]||'') : '' ;
    this["RoleFlag-38"] = user ? (user["RoleFlag-38"]||'') : '' ;
    this["RoleFlag-39"] = user ? (user["RoleFlag-39"]||'') : '' ;
    this["RoleFlag-40"] = user ? (user["RoleFlag-40"]||'') : '' ;
    this["RoleFlag-41"] = user ? (user["RoleFlag-41"]||'') : '' ;
    this["RoleFlag-42"] = user ? (user["RoleFlag-42"]||'') : '' ;
    this["RoleFlag-43"] = user ? (user["RoleFlag-43"]||'') : '' ;
    this["RoleFlag-44"] = user ? (user["RoleFlag-44"]||'') : '' ;
    this["RoleFlag-45"] = user ? (user["RoleFlag-45"]||'') : '' ;
    this["RoleFlag-46"] = user ? (user["RoleFlag-46"]||'') : '' ;
    this["RoleFlag-47"] = user ? (user["RoleFlag-47"]||'') : '' ;
    this["RoleFlag-48"] = user ? (user["RoleFlag-48"]||'') : '' ;
    this["RoleFlag-49"] = user ? (user["RoleFlag-49"]||'') : '' ;
    this["RoleFlag-50"] = user ? (user["RoleFlag-50"]||'') : '' ;
    this["RoleFlag-51"] = user ? (user["RoleFlag-51"]||'') : '' ;
    this["RoleFlag-52"] = user ? (user["RoleFlag-52"]||'') : '' ;
    this["RoleFlag-53"] = user ? (user["RoleFlag-53"]||'') : '' ;
    this["RoleFlag-54"] = user ? (user["RoleFlag-54"]||'') : '' ;
    this["RoleFlag-55"] = user ? (user["RoleFlag-55"]||'') : '' ;
    this["RoleFlag-56"] = user ? (user["RoleFlag-56"]||'') : '' ;
    this["RoleFlag-57"] = user ? (user["RoleFlag-57"]||'') : '' ;
    this["RoleFlag-58"] = user ? (user["RoleFlag-58"]||'') : '' ;
    this["RoleFlag-59"] = user ? (user["RoleFlag-59"]||'') : '' ;
    this["RoleFlag-60"] = user ? (user["RoleFlag-60"]||'') : '' ;
    this["RoleFlag-61"] = user ? (user["RoleFlag-61"]||'') : '' ;
    this["RoleFlag-62"] = user ? (user["RoleFlag-62"]||'') : '' ;
    this["RoleFlag-63"] = user ? (user["RoleFlag-63"]||'') : '' ;
    this["RoleFlag-64"] = user ? (user["RoleFlag-64"]||'') : '' ;
    this["RoleFlag-65"] = user ? (user["RoleFlag-65"]||'') : '' ;
    this["RoleFlag-66"] = user ? (user["RoleFlag-66"]||'') : '' ;
    this["RoleFlag-67"] = user ? (user["RoleFlag-67"]||'') : '' ;
    this["RoleFlag-68"] = user ? (user["RoleFlag-68"]||'') : '' ;
    this["RoleFlag-69"] = user ? (user["RoleFlag-69"]||'') : '' ;
    this["RoleFlag-70"] = user ? (user["RoleFlag-70"]||'') : '' ;
    this["RoleFlag-71"] = user ? (user["RoleFlag-71"]||'') : '' ;
    this["RoleFlag-72"] = user ? (user["RoleFlag-72"]||'') : '' ;
    this["RoleFlag-73"] = user ? (user["RoleFlag-73"]||'') : '' ;
    this["RoleFlag-74"] = user ? (user["RoleFlag-74"]||'') : '' ;
    this["RoleFlag-75"] = user ? (user["RoleFlag-75"]||'') : '' ;
    this["RoleFlag-76"] = user ? (user["RoleFlag-76"]||'') : '' ;
    this["RoleFlag-77"] = user ? (user["RoleFlag-77"]||'') : '' ;
    this["RoleFlag-78"] = user ? (user["RoleFlag-78"]||'') : '' ;
    this["RoleFlag-79"] = user ? (user["RoleFlag-79"]||'') : '' ;
    this["RoleFlag-80"] = user ? (user["RoleFlag-80"]||'') : '' ;
    this["RoleFlag-81"] = user ? (user["RoleFlag-81"]||'') : '' ;
    this["RoleFlag-82"] = user ? (user["RoleFlag-82"]||'') : '' ;
    this["RoleFlag-83"] = user ? (user["RoleFlag-83"]||'') : '' ;
    this["RoleFlag-84"] = user ? (user["RoleFlag-84"]||'') : '' ;
    this["RoleFlag-85"] = user ? (user["RoleFlag-85"]||'') : '' ;
    this["RoleFlag-86"] = user ? (user["RoleFlag-86"]||'') : '' ;
    this["RoleFlag-87"] = user ? (user["RoleFlag-87"]||'') : '' ;
    this["RoleFlag-88"] = user ? (user["RoleFlag-88"]||'') : '' ;
    this["RoleFlag-89"] = user ? (user["RoleFlag-89"]||'') : '' ;
    this["RoleFlag-90"] = user ? (user["RoleFlag-90"]||'') : '' ;
    this["RoleFlag-91"] = user ? (user["RoleFlag-91"]||'') : '' ;
    this["RoleFlag-92"] = user ? (user["RoleFlag-92"]||'') : '' ;
    this["RoleFlag-93"] = user ? (user["RoleFlag-93"]||'') : '' ;
    this["RoleFlag-94"] = user ? (user["RoleFlag-94"]||'') : '' ;
    this["RoleFlag-95"] = user ? (user["RoleFlag-95"]||'') : '' ;
    this["RoleFlag-96"] = user ? (user["RoleFlag-96"]||'') : '' ;
    this["RoleFlag-97"] = user ? (user["RoleFlag-97"]||'') : '' ;
    this["RoleFlag-98"] = user ? (user["RoleFlag-98"]||'') : '' ;
    this["RoleFlag-99"] = user ? (user["RoleFlag-99"]||'') : '' ;
  }
}
