import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Common } from "../core/constants/common";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as moment from "moment";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { SessionStorageService } from "ngx-webstorage";
import { CookieHelper } from "../core/helper/cookie-helper";
import {
  IAdmin,
  ICheckIsSuperAdminResponse,
  ILoginAdminRequest,
  ILoginAdminResponse,
} from "./models/auth.model";
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: "root" })
export class AuthService {
  private _currentAdmin: IAdmin;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private passwordPolicy: any;

  get currentAdmin(): IAdmin {
    return this._currentAdmin || {};
  }
  set currentAdmin(admin: IAdmin) {
    this._currentAdmin = {
      can_login: admin.can_login || false,
      can_change_password: admin.can_change_password || false,
    };
  }
  get canLoginAdmin(): boolean {
    return this.currentAdmin.can_login || false;
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private $sessionStorage: SessionStorageService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      sessionStorage.getItem("token")
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  loginAdmin(request: ILoginAdminRequest) {
    return this.http
      .post<ILoginAdminResponse>(
        `${environment.apiUrl}/api/v1/login`,
        request,
        {
          params: { is_admin: "1" },
        }
      )
      .pipe(
        map((data: ILoginAdminResponse) => {
          const token = data.data.token;
          sessionStorage.setItem("token", token);
          this.$sessionStorage.store("token", token);
          sessionStorage.setItem("currentUser", JSON.stringify(data.data));
          this.insertExpiredDate();
          this.currentUserSubject.next(token);
          return data;
        })
      );
  }
  getTokenFromCookie() {
    const token = CookieHelper.getCookie("saml_token");
    if (token) {
      sessionStorage.setItem("token", token);
      this.$sessionStorage.store("token", token);
      sessionStorage.setItem("currentUser", JSON.stringify({ token }));
      this.insertExpiredDate();
      this.currentUserSubject.next(token);
      CookieHelper.eraseCookie("saml_token");
      return { token };
    }
  }

  getSamlToken(tokenParam: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/v1/saml_token`, {
        token: tokenParam,
      })
      .pipe(
        map((data) => {
          const token = data.data.token;
          sessionStorage.setItem("token", token);
          this.$sessionStorage.store("token", token);
          sessionStorage.setItem("currentUser", JSON.stringify(data.data));
          this.currentUserSubject.next(token);
          return data;
        })
      );
  }

  login(user_id: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/v1/login`, { user_id, password })
      .pipe(
        map((data) => {
          const token = data.data.token;
          sessionStorage.setItem("token", token);
          this.$sessionStorage.store("token", token);
          this.insertExpiredDate();
          sessionStorage.setItem("currentUser", JSON.stringify(data.data));
          this.currentUserSubject.next(token);
          return data;
        })
      );
  }

  callLogout() {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/logout`);
  }

  logoutAdmin() {
    sessionStorage.removeItem("token");
    this.$sessionStorage.clear("token");
    this.currentUserSubject.next(null);
    const redirect = this.canLoginAdmin ? "admin-login" : "login";
    this.router.navigate([redirect]);
    sessionStorage.clear();
  }

  logoutUser() {
    const tokenCurrent = sessionStorage.getItem('token') || '';
    CookieHelper.eraseCookie("saml_token");
    sessionStorage.removeItem("token");
    this.$sessionStorage.clear("token");
    CookieHelper.eraseCookie('saml_error_user_not_found');
    sessionStorage.clear();
    if (tokenCurrent && jwt_decode(tokenCurrent).idp_name) {
      const tokenValue = jwt_decode(tokenCurrent);
      this.logoutSaml(tokenValue.idp_name);
    } else {
      this.router.navigate(['login'], { queryParams: { logout: 1 } });
    }
  }
  getPageConfigs(page) {
    return this.http
      .get(`${environment.apiUrl}/api/v1/page_configs?page=${page}`)
      .pipe(
        map((data) => {
          const config = data["data"]["page"];
          const samlSetting = data["data"]["saml-setting"];
          return {
            config: config.reduce(
              (acc, curr) => ((acc[curr.name] = curr.label), acc),
              {}
            ),
            samlSetting,
          };
        })
      );
  }
  checkReset() {
    return this.http.get(`${environment.apiUrl}/api/v1/password_policy`).pipe(
      map((data) => {
        const config = data["data"];
        this.passwordPolicy = config;
        return { config };
      })
    );
  }

  checkIsSupeAdmin() {
    return this.http
      .get<ICheckIsSuperAdminResponse>(
        `${environment.apiUrl}/api/v1/admin/is_super_admin`
      )
      .pipe(
        map((response: ICheckIsSuperAdminResponse) => response || {}),
        map((response: ICheckIsSuperAdminResponse) => response.data || {}),
        map((admin: IAdmin) => {
          this.currentAdmin = admin;
          return admin;
        })
      );
  }

  showCurrentAdmin() {
    return this.http
      .get(`${environment.apiUrl}/api/v1/admin/show_current_admin`)
      .pipe(
        map((data) => {
          if (data['data']['keyspiderManagementRole'] == '企業管理者') {
            data['data']['permissionTitle'] =
              'pages.user.company_administrator';
          } else if (data['data']['keyspiderManagementRole'] == '部門管理者') {
            data['data']['permissionTitle'] =
              'pages.user.department_administrator';
          }

          if (data['data']['is_maintaince'] == '0') {
            data['data']['showAdminScr'] = false;
          } else {
            data['data']['showAdminScr'] = true;
          }

          return { data };
        })
      );
  }

  insertExpiredDate(loginTimeExpired = null) {
    if(loginTimeExpired){
      sessionStorage.setItem("loginTimeExpired", loginTimeExpired)
    }
    loginTimeExpired = sessionStorage.getItem("loginTimeExpired") || Common.EXPIRED_DATE_ADMIN;

    let timestamp = moment()
      .add(Common.EXPIRED_DATE_ADMIN, "minutes")
      .format("x");
    sessionStorage.setItem("expired_admin", timestamp);
  }

  async closeConfirmDialog() {
    return await new Promise((resolve) => {
      let openedDialog = document.querySelector(
        ".admin-confirm-dialog.ui-confirmdialog"
      );
      openedDialog && openedDialog.querySelector("a").click();
      setTimeout(resolve, 200);
    });
  }

  async checkExpiredDate() {
    let expiredDate: any = sessionStorage.getItem("expired_admin");
    let now = moment().format("x");
    if (now > expiredDate) {
      console.log("logout when timeout after period of time");
      await this.closeConfirmDialog();
      this.callLogout().subscribe(
        (e) => {
          this.logoutAdmin();
        },
        (e) => {
          this.logoutAdmin();
        }
      );
    }
  }
  async checkExpiredDateWorkFlow() {
    let expiredDate: any = sessionStorage.getItem("expired_admin");
    let now = moment().format("x");
    if (now > expiredDate) {
      console.log("logout when timeout after period of time");
      await this.closeConfirmDialog();
      this.callLogout().subscribe(
        (e) => {
          this.logoutUser();
        },
        (e) => {
          this.logoutUser();
        }
      );
    }
  }

  public getPasswordPolicy(){
    return this.passwordPolicy;
  }

  checkIpAddress() {
    return this.http.get(`${environment.apiUrl}/api/v1/check-ip-address`);
  }

  logoutSaml(idp_name) {
    const redirectUrl = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${
      environment.apiUrl
    }/saml2/${idp_name}/logout?returnTo=${encodeURIComponent(redirectUrl)}`;
  }
}
