export const workflow_user_apply = {
    application_form : "申請起票",
    workflow : "ワークフロー",
    application_target_organization : "申請対象組織",
    approver_selection : "承認者選択",
    enter_approver_selection : "承認者選択を入力してください。",
    comment : "コメント",
    application : "申請",
    delete : "削除",
    save_as_draft : "下書き保存",
    create_user_registration_application : "ユーザー登録申請作成",
    no_app_message : "まだ申請は起票されていません。すべての申請内容を入力後に上の「申請」をクリックしてください。",
    subject : "件",
    application_contents : "申請内容",
    date_of_issue : "発令日",
    no_data : "データはありません。",
    none : "なし",
    verification : "確認",
    yes : "はい",
    no : "いいえ",
    no_application_target : "申請対象がありません",
    create_app_confirm : "ワークフロー管理者に対して申請を起票しようとしています。承認者が決定されると、この申請は差戻しされることがあります。よろしいですか？",
    submit_app_confirm : "申請を送信してもよろしいですか？",
    submitted_the_application : "申請を送信しました",
    request_deleted : "申請を削除しました",
    i_drafted_my_application : "申請を下書きしました",
    has_app_other_org_message : "他の組織で作成された申請があります。<br>この申請を送信した後、<br>再度「申請起票（ユーザー）」から<br>組織を選択し申請を送信してください。",
    delete_app_confirm : "登録した申請内容がすべて削除されますが<br/>よろしいですか？<br/>※個別に削除したい場合は、<br/>一覧から対象ユーザーの申請をクリックし<br/>「取消」を選択してください。",
    restrict_approvers: "承認者を所属部門に限定する"
}
