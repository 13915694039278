import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { createHttpParams } from "../../blocks/utils/http-helper";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class UserAttributeService {
  constructor(private http: HttpClient) {}

  getDetailList(parentId, param) {
    const params = createHttpParams({
      page: param.page,
      limit: param.numberPerPage,
      orderField: param.order_by,
      orderBy: param.order_direction,
      DeleteFlag: param.DeleteFlag,
      UpdateUserID: param.updateUser,
      UpdateDate: param.UpdateDate,
      fieldSearch: param.search_by_field,
      valueSearch: param.search
    });

    return this.http
      .get(`${environment.apiUrl}/api/v1/user_attribute/detail/${parentId}/get_list`, { params})
      .pipe(
        map(data => {
          const users = data["data"]["items"].map(user => user);
          const parent = data["data"]["parent"];
          const total = data["data"]["total"];
          const headers = data["data"]["headers"];
          return { users, parent, total, headers };
        })
      );
  }

  getAttributeSetting() {
    return this.http
      .get(`${environment.apiUrl}/api/v1/user_attribute/summary/get_list_attributes`)
      .pipe(
        map(data => {
          const attributes = data['data']
          return { attributes };
        })
      );
  }

  importAttribute(file, delimiter) {
    const formData = new FormData();
    formData.append('file', file);
    const params = {
      delimiter: delimiter,
    }
    return this.http.post(`${environment.apiUrl}/api/v1/user_attribute/summary/importAttribute`, 
    formData, 
    { params:  createHttpParams(params) })
    .pipe(
      map(data => {
          const totalRow = data['data']['totalRow']
          const error = data['data']['error']
          return {totalRow, error};
      })
    );
  }

  downloadTemplateAttribute() {
    return this.http.get(`${environment.apiUrl}/api/v1/user_attribute/summary/downloadTemplateAttribute`, {
        observe: "response",
        responseType: "blob" as "json"
    });
  }

  extractAttributeCsv(param, newParams) {
    const params = createHttpParams({
      name_file: newParams.name_file,
      end_character: newParams.end_character,
      DeleteFlag: param.DeleteFlag,
    });
    return this.http.get(`${environment.apiUrl}/api/v1/user_attribute/summary/exportAttribute`, {
      params,
      observe: "response",
      responseType: "blob" as "json"
    });
  }

  getConditionToSearch() {
    return this.http
      .get(`${environment.apiUrl}/api/v1/user_attribute/detail/get_conditions`)
      .pipe(
        map(data => {
          const fieldSearchKeywords = data["fields"];
          const extractionIds = data["extractionIds"];
          const updateUserName = data["admins"];

          return {
            fieldSearchKeywords,
            extractionIds,
            updateUserName,
          };
        })
      );
  }

  getSummaries(param){
    const params = createHttpParams({
      DeleteFlag: param.DeleteFlag,
      TableSelected: param.TableSelected
    });

    return this.http
      .get(`${environment.apiUrl}/api/v1/user_attribute/summary/get_list`, {params})
      .pipe(
        map(data => {
          const summaries = data["data"]["summaries"];
          const attributes = data["data"]["attributes"];
          const settings = data["data"]["settings"];
          const summariesAttributes = data["data"]["summariesAttributes"];

          return { summaries, attributes, settings, summariesAttributes };
        })
      );
  }

  saveOrder(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/user_attribute/summary/save-order-row`, data);
  }
  createGroup(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/group/create`, data);
  }

  createAttribute(data, target, parentId?) {
    if(target !== "summary" && target !== "detail") {
      console.error("[UserAttribute] Invalid Action");
      return;
    } else if(target === "detail" && !parentId) {
      console.error("[UserAttribute Detail] ParentId Not Setting")
    }

    if(target === "summary"){
      return this.http.post(`${environment.apiUrl}/api/v1/user_attribute/summary/create`, data);
    } else if(target === "detail") {
      return this.http.post(`${environment.apiUrl}/api/v1/user_attribute/detail/${encodeURIComponent(parentId)}/create`, data);
    }
  }

  updateGroup(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/group/update`, data);
  }

  updateAttribute(data, target, parentId?) {
    if(target !== "summary" && target !== "detail") {
      console.error("[UserAttribute Summary] Invalid Action");
      return;
    } else if(target === "detail" && !parentId) {
      console.error("[UserAttribute Detail] InvalidParentId")
    }

    if(target === "summary"){
      return this.http.post(`${environment.apiUrl}/api/v1/user_attribute/summary/update`, data);
    } else if(target === "detail") {
      return this.http.post(`${environment.apiUrl}/api/v1/user_attribute/detail/${encodeURIComponent(parentId)}/update`, data);
    }
  }


  getGroupDetail(groupID) {
    return this.http
      .get(`${environment.apiUrl}/api/v1/group/${encodeURIComponent(groupID)}/show`)
      .pipe(
        map(user => user["data"]),
        map(user => {
          return user;
        })
      );
  }

  getSummaryDetail(summaryId) {
    return this.http
      .get(`${environment.apiUrl}/api/v1/user_attribute/summary/${encodeURIComponent(summaryId)}/show`)
      .pipe(
        map(summary => summary["data"])
      );
  }

  getAttributeDetail(parentId, detailId) {
    return this.http
      .get(`${environment.apiUrl}/api/v1/user_attribute/detail/${encodeURIComponent(parentId)}/${encodeURIComponent(detailId)}/show`)
      .pipe(
        map(summary => summary["data"])
      );
  }

  downloadTemplate() {
    return this.http.get(`${environment.apiUrl}/api/v1/group/download`, {
        observe: "response",
        responseType: "blob" as "json"
    });
}

importCsv(file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.apiUrl}/api/v1/group/import`, formData, { observe: 'response', responseType: 'blob' as 'json' });
}

extractCsv(param) {
    const params = createHttpParams({
      page: param.page,
      limit: param.numberPerPage,
      orderField: param.order_by,
      orderBy: param.order_direction,
      UpdateFlags: param.search_update_flag,
      ExpiredPassword: param.ExpiredPassword,
      LockFlag: param.LockFlag,
      DeleteFlag: param.DeleteFlag,
      UpdateUserID: param.updateUser,
      EffectiveDate: param.EffectiveDate,
      UpdateDate: param.UpdateDate,
      fieldSearch: param.search_by_field,
      valueSearch: param.search
    });
    return this.http.get(`${environment.apiUrl}/api/v1/group/extract`, {
        params,
        observe: "response",
        responseType: "blob" as "json"
    });
}


importDetail(file, delimiter, parentID) {
  const formData = new FormData();
  formData.append('file', file);
  const params = {
    delimiter: delimiter,
    parentID: parentID
  }
  return this.http.post(`${environment.apiUrl}/api/v1/user_attribute/detail/importDetail`, 
  formData, 
  { params:  createHttpParams(params) })
  .pipe(
    map(data => {
        const totalRow = data['data']['totalRow']
        const error = data['data']['error']
        return {totalRow, error};
    })
  );
}

extractDetail(param, newParams, parentID) {
    const params = createHttpParams({
      name_file: newParams.name_file,
      end_character: newParams.end_character,
      parentID: parentID,
      page: param.page,
      limit: param.numberPerPage,
      orderField: param.order_by,
      orderBy: param.order_direction,
      UpdateFlags: param.search_update_flag,
      ExpiredPassword: param.ExpiredPassword,
      LockFlag: param.LockFlag,
      DeleteFlag: param.DeleteFlag,
      UpdateUserID: param.updateUser,
      EffectiveDate: param.EffectiveDate,
      UpdateDate: param.UpdateDate,
      fieldSearch: param.search_by_field,
      valueSearch: param.search
    });
    return this.http.get(`${environment.apiUrl}/api/v1/user_attribute/detail/exportDetail`, {
        params,
        observe: "response",
        responseType: "blob" as "json"
    });
}

downloadTemplateDetail() {
  return this.http.get(`${environment.apiUrl}/api/v1/user_attribute/detail/downloadTemplateDetail`, {
      observe: "response",
      responseType: "blob" as "json"
  });
}
}
