import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  HostListener
} from "@angular/core";
import { CODE_CHARACTER_TYPE, END_CHARACTER_TYPE } from "../models/csv-model";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import * as iconv from "iconv-lite";
import { GroupManagerService } from "src/app/group-manager/service/group.service";
import { OrgManagerService } from "src/app/organization-manager/service/organization.service";
import { GroupAssignManagerService } from "src/app/group-assign-manager/service/group-assign.service";
import { UserAttributeService } from "src/app/user-attribute-manager/service/user-attribute.service";
import { LoadingService } from "src/app/loading/service/loading";
import { UserManagerService } from "src/app/user-manager/services/user-manager.service";
import { Papa } from "ngx-papaparse";
import { HandleCodeService } from "src/app/function-manager/service/handle-code.service";

@Component({
  selector: "app-import-csv",
  templateUrl: "./import-csv.component.html",
  styleUrls: ["./import-csv.component.scss"],
})
export class ImportCSVComponent implements OnInit {
  @Output() onCloseModals = new EventEmitter();
  @Output() onImportCsv = new EventEmitter();
  @Input() public modalImportAction;
  @Input() public messages;
  @Input() public screen;
  @Input() public parentId;
  @Input() public userSettingDisplay;
  @Input() public functions;
  @ViewChild("import", { static: false }) import: ElementRef;
  fileData: any;
  params: any;
  codeCharacter: any;
  endCharacter: any;
  codeCharacterType = CODE_CHARACTER_TYPE;
  endCharacterType = END_CHARACTER_TYPE;
  dragAreaClass: string;
  fileName: string;
  noFileChoose: string;
  totalImportSuccess = 0;
  totalImportError = [];
  loading = false;

  constructor(
    private translate: TranslateService,
    private groupService: GroupManagerService,
    private orgService: OrgManagerService,
    private groupAssignService: GroupAssignManagerService,
    private userAttributeService: UserAttributeService,
    private loadingService: LoadingService,
    private handleCodeService: HandleCodeService,
    private userService: UserManagerService,
  ) { }

  ngOnInit() {
    this.codeCharacter = this.codeCharacterType.SJIS
    this.endCharacter= this.endCharacterType.COMMA
    this.dragAreaClass = "dragarea";
  }

  extractFile(event) {
    this.fileData = <File>event.target.files[0];
    this.fileName = this.fileData.name;
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      this.fileData = <File>event.dataTransfer.files[0];
      this.fileName = this.fileData.name;
    }
  }

  importCsv() {
    this.loading = true;
    this.loadingService.setShowLoading(true);
    const numberRows = environment.chunkSizeCSV;
    const csvEncode = this.codeCharacter;
    const delimiter = this.endCharacter;
    const fileData = this.fileData;
    if (!fileData) {
      this.noFileChoose = this.translate.instant(
        'pages.import_export_csv.not_file'
      );
      this.loading = false;
      this.loadingService.setShowLoading(false);
      return;
    }
    // 文字コード対応
    const reader = new FileReader();
    reader.readAsBinaryString(fileData);

    reader.onload = async (e) => {
      let decodeCSV = iconv.decode(Buffer.from(reader.result as string, 'binary'), csvEncode);
      if (this.screen === 'user-list') {
        decodeCSV = await this.addFunctionForCsv(decodeCSV, delimiter);
        if (!decodeCSV) {
          this.onImportCsv.emit('invalid');
          this.loading = false;
          this.loadingService.setShowLoading(false);
          const eleFile: any = document.querySelector('#file-input');
          eleFile.value = '';
          return;
        }
      }
      const headers = decodeCSV.slice(0, decodeCSV.indexOf("\n")).split(delimiter);
      const rows = decodeCSV.slice(decodeCSV.indexOf("\n") + 1).split("\n");
      const res = [];

      for (let i = 0; i < rows.length; i += numberRows) {
        const chunk = rows.slice(i, i + numberRows);
        chunk.unshift(headers[0]);
        res.push(chunk.join('\n'));
      }

      this.totalImportSuccess = 0;
      this.totalImportError = [];
      for (const [index, child] of res.entries()) {
        const blob = new Blob([child], {type: "text/plain"});
        const hasResult: any = await this.executeImportCSV(blob, delimiter);
        if (hasResult.totalRow) {
          this.totalImportSuccess += hasResult.totalRow;
        }
        if (hasResult.status) {
          if (hasResult.status === 422) {
            this.onImportCsv.emit('invalid');
            this.loading = false;
            this.loadingService.setShowLoading(false);
            const eleFile: any = document.querySelector('#file-input');
            eleFile.value = '';
            return;
          } else {
            const newErr = hasResult.error.data.error.map((err: any) => {
              const value = err;
              value.row = err.row + (index * numberRows);
              return value;
            });
            this.totalImportError = this.totalImportError.concat(newErr);
          }
        }
      }
      if (!this.totalImportError.length) {
        this.import.nativeElement.value = "";
        this.loading = false;
        this.loadingService.setShowLoading(false);
        this.onImportCsv.emit('success');
      } else {
        this.import.nativeElement.value = "";
        this.loading = false;
        this.loadingService.setShowLoading(false);
        const eleFile: any = document.querySelector('#file-input');
        eleFile.value = '';
        this.onImportCsv.emit('fail');
      }
    };
  }

  async executeImportCSV(data, delimiter) {
    let screenService: any;
    switch (this.screen) {
      case 'user-list': {
        screenService = this.userService;
        break;
      }
      case 'group-list': {
        screenService = this.groupService;
        break;
      }
      case 'organization-list': {
        screenService = this.orgService;
        break;
      }
      case 'group-assign-list': {
        screenService = this.groupAssignService;
        break;
      }
      case 'user-attribute-summary-list': {
        screenService = this.userAttributeService;
        break;
      }
      case 'user-attribute-detail-list': {
        screenService = this.userAttributeService;
        break;
      }
    }
    if (this.screen === 'user-attribute-summary-list') {
      return this.executeImportCSVAttributeSummary(screenService, data, delimiter);
    } else if (this.screen === 'user-attribute-detail-list') {
      return this.executeImportCSVAttributeDetail(screenService, data, delimiter);
    } else {
      return await new Promise((resolve) => {
        screenService.importCsv(data, delimiter).subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            resolve(err);
          }
        );
      });
    }
  }

  async executeImportCSVAttributeSummary(screenService, data, delimiter) {
    return await new Promise((resolve) => {
      screenService.importAttribute(data, delimiter).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          resolve(err);
        }
      );
    });
  }

  async executeImportCSVAttributeDetail(screenService, data, delimiter) {
    return await new Promise((resolve) => {
      screenService.importDetail(data, delimiter, this.parentId).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          resolve(err);
        }
      );
    });
  }

  closeModal() {
    this.onCloseModals.emit();
  }

  private async addFunctionForCsv(csv: string, delimiter = '') {
    try {
      let newDelimiter;

      if (delimiter === 'tab') {
        newDelimiter = '\t';
      } else {
        newDelimiter = ',';
      }
      const papa = new Papa();
      const csvParse = papa.parse(csv, { skipEmptyLines: true });

      if (csvParse.errors.length > 0) {
        return '';
      }

      if (csvParse.meta.delimiter != newDelimiter) {
        return '';
      }
      const lines = csvParse.data;
      const header = lines[0];
      const results = [header];

      for (let i = 0; i < lines.length; i++) {
        if (i === 0){
          continue;
        }
        const items = lines[i];
        let result: any = {};

        for (let y = 0; y < items.length; y++) {
          const stringHeader = header[y];
          const findSetting: any = this.userSettingDisplay.find((e: any) => e.display_name === stringHeader );
          result[findSetting.field] = items[y];
        }
        result = JSON.parse(JSON.stringify(result));

        for (const o in result) {
          if (Object.prototype.hasOwnProperty.call(result, o)) {
            if (this.functions[o]){
              const value =  await this.handleCodeService.handleCodejs({
                data: result,
                table: "User",
                codeJs: this.functions[o].code_js  ,
                target: o,
                isInputForm: false,
              });
              result[o] = value;
            }
          }
        }
        results.push(Object.values(result));
      }
      return papa.unparse(results, {delimiter: newDelimiter});

    } catch (error) {
      return null;
    }
  }
}
