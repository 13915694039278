export const validation = {
  input_item_control_setting : '入力項目制御設定',
  item_selection : '項目選択',
  search_value : '＝検索値',
  master_name : 'マスター名',
  create_new : '新規作成',
  subject : '件',
  no_matching_data_found : '該当データが見つかりません',
  acquiring_data : 'データ取得中',
  edit : '編集',
  sign_up : '新規登録',
  item_name : '項目名',
  input_item_control : '入力項目制御',
  lock_flag : 'ロックフラグ',
  delete : '削除',
  normal : '正常',
  uneditable : '編集不可',
  editable : '編集可',
  authority_name : '権限名',
  value: '値',
  add: {
    keep: '保存',
    delete: '削除',
    close_up: '閉じる',
    it_is_illegal : 'が不正です。',
    min_char_less_than_max_char_msg : '最小文字数は最大文字数より小さい値を入力してください。',
    data_registered : 'データを登録しました。',
    updated_data : 'データを更新しました。',
    update_flag : '更新フラグ'
  }
}