import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'checkbox',
    template: `
      <div [formGroup]="form">
          <div *ngFor="let opt of field.options" >
             <input [formControlName]="opt.name ? opt.name : field.name" 
                class="form_control" 
                type="checkbox" 
                id="inlineCheckbox1" 
                [attr.disabled]="disabled ? true : null"
             />
             {{opt.label}}
          </div>

      </div> 
    `,
    styleUrls: ['./style.scss']
})
export class CheckBoxComponent {
    @Input() field: any = {};
    @Input() form: UntypedFormGroup;
    @Input() disabled: boolean = false;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
}