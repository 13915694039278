export class CookieHelper {
  static setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  static getCookie(name) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  static eraseCookie(name) {
    const href = window.location.href;
    const url = new URL(href);
    const domain = `.${url.hostname}`;
    document.cookie = `${name}=; path=/; domain=${domain}; expires=${new Date(0).toUTCString()}`;
    //document.cookie = name + '=; Max-Age=-99999999;';
  }
}
