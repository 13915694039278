import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { ISelectOption } from "src/app/core/models/select-option.model";

@Component({
  selector: "select-filter",
  templateUrl: "./selectFilter.html",
  styleUrls: ["./selectFilter.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelectFilter implements OnInit, OnDestroy, OnChanges {
  @ViewChild("dropdownFilter", { static: true }) dropdownFilter;
  @Input() field: any = {};
  @Input() form: UntypedFormGroup;
  @Input() value: string;
  @Input() styleClass: string;
  @Input() disabled: boolean | any;
  @Input() options: ISelectOption[];
  @Input() excludeEmpty: boolean | any;
  @Input() filter: boolean = true;
  @Input() virtualScroll: boolean = true;
  @Output() valueChange = new EventEmitter();
  selectedOption: ISelectOption;
  selectedOptionIDFromForm: string;

  _styleClass: string = "select-org-output";
  _selectOptions: any[] = [];
  _panelStyle: { [key: string]: string } = {};

  get showClear() {
    return !(!this.selectedOption || !this._selectOptions.length || this._selectOptions[0].ID === this.selectedOption.ID) &&
      this._selectOptions[0].ID === '';
  }

  ngOnInit(): void {
    this.updateData();
    if (this.form) {
      const control = this.form.get(this.field.name);
      if (control) {
        control.valueChanges.subscribe((value) => {
          this.changeInputFromFormControl(value, control);
        });
      }
    }
    window.addEventListener("resize", this.onWindowResize);

    this.onWindowResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    setTimeout(() => {
      const panelWidth = (
        this.dropdownFilter.el.nativeElement.getElementsByClassName(
          "select-org-output"
        )[0] || {}
      ).offsetWidth;
      const panelStyle = {};
      if (panelWidth) {
        panelStyle["width"] = `${panelWidth}px`;
      }
      if (Object.keys(panelStyle).length) {
        this._panelStyle = panelStyle;
      } else {
        this._panelStyle = {};
      }
    });
  };

  updateData() {
    if (this.options && this.options.length) {
      this._selectOptions = [...this.options];
    } else if (this.field && this.field.options && this.field.options.length) {
      this._selectOptions = [...this.field.options];
    } else {
      this._selectOptions = [];
    }
    if (!this.excludeEmpty) {
      // add empty to first
      const first = this._selectOptions[0];
      if (first && first.ID) {
        this._selectOptions.unshift({ ID: "", Name: "" });
      }
    } else {
      // remove empty from first
      const first = this._selectOptions[0];
      if (first && !first.ID) {
        this._selectOptions.shift();
      }
    }
    this.selectedOption = this._selectOptions.find(
      (o: any) => o.ID == (this.value || this.selectedOptionIDFromForm)
    );
    const classList = ["select-org-output"];
    if (this.styleClass) {
      classList.push(this.styleClass);
    }
    this._styleClass = classList.join(" ");
    if (
      this.form &&
      this.field &&
      this.field.name &&
      this.disabled === undefined &&
      this.field.disable === undefined
    ) {
      const control = this.form.get(this.field.name);
      if (control) {
        this.field.disable = control.disabled;
      }
    }
  }

  onChangeSelectOption() {
    const ID = (this.selectedOption || {}).ID || "";
    this.valueChange.emit(ID);

    if (this.form) {
      const control = this.form.get(this.field.name);
      if (control) {
        control.setValue(ID);
      }
    }
  }

  changeInputFromFormControl(value, control?: AbstractControl) {
    this.selectedOptionIDFromForm = value;
    this.selectedOption = this._selectOptions.find((o: any) => o.ID == value);
    if (control && this.field) {
      this.field.disable = control.disabled;
    }
  }
}
