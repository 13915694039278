import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageService } from "ngx-webstorage";
import { environment } from "src/environments/environment";
import {Common} from "../../core/constants/common";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private localStorage: LocalStorageService
  ) {}
  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Secret: "197224611",
      },
    });
    if (
      !request ||
      !request.url ||
      (/^http/.test(request.url) &&
        !(environment.apiUrl && request.url.startsWith(environment.apiUrl)))
    ) {
      return next.handle(request);
    }
    // add authorization header with basic auth credentials if available
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
          locale : this.getCookie('locale') || Common.LOCALE_DEFAULT
        },
      });
    }

    return next.handle(request);
  }
}
