import { MessageService } from "primeng/api";
import { AutofocusDirective } from "./../blocks/directives/autofocus";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID,ModuleWithProviders } from "@angular/core";
import { InputTrimModule } from "ng2-trim-directive";
import { SharedLibsModule } from "../shared/shared-libs.module";
import { DatePipe } from "@angular/common";
import { AktSharedCommonModule } from "./shared-common.module";

@NgModule({
  imports: [InputTrimModule, SharedLibsModule, AktSharedCommonModule],
  declarations: [AutofocusDirective],
  providers: [
    MessageService,
    {
      provide: LOCALE_ID,
      useValue: "en"
    },
    DatePipe
  ],
  exports: [
    AutofocusDirective,
    InputTrimModule,
    SharedLibsModule,
    AktSharedCommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule
    };
}
}
