import { MessageService } from "primeng/api";
import { Component, OnInit, OnDestroy ,ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { first, finalize, takeUntil } from "rxjs/operators";

import { ResetPassService } from "src/app/services/reset-pass.service";
import { Common } from "../../../core/constants/common";
import { AuthService } from "src/app/services/auth.service";
import { Subject } from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: "app-password-send",

  templateUrl: "./password-send.component.html",
  styleUrls:['../../../../user-workflow.scss','password-send.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordSendComponent implements OnInit, OnDestroy {
  sendMailForm: UntypedFormGroup;
  error = false;
  submitted = false;
  errorMessage: "";
  loading = false;
  ngUnsubscribe = new Subject();
  show = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private resetService: ResetPassService,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getConfig();
    this.sendMailForm = this.formBuilder.group({
      email: [
        "",
        [Validators.required, Validators.pattern(Common.EMAIL_PATTERN)],
      ],
      UserID: ["", [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.unsubscribe();
  }

  get email() {
    return this.sendMailForm.controls.email;
  }

  get UserID() {
    return this.sendMailForm.controls.UserID;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.sendMailForm.controls;
  }

  isFieldValid(field: string) {
    return (
      !this.sendMailForm.get(field).valid &&
      this.sendMailForm.get(field).touched
    );
  }

  private getConfig() {
    this.loading = true;
    this.authService
      .checkReset()
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data) => {
        this.loading = false;
        if (!data.config.AllowReset) {
          this.router.navigate(['404']);
          return;
        }
        this.show = true;
      });
  }

  onSubmit() {
    this.loading = true;
    this.submitted = true;
    this.sendMailForm.controls['email'].setErrors(null);
    this.errorMessage = "";

    this.resetService
      .sendMail(this.f.email.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.emitUpdateSuccessEvent(data.message);
          this.sendMailForm.reset();
          this.submitted = false;
          this.error = false;
          this.loading = false;
        },
        (error) => {
          if (error.status === 404) {
            this.router.navigate(['404']);
            return;
          }
          let errors =error.error.errors;
          if (errors) {
            if(errors.email){
              this.sendMailForm.controls.email.setErrors({ error: errors.email[0] });
            }
            if(errors.UserID){
              this.sendMailForm.controls.UserID.setErrors({ error: errors.UserID[0] });
            }
          } else {
            if(error.error.errorMessage){
              this.errorMessage = error.error.errorMessage;
            }
          }
          this.loading = false;
          this.submitted = false;
          this.error = true;
        }
      );
  }

  private emitUpdateSuccessEvent(message?) {
    this.messageService.add({
      severity: "success",
      summary: "",
      detail: message || `Successfully`,
    });
  }
}
