export const user = {
    user_information_management: 'ユーザー情報管理',
    company_administrator: '（企業管理者）',
    template: 'テンプレート',
    import: 'インポート',
    export: 'エクスポート',

    csv_template: 'CSVテンプレート',
    csv_import: 'CSVインポート',
    csv_export: 'CSVエクスポート',
    string_search: '文字列検索',
    item: '項目',
    search_value: '検索値',
    department_search: '部門検索',
    search_including_child_organizations: '子組織を含めて検索　',
    search_including_concurrent_departments: '副所属を含めて検索',
    service_user_search: 'サービス利用者検索',
    date_search: '日付検索',
    from: 'から',
    to: 'まで',
    status_display: 'ステータス表示',
    effectiveness: '有効',
    pause: '一時停止',
    invalidation: '無効化',
    expired: '有効期限切れ',
    item_selection: '項目選択',
    _search_value: '＝検索値',
    organization: '組織',
    subordinate_organization: '下位組織',
    authority: '権限',
    date_of_issue: '発令日',
    update_date: '更新日',
    changer: '更新者',
    update_flag: '更新フラグ',
    delete_flag: '削除フラグ',
    account_lock: 'アカウントロック',
    password_expired: 'パスワード有効期限切れ',
    create_new: '新規作成',
    delete: '削除',
    subject: '件',
    use: '利用する',
    dont_use: '利用しない',
    no_matching_data_found: '該当データが見つかりません',
    acquiring_data: 'データ取得中',
    edit: 'ユーザー情報編集',
    sign_up: '新規登録',
    are_you_sure_you_want_to_delete_this_record : 'このレコードを削除しますか？',
    yes : 'はい',
    no : 'いいえ',
    remove_confirmation : '確認を削除',
    warning_has_record_change : '発令日が翌日以降のレコードはすぐに反映されません。登録した情報は「発令情報管理」で確認できます。 <br/>未反映の発令情報が登録されているユーザーに対して、これらデータを反映すると情報の不整合が起こる場合があります。必要に応じて発令情報を修正してください。',
    notification : '通知',
    remove_user : 'ユーザーを削除',
    delete_user_success : 'ユーザーの成功を削除する',
    error : 'エラー',
    you_have_not_selected_an_item_yet : 'まだアイテムを選択していません',
    normal : '正常',
    lock : 'ロック',
    external : '外部',
    with_update : '更新あり',
    no_updates : '更新なし',
    import_successful : 'CSVインポートが成功しました。',
    cannot_input_number_check_your_error_log : 'バリデーションチェックによるエラーでいくつかのデータがインポートできませんでした。エラーログを確認してください。',
    file_format_is_invalid : 'CSVファイルのフォーマットが不正です。',
    not_all_data_could_be_imported_check_your_error_log : 'バリデーションチェックによるエラーで全てのデータがインポートできませんでした。エラーログを確認してください。',

    add: {
        use : '利用する',
        dont_use : '利用しない',
        assigned_license : '割当てライセンス',
        none : "なし",
        basic_information : "基本情報",
        system_usage_authority : "システム利用権限",
        custom_field : "カスタム項目",
        msg_is_not_updated_immediately : "発令日が翌日以降です。ユーザー情報はすぐに更新されません。",
        you_can_check_the_registered_information_in_issuance_information_management : "登録した情報は「発令情報管理」で確認できます。",
        msg_confirm_edit : "発令日が翌日以降です。ユーザー情報はすぐに更新されません。<br/>登録した情報は「発令情報管理」で確認できます。",
        yes : "はい",
        confirmation : "確認",
        please_enter : "を入力してください。",
        the_email_address_format_is_invalid : "メールアドレスのフォーマットが不正です。",
        data_registered : "データを登録しました。",
        updated_data : "データを更新しました。",
        update_flag : "更新フラグ",
        organization : "組織",
        with_updates : "（更新あり）",
        no_update : "（更新なし）",
        delete_flag : "削除フラグ",
        account_lock : "アカウントロック",
        normal : "（正常）",
        lock : "（ロック）",
        date_of_issue : "発令日",
        password : "パスワード",
        user_information_management_authority : "ユーザー情報管理権限",
        workflow_permissions : "ワークフロー権限",
        password_update_date : "パスワード更新日",
        update_date : "更新日",
        cancel : '閉じる',
        keep : '保存',
        delete : '削除',
        restoration : '復元',
        update_flag_field: '更新フラグ・外部ID'
    }
};
