export const saas_link_setting = {
    add: {
        display_name_when_displaying_this_service : 'Keyspider管理画面でこのサービスを表示するときの表示名です。',
        name_by_which_keyspider_identifies_this_service_internally_it_is_automatically_generated : 'Keyspiderが内部的にこのサービスを識別するときの名称です。定義名から自動生成するため変更はできません。',
        command_is_used_when_keyspider_internally_executes_linkage_the_values_to_be_entered_vary_depending : 'Keyspiderが内部的に連携を実行するときのコマンドです。入力する値は連携サービスによって変わりますので、詳細はサポートにお問い合わせください。',
        command_is_used_when_keyspider_internally_executes_linkage : 'Keyspiderが内部的に連携を実行するときのコマンドです。',
        scim_enabled_service_input : 'SCIM対応サービス（入力）',
        scim_compatible_service_output : 'SCIM対応サービス（出力）',
        select_whether_to_enable_privilege_assignment_by_keyspider : 'Keyspiderの自動権限付与で権限割当てを可能とするかを選択します。',
        enter_the_number_of_licenses_purchased_for_the_service_here : 'Keyspiderのライセンス数超過チェックを行う場合はここにサービスで購入しているライセンスの数を入力します。',
        select_where_to_place_the_csv_file_output : 'Keyspiderが出力したCSVファイルの配置先を選択します。',
        select_the_type_of_relational_database : '接続するリレーショナルデータベースの種類を選択します（MySQL/PostgreSQL/MS SQL Server/Oracleから選択可能です）。',
        enter_the_name_of_the_server_running_the_relational_database : '接続するリレーショナルデータベースが稼働しているサーバー名を入力します。Keyspiderコネクタをご利用の場合は「127.0.0.1」を入力します。',
        enter_the_port_number_to_connect : '接続するポート番号を入力します。Keyspiderコネクタをご利用の場合はコネクタ側で設定したポート番号を入力します。',
        ldap_service : 'LDAPサービス',
        select_the_type_of_ldap_service_to_connect : '接続するLDAPサービスの種類を選択します（Active Directory/389ds/OpenLDAPから選択可能です）。',
        select_type_of_ldap_service_to_connect: "接続するLDAPサービスの種類を選択します",
        enter_the_name_of_the_server_running_the_ldap_service_to_connect : '接続するLDAPサービスが稼働しているサーバー名を入力します。Keyspiderコネクタをご利用の場合は「127.0.0.1」を入力します。',
        enter_the_ldap_username_to_use_for_the_connection : '接続に使用するLDAPユーザー名を入力します。Active Directoryの場合はユーザーのログオン名の@より前（admin@keyspider.local→admin）を入力します。',
        enter_the_password_of_the_ldap_user_to_use_for_the_connection : '接続に使用するLDAPユーザーのパスワードを入力します。',
        ssl_connection : 'SSL接続',
        ssl_encrypts_requests_from_keyspider : 'KeyspiderからのリクエストをSSL暗号化します。',
        enter_the_domain_controller_server_name_to_connect : '接続するドメインコントローラーサーバー名を入力します。Keyspiderコネクタをご利用の場合は「127.0.0.1」を入力します。',
        enter_the_active_directory_username_to_use_for_the_connection : '接続に使用するActive Directoryユーザー名を入力します。Active Directoryの場合はユーザーのログオン名の@より前（admin@keyspider.local→admin）を入力します。',
        enter_the_password_of_the_active_directory_user_to_use_for_connection : '接続に使用するActive Directoryユーザーのパスワードを入力します。',
        ssl_encrypts_requests_from_keyspider_when_performing_password_linkage_it_must_be_set_to_enable : 'KeyspiderからのリクエストをSSL暗号化します。パスワード連携を行う場合は必ず「有効」にする必要があります。',
        db_service : 'DBサービス',
        linked_service_settings : '連携サービス設定',
        service_name : 'サービス名',
        name_of_this_service : 'このサービスの名称です。',
        display_name : '表示名',
        enable_integration : '連携の有効化',
        linking_can_be_started_by_setting_enable : '「有効」にすると連携を開始することができます。「詳細設定」でユーザーやグループ、組織ごとに有効化するまでは実際の連携は行われません。',
        effectiveness : '有効',
        invalid : '無効',
        definition_name : '定義名',
        enter_an_alphanumeric_identifier_for_the_service_cannot_overlap_with_other_services : 'サービスの識別子を英数字で入力します。他のサービスと重複はできません。',
        synchronous_execution_command : '同期実行コマンド',
        automatic_grant_target : '自動権限付与対象',
        subject : '対象',
        number_of_purchased_licenses : '購入ライセンス数',
        excluded_from_license_count : 'ライセンスカウント対象外',
        role_license : 'ロール（ライセンス）',
        select_one_or_more_roles_or_licenses : 'このサービスで割り当てることのできるロールまたはライセンスを選択します（複数指定可）。ここにないものは「ロール（ライセンス）設定」で追加します。',
        addition : '追加',
        connection_destination_setting : '接続先設定',
        file_storage : 'ファイルストレージ',
        external_storage : '外部ストレージ',
        destination_server : '接続先サーバー',
        port_number : 'ポート番号',
        connection_user : '接続ユーザー',
        enter_the_database_username_to_use_for_the_connection : '接続に使用するデータベースユーザー名を入力します。',
        password : 'パスワード',
        enter_the_password_of_the_database_user_to_use_for_the_connection : '接続に使用するデータベースユーザーのパスワードを入力します。',
        item_name : '項目名',
        value : '値',
        add_item : '項目追加',
        confirm : '確定',
        delete : '削除',
        clear : 'クリア',
        close_up : '閉じる',
        application_id : 'アプリケーションID',
        id_assigned_to_the_keyspider_registered_in_azuread : 'AzureADに登録したKeyspiderに割り当てられたID。AzureAD管理画面の「アプリの登録」から確認できます。',
        directory_id : 'ディレクトリID',
        id_for_identifying_the_azuread_tenant_to_be_linked : '連携するAzureADのテナントを識別するためのID。AzureAD管理画面の「アプリの登録」から確認できます。',
        client_secret : 'クライアントシークレット',
        secret_value_issued_for_authentication_of_keyspider_registered_in_azuread : 'AzureADに登録したKeyspiderの認証のために発行されたシークレット値。AzureAD管理画面の「アプリの登録」>「証明書とシークレット」で発行します。',
        client_id : 'クライアントID',
        the_id_assigned_to_the_keyspider_registered_with_the_service : 'サービスに登録したKeyspiderに割り当てられたID。',
        secret_value_issued_for_authentication_of_keyspider_registered_with_the_service : 'サービスに登録したKeyspiderの認証のために発行されたシークレット値',
        enterprise_id : 'エンタープライスID',
        id_for_identifying_the_linked_tenant : '連携するテナントを識別するためのID。',
        customer_id : 'カスタマーID',
        application_creation_username : 'アプリケーション作成ユーザー名',
        password_of_the_above_user : '上記ユーザーのパスワード',
        scim_endpoint : 'SCIMエンドポイント',
        url_of_the_service_where_keyspider_sends_scim_requests : '"KeyspiderがSCIMリクエストを送信するサービス側のURL.',
        credentials : 'クレデンシャル',
        secret_value_issued_for_keyspider_connection_authentication : 'Keyspiderの接続認証のために発行されたシークレット値',
        service_side_url_where_keyspider_sends_scim_requests : 'KeyspiderがSCIMリクエストを送信するサービス側のURL',
        region : 'リージョン',
        region_where_the_tenant_is_housed_enter_us_or_eu : 'テナントが収容されているリージョン。\"us\"または\"eu\"を入力します。',
        please_enter_a_number_greater_than_or_equal_to_0 : '0以上の数字を入力してください。',
        please_enter_a_number_less_than_or_equal_to_2147483647 : '2,147,483,647以下の数値を入力してください。',
        please_enter : 'を入力してください。',
        please_enter_in_alphanumeric_characters : 'に英数字で入力してください。',
        item_name_cannot_contain_characters : '項目名に英数字、記号（「_.-」のみ）を入力してください。',
        created_a_link_destination_setting : '連携先設定を作成しました。',
        changed_the_connection_destination_setting : '連携先設定を変更しました。',
        can_i_delete_the_linked_service_settings : '連携先サービス設定を削除してもいいですか？',
        removed_link_destination_service_settings : '連携先サービス設定を削除しました',
        confirmation : '確認',
        yes : 'はい',
        no : 'いいえ',
    },
    list: {
        ldap_service : "LDAPサービス",
        service_name : "サービス名称",
        service_type : "サービス種類",
        input : "入力",
        output : "出力",
        number_of_licenses : "ライセンス数",
        authority : "権限",
        automatic_authorization : "自動権限付与",

        linked_service : "連携サービス",
        create_new : "新規作成",
        advanced_setting : "詳細設定",
        addition : "追加",
        effectiveness : "有効",
        invalid : "無効",
        no_matching_data_found : "該当データが見つかりません",
        acquiring_data : "データ取得中",
    },
    form_advance: {
        name_project: 'Keyspider',
        status: '連携',
        status_label: '連携する',
        tooltip: {
            custom_status_help_msg: '「有効」にするとサービスとの連携を開始します（設定が完了するまでは「有効」にしないでください）。',
            normal_status_help_msg: '「有効」にするとサービスとの連携処理を開始します（設定が完了するまでは「有効」にしないでください）。',
            file_name_in_csv: 'KeyspiderにアップロードされるCSVファイル名を入力します。',
            file_name_in_rdb: 'Keyspiderが情報を取得するテーブルまたはビューの名前を入力します。',
            file_name_in_ldap: 'Keyspiderが情報を取得するLDAPツリーのベースDNを入力します。',
            file_name_of_ldap: 'Keyspiderが情報を取得するADツリーのベースDNを入力します。',
            file_name_out_rdb: 'Keyspiderが情報を連携するテーブルの名前を入力します。',
            file_name_out_ad_and_ldap: '連携先でレコードを一意に識別する項目名を指定します。',
            rdb_connect_config: {
              primary_column: '連携先でレコードを一意に識別する項目名を指定します。',
            },
            ldap_connect_config: {
              ldap_search_column: '「連携先検索属性名」で取得した値と、ここで選択した項目の値が同じレコードを関連づけます。'
            },
            file_name_out_csv: 'Keyspiderが出力するCSVファイル名を入力します。',
            process_condition: 'このサービスに連携する条件を指定します。Keyspider項目と値の組み合わせで指定ができます。複数指定が可能で、条件は「AND」で評価されます。',
            diff_update_method: {
                user: '（ユーザー情報のみ）KeyspiderにアップロードされるCSVファイルにすべてのデータが含まれる場合は「全件」、更新のあったデータのみが含まれる場合は「差分」を選択します。',
                organization: '組織の場合は差分ファイルのみ使用できます。',
                group: 'グループの場合は差分ファイルのみ使用できます。',
                user_to_group: 'グループメンバーの場合は差分ファイルのみ使用できます。',
            },
            primary_column: 'レコードを一意に識別する項目を入力します。この項目はKeyspiderのIDにマッピングします。',
            execution_time: 'このサービスへの連携時間を指定します。手動で連携する場合は「指定しない」を選択してください。複数時刻に連携する場合は「カスタム」の欄にカンマ区切りで入力（例：0:00,1:00,1:30）します。',
            saas_name: {
                in: {
                    csv: '項目名 : CSVファイルの項目名を選択します。項目名は追加することができます。固定値/関数 : 固定値を入力したり、関数を使用する場合はこちらに入力します（使用可能な関数はマニュアル参照）。',
                    rdb: '項目名 : データベーステーブルの項目名を選択します。項目名は追加することができます。固定値/関数 : 固定値を入力したり、関数を使用する場合はこちらに入力します（使用可能な関数はマニュアル参照）。',
                    ldap: '項目名 : LDAPサービスの項目名を選択します。項目名は追加することができます。固定値/関数 : 固定値を入力したり、関数を使用する場合はこちらに入力します（使用可能な関数はマニュアル参照）。',
                    saas: '項目名 : サービス側の項目名を選択します。項目名は追加することができます。固定値/関数 : 固定値を入力したり、関数を使用する場合はこちらに入力します（使用可能な関数はマニュアル参照）。',
                },
                out: {
                    rdb: 'Keyspiderの項目をマッピングするデータベーステーブルの項目名を選択します。項目名は追加することができます。',
                    ad: 'Keyspiderの項目をマッピングするActive Directoryの項目名を選択します。項目名は追加することができます。',
                    ldap: 'Keyspiderの項目をマッピングするLDAPサービスの項目名を選択します。項目名は追加することができます。',
                    csv: 'Keyspiderの項目をマッピングするCSVの項目名を選択します。項目名は追加することができます。',
                }
            },
            name_db: {
                out: '項目名 : Keyspider項目を選択します。固定値/関数 : 固定値を入力したり、関数を使用する場合はこちらに入力します（使用可能な関数はマニュアル参照）。',
                in: {
                    csv: 'CSVファイルの項目をマッピングするKeyspider項目を選択します。',
                    rdb: 'データベーステーブルの項目をマッピングするKeyspider項目を選択します。',
                    ldap: 'LDAPサービスの項目をマッピングするKeyspider項目を選択します。',
                    saas: 'サービス側の項目をマッピングするKeyspider項目を選択します。',
                }
            }
        },
        file_name_label: {
            csv: 'CSVファイル名',
            in_rdb: 'テーブル名',
            in_ldap: 'ベースDN',
        },
        err_msg: {
            file_name: 'CSVファイル名を入力してください。',
            import_table: 'テーブル名を入力してください。',
            ldap_base_dn: 'ベースDNを入力してください。',
            ldap_connect_config: {
                ldap_search_path: '連携先検索属性名を入力してください。',
                ldap_search_column: 'Keyspider検索属性名を入力してください。',
            },
            rdb_connect_config: {
                export_table: '連携先テーブル名を入力してください。',
                primary_column: '連携先ID項目名を入力してください。'
            },
            prefix: 'CSVファイル名を入力してください。',
            primary_column: 'ID項目名を入力してください。',
            execution_time: {
                hour: '時間を選択してください。',
                minute: '分を選択してください。',
                value_multi: '時刻を〇:〇〇の形式でコンマ区切りで複数を入力してください。'
            },
            condition: {
                in: {
                    key: '値を選択してください。',
                    value_input: '固定値・関数を入力してください。',
                    value_select: '項目名を選択してください。',
                },
                out: {
                    key_rdb: 'を入力してください。',
                    key: '値を選択してください。',
                    value_input: '固定値・関数を入力してください。',
                    value_select: '項目名を選択してください。'
                },
                must_enable: 'これは必須項目なので有効にする必要があります。',
            },
            process_condition: {
                key: '連携条件を選択してください。',
                value: '値を入力してください。',
            },
            can_not_disable: 'Keyspiderで必須項目がありますので無効にすることができません',
        },
        rdb_connect_config: {
            export_table: '連携先テーブル名',
            primary_column: '連携先ID項目名'
        },
        ldap_connect_config: {
            ldap_search_path: '連携先検索属性名',
            ldap_search_column: 'Keyspider検索属性名',
        },
        process_condition_label: '連携条件',
        diff_update_method: {
            label: '全件／差分',
            diff: '全件',
            all: '差分',
        },
        primary_column: 'ID項目名',
        execution_time: {
            label: '連携時間',
            non: '指定しない',
            daily: '日次',
            hour: '時',
            minute: '分',
            multi: 'カスタム​',
        },
        condition_label: 'マッピング設定​',
        status_condition: {
            label_true: '有効',
            label_false: '無効',
            label_type_input: '項目名',
            label_type_select: '固定値/関数',
        },
        table_name: {
            user: 'ユーザー情報',
            organization: '組織情報',
            group: 'グループ情報',
            user_to_group: 'グループメンバー情報',
        },
        popup_add_attr: {
          title: '新しい項目を追加',
          display_name: '表示名',
          attribute: '物理項目名',
          msg_err: {
            display_name: '表示名を入力してください。',
            attribute_required: '物理項目名を入力してください。',
            attribute_format: '項目名に英数字、記号（「_.-」のみ）を入力してください。',
          }
        }
    }

};
