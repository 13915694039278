import { CustomValidators } from "src/app/blocks/validators/custom-validators";
import { MessageService } from "primeng/api";
import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ResetPassService } from "src/app/services/reset-pass.service";
import { first, finalize, takeUntil } from "rxjs/operators";
import { UntypedFormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
import { comparePasswords } from "src/app/blocks/validators/password.validator";
import { AuthService } from "src/app/services/auth.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls:['../../../../user-workflow.scss','password-reset.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  token: string;
  error: boolean;
  form: UntypedFormGroup;
  submitted = false;
  errorMessage: "";
  ngUnsubscribe = new Subject();
  loading = false;
  success: boolean;
  minlength: number;
  minNumberCondition: number;
  dataConfig: any;
  checkError = false;
  errorReuse = false;
  uppperStr = "";
  lowerStr = "";
  numberStr = "";
  specialStr = "";
  showHint = true;
  max = 0;
  hintTxt = "";

  constructor(
    private route: ActivatedRoute,
    private resetService: ResetPassService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private authService: AuthService
  ) {
    this.token = this.route.snapshot.queryParams.token;
  }

  ngOnInit() {
    this.checkExpireToken();
    this.getConfig();
    this.createForm();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.unsubscribe();
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    const formValue = this.form.value;
    const params = {
      password: formValue.password,
      confirm_password: formValue.confirm_password,
      token: this.token,
    };

    this.errorMessage = "";
    this.form.controls['password'].setErrors(null);
    this.form.controls['confirm_password'].setErrors(null);

    this.resetService.resetPass(params).subscribe(
      (data) => {
        this.loading = false;
        this.showSuccessMessage("パスワード再設定が完了しました");
        this.success = true;
        this.authService.logoutUser();
        
      },
      (error) => {
        if (error.error.code === 406) {
          this.errorReuse = true;
          this.errorMessage = error.error.message;
        } else {
          let errors = error.error.errors;
          if (errors) {
            if(errors.password){
              this.form.controls.password.setErrors({ error: errors.password[0] });
            }
            if(errors.confirm_password){
              this.form.controls.confirm_password.setErrors({ error: errors.confirm_password[0] });
            }
          } else {
            if(error.error.errorMessage){
              this.errorMessage = error.error.errorMessage;
            }
          }
        }
        
        this.loading = false;
        this.submitted = false;
      }
    );
  }

  get password() {
    return this.form.controls.password;
  }

  get confirm_password() {
    return this.form.controls.confirm_password;
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  private checkExpireToken() {
    this.resetService
      .checkExpireUrl(this.token)
      .pipe(first())
      .subscribe(
        () => {
          this.error = false;
        },
        (error) => {
          this.error = true;
          this.errorMessage = error.error.message;
          setTimeout(() => {
            this.authService.logoutUser();
          }, 5000);
        }
      );
  }

  private checkToShowStr() {
    if (this.minNumberCondition === 0) {
      this.showHint = false;
    }

    if (
      this.dataConfig.RequiredUpper &&
      !this.dataConfig.RequiredLower &&
      !this.dataConfig.RequiredNumber &&
      !this.dataConfig.RequiredSymbol
    ) {
      this.uppperStr = "アルファベット大文字";
    } else if (this.dataConfig.RequiredUpper) {
      this.uppperStr = "アルファベット大文字、";
    }

    if (
      this.dataConfig.RequiredLower &&
      !this.dataConfig.RequiredNumber &&
      !this.dataConfig.RequiredSymbol
    ) {
      this.lowerStr = "小文字";
    } else if (this.dataConfig.RequiredLower) {
      this.lowerStr = "小文字、";
    }

    if (this.dataConfig.RequiredNumber && !this.dataConfig.RequiredSymbol) {
      this.numberStr = "数字";
    } else if (this.dataConfig.RequiredNumber) {
      this.numberStr = "数字、";
    }
    if (this.dataConfig && this.dataConfig.RequiredSymbol) {
      this.specialStr = "記号";
    }

    if (this.max === this.minNumberCondition) {
      this.hintTxt =
        "パスワードには、次の種類の文字を全て使う必要があります。（" +
        this.uppperStr +
        this.lowerStr +
        this.numberStr +
        this.specialStr +
        "）";
    } else {
      this.hintTxt =
        "パスワードには、次の" +
        "(" +
        this.max +
        ")" +
        "種類の文字から" +
        "(" +
        this.minNumberCondition +
        ")" +
        "種類以上の文字を使う必要があります。（" +
        this.uppperStr +
        this.lowerStr +
        this.numberStr +
        this.specialStr +
        "）";
    }
  }

  private showSuccessMessage(message?) {
    this.messageService.add({
      severity: "success",
      summary: "",
      detail: message || `Successfully`,
    });
  }

  private getConfig() {
    this.loading = true;
    this.authService
      .checkReset()
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data) => {
        this.dataConfig = data.config;
        this.minlength = data.config.MinLength;
        this.minNumberCondition = data.config.MinNumberCondition;
        this.createForm();
        this.checkToShowStr();
        this.loading = false;
      });
  }

  private createForm() {
    let validators = [];

    if (this.minlength !== 0) {
      validators = [Validators.required, Validators.minLength(this.minlength)];
    }
    let rules = [];
    this.form = this.fb.group(
      {
        password: [""],
        confirm_password: ["", validators],
      },
      { validator: comparePasswords("password", "confirm_password") }
    );

    if (this.dataConfig && this.dataConfig.RequiredUpper) {
      rules.push(/[A-Z]/);
      this.max++;
    }

    if (this.dataConfig && this.dataConfig.RequiredLower) {
      rules.push(/[a-z]/);
      this.max++;
    }

    if (this.dataConfig && this.dataConfig.RequiredNumber) {
      rules.push(/\d/);
      this.max++;
    }

    if (this.dataConfig && this.dataConfig.RequiredSymbol) {
      this.max++;
      rules.push(/[ !@#$%^&*()_+\-=\{};':"|,.<>\/?¥¯]/);
    }

    validators.push(
      CustomValidators.patternValidator(
        rules,
        {
          passValidateRules: true,
        },
        this.minNumberCondition
      )
    );

    if (this.minNumberCondition === 0 && this.minlength !== 0) {
      validators = [Validators.required, Validators.minLength(this.minlength)];
    }

    this.form.get("password").setValidators(validators);
    this.form.get("password").updateValueAndValidity();
  }
}
