export const master_manager = {
  item_selection : "項目選択",
  search_value : "=検索値",
  date_of_issue : "発令日",
  update_date : "更新日",
  changer : "更新者",
  update_flag : "更新フラグ",
  delete_flag : "削除フラグ",
  create_new : "新規作成",
  csv_import : "CSVインポート",
  csv_export : "CSVエクスポート",
  csv_template : "CSVテンプレート",
  template : "テンプレート",
  subject : "件",
  delete : "削除",
  no_matching_data_found : "該当データが見つかりません",
  acquiring_data : "データ取得中",
  edit : "編集",
  sign_up : "新規登録",
  normal : "正常",
  external : "外部",
  with_update : "更新あり",
  no_update : "更新なし",
  some_data_could_not_be_imported_due_to_validation_check_errors: "バリデーションチェックによるエラーでいくつかのデータがインポートできませんでした。エラーログを確認してください。",
  not_all_data_could_be_imported_due_to_validation_check_errors: "バリデーションチェックによるエラーで全てのデータがインポートできませんでした。エラーログを確認してください。",
  csv_import_successful: "CSVインポートが成功しました。",
  csv_file_format_is_invalid: "CSVファイルのフォーマットが不正です。",
  add: {
    delete: "削除",
    restoration: "復元",
    keep: "保存",
    close_up: "閉じる",
    please_enter : "を入力してください。",
    data_registered : "データを登録しました。",
    updated_data : "データを更新しました。",
    with_update : "更新あり",
    no_update : "更新なし",
    password_update_date : "パスワード更新日",
    update_date : "更新日",
    update_flag : "更新フラグ",
  },
  organization: {
    user_information_management: "ユーザー情報管理",
    organization_information_management: "組織情報管理",
    company_administrator: "（企業管理者）",
  },
  group: {
    group_management : "グループ管理",
    company_administrator : "企業管理者",
    group_name : "グループ名",
  },
  role: {
    authority_information_management : "権限情報管理",
    company_administrator : "企業管理者",
    authority_name : "権限名",
  }
};
