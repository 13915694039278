export const user_attribute = {
    list : {
        attribute_master_management : "カスタムマスター管理",
        company_administrator : "（企業管理者）",
        create_new : "新規作成",
        delete_flag : "削除したマスターを表示する",
        change : "変更",
        detail : "詳細",
        delete : "削除",
        there_is_no_data : "データがありません。",
        sign_up : "新規登録",
        table_name : "マスター名",
        user: "ユーザー",
        group: "グループ",
        organization: "組織",
        import: 'インポート',
        export: 'エクスポート',
        template: 'テンプレート',
        csv_import_successful: "CSVインポートが成功しました。",
        _format_is_invalid: "CSVファイルのフォーマットが不正です。",
        _all_data_validation_check_errors: 'バリデーションチェックによるエラーで全てのデータがインポートできませんでした。エラーログを確認してください。',
        _validation_check_errors: 'バリデーションチェックによるエラーでいくつかのデータがインポートできませんでした。エラーログを確認してください。'
    },
    add : {
        are_you_sure_you_want_to_delete_the : "を削除しますか？",
        keep : "保存",
        delete : "削除",
        close_up : "閉じる",
        attribute_name : "定義名",
        user_attributes : "ユーザー属性",
        organization_attributes : "組織属性",
        group_attributes : "グループ属性",
        delete_flag : "削除フラグ",
        please_enter : "を入力してください。",
        data_registered : "データを登録しました。",
        updated_data : "データを更新しました。",
        _normal : "（正常）",
        _delete : "（削除）",
        update_flag : "更新フラグ",
        table_name : "マスター名",
        restore: '復元',
        attribute : "属性",
        update_date : "更新日",
        changer : "更新者",
        display_name: '表示項目名'
    },
    detail : {
        _remove : "1 (削除)",
        attribute_master_management : "カスタムマスターの詳細",
        company_administrator : "（企業管理者）",
        return : "戻る",
        item_selection : "項目選択",
        search_value : "＝検索値",
        update_date : "更新日",
        changer : "更新者",
        delete_flag : "削除したマスターを表示する",
        create_new : "新規作成",
        subject : "件",
        no_matching_data_found : "該当データが見つかりません",
        acquiring_data : "データ取得中",
        edit : "編集",
        sign_up : "新規登録",
        name : "定義名",
        delete : "削除",
        normal : "正常",
        external : "外部",
        update_flag : "更新フラグ",
        with_update : "更新あり",
        no_updates : "更新なし",
        date_of_issue : "発令日",
        display_order : "ソート順",
        detail : "詳細",
        csv_import_was_successful : "CSVインポートが成功しました。",
        some_data_could_not_be_imported_due_to_validation_check_errors_check_your_error_log : "バリデーションチェックによるエラーでいくつかのデータがインポートできませんでした。エラーログを確認してください。",
        the_format_of_the_csv_file_is_invalid_ : "CSVファイルのフォーマットが不正です。",
        not_all_data_could_be_imported_due_to_validation_check_errors_check_your_error_log : "バリデーションチェックによるエラーで全てのデータがインポートできませんでした。エラーログを確認してください。",
        add : {
            keep : "保存",
            delete : "削除",
            restoration : "復元",
            close_up : "閉じる",
            with_updates : "(更新あり)",
            please_enter : "を入力してください。",
            data_registered : "データを登録しました。",
            updated_data : "データを更新しました。",
            detail : "詳細",
            priority : "優先順位",
            update_flag : "更新フラグ",
            no_update : "（更新なし）",
            password_update_date : "パスワード更新日",
            update_date : "更新日",
            table_name : "マスター名",
        }
    }
};
