import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { createHttpParams } from "src/app/blocks/utils/http-helper";
import { environment } from "src/environments/environment";
import { UserListModel } from "./../models/user-list-model";

@Injectable({ providedIn: "root" })
export class UserManagerService {
    constructor(private http: HttpClient) { }

    getUserList(param) {
        let p: any;
        const fieldSearch = param.search_by_field;
        const searchKeyword = encodeURIComponent(param.search);
        const params = {
            page: param.page,
            page_size: param.numberPerPage,
            order_by: param.order_by,
            order_direction: param.order_direction,
            search_organization: param.search_organization,
            search_update_flag: param.search_update_flag,
            is_expired_date: param.isExpiredDate,
            ExpiredPassword: param.ExpiredPassword,
            LockFlag: param.LockFlag,
            DeleteFlag: param.DeleteFlag,
            check_sub_organization: param.check_sub_organization,
            UpdateUserID: param.UpdateUserID,
            search_role: param.search_role,
            delete_flags_1: param.delete_flags_1,
            delete_flags_0: param.delete_flags_0,
            isUpdateFlags: param.isUpdateFlags,
            selectedFieldDate: param.selectedFieldDate,
            input_date_end: param.input_date_end,
            input_date_start: param.input_date_start,
            count_group: param.count_group ? 1 : 0,
            // EffectiveDate: param.EffectiveDate,
            // UpdateDate: param.UpdateDate,
            managed_org: param.managedOrg,
            fieldSearch,
        };

        if (fieldSearch !== "" && searchKeyword !== "") {
            params[fieldSearch] = searchKeyword;
        }

        // if (param.selectedFieldDate !== "" && param.searchDate ) {
        //      params[param.selectedFieldDate ] = param.searchDate;
        // }

        return this.http
            .get(`${environment.apiUrl}/api/v1/user/get_list`, { params: createHttpParams(params)})
            .pipe(
                map(data => {
                    const users = data["data"]["items"].map(user => user);
                    const total = data["data"]["total"];
                    const fields = data["data"]["fields"];
                    const validations = data["data"]["validations"];
                    const required_fields = data["data"]["required_fields"];
                    // const pagination = data['meta'].pagination;
                    return { users, total, fields, validations, required_fields };
                })
            );
    }

    resetPass(params) {
        const token = params.token;
        const password = params.password;
        const confirm_password = params.confirm_password;
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/update_password`, {
                password,
                confirm_password,
                token
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    bulkDelete(params) {
        const ids = params.ids;
        return this.http.post(`${environment.apiUrl}/api/v1/user/bulk_delete`, {ids});

    }

    checkExpireUrl(token) {
        const params = createHttpParams({
            token
        });
        return this.http
            .get<any>(`${environment.apiUrl}/api/v1/check_expire_token`, { params })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    getConditionToSearch() {
        return this.http
      .get(`${environment.apiUrl}/api/v1/user/get_conditions`)
      .pipe(
        map(data => {
          const field = data['data']['fields'];
          const extractionIds = data['data']['extractionIds'];
          const organization = data['data']['organizations'];
          const attribute = data['data']['attributes'];
          const attributeMultipleSelect =
            data['data']['attribute_multiple_selects'];
          const admins = data['data']['admins'];
          const role = data['data']['saas'];
          const setting = data['data']['setting'];
          const conditionField = data['data']['condition_field'];
          const systemSettingFieldID = data['data']['system_setting_field_id'];
          const configSystemSettingFieldsID =
            data['data']['config_system_setting_fields_id'];
          const requiredFields = data['data']['required_fields'];
          const isShowUpdateFLag = data['data']['isShowUpdateFlag'];
          const functions = data['data']['functions'];
          const allOrgs = data['data']['all_orgs'];
          const listGroup = data['data']['list_group'];
          // const users = data['data']['items'].map(user => new UserListModel(user));
          // const pagination = data['meta'].pagination;
          return {
            field,
            extractionIds,
            organization,
            attribute,
            admins,
            role,
            setting,
            conditionField,
            systemSettingFieldID,
            configSystemSettingFieldsID,
            requiredFields,
            functions,
            isShowUpdateFLag,
            allOrgs,
            listGroup,
            attributeMultipleSelect,
          };
        })
      );
    }

    createUser(data) {
        return this.http.post(`${environment.apiUrl}/api/v1/user/create`, data);
    }

    updateUser(data) {
        return this.http.post(`${environment.apiUrl}/api/v1/user/update`, data);
    }

    getPermission() {
        return this.http.get(`${environment.apiUrl}/api/v1/user/get_permissions`, {});
    }

    downloadTemplate() {
        return this.http.get(`${environment.apiUrl}/api/v1/user/download`, {
            observe: "response",
            responseType: "blob" as "json"
        });
    }

    importCsv(file, delimiter) {
        const formData = new FormData();
        formData.append('file', file);
        const params = {
            delimiter: delimiter,
        }
        return this.http
            .post(`${environment.apiUrl}/api/v1/user/import`, formData, {
                params:  createHttpParams(params)})
            .pipe(
                map(data => {
                    const totalRow = data['data']['totalRow']
                    const error = data['data']['error']
                    return {totalRow, error};
                })
            );
    }

    extractCsv(param, newParams) {
        let p: any;
        const fieldSearch = param.search_by_field;
        const searchKeyword = encodeURIComponent(param.search);
        const params = {
            name_file: newParams.name_file,
            end_character: newParams.end_character,
            page: param.page,
            page_size: param.numberPerPage,
            order_by: param.order_by,
            order_direction: param.order_direction,
            search_organization: param.search_organization,
            search_update_flag: param.search_update_flag,
            is_expired_date: param.isExpiredDate,
            ExpiredPassword: param.ExpiredPassword,
            LockFlag: param.LockFlag,
            DeleteFlag: param.DeleteFlag,
            check_sub_organization: param.check_sub_organization,
            UpdateUserID: param.UpdateUserID,
            search_role: param.search_role,
            delete_flags_1: param.delete_flags_1,
            delete_flags_0: param.delete_flags_0,
            isUpdateFlags: param.isUpdateFlags,
            selectedFieldDate: param.selectedFieldDate,
            input_date_end: param.input_date_end,
            input_date_start: param.input_date_start,
            // EffectiveDate: param.EffectiveDate,
            // UpdateDate: param.UpdateDate,
            managed_org: param.managedOrg
        };

        if (fieldSearch !== "" && searchKeyword !== "") {
            params[fieldSearch] = searchKeyword;
        }

        // if (param.selectedFieldDate !== "" && param.searchDate ) {
        //      params[param.selectedFieldDate ] = param.searchDate;
        // }
        return this.http.get(`${environment.apiUrl}/api/v1/user/extract`, {
            params:  createHttpParams(params),
            observe: "response",
            responseType: "blob" as "json"
        });
    }

    getUserDetail(userId) {
        return this.http
            .get(`${environment.apiUrl}/api/v1/user/${encodeURIComponent(userId)}/show`)
            .pipe(
                // map(user => user["data"]),
                map(data => {
                    return data;
                })
            );
    }

    deleteUser(userId) {
      return this.http
        .delete(`${environment.apiUrl}/api/v1/user/${encodeURIComponent(userId)}/delete`);
    }
}
