export class Common {
  public static EMAIL_PATTERN =
    /^[a-zA-Z0-9.!#$%&‘+\/=?^_`{|}~-]+@(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  public static DATE_FORMAT = "yyyy/MM/dd";
  public static PASSWORD_FORMAT = /!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?¥¯/;
  public static EXPIRED_DATE_ADMIN = 15; //minutes
  public static REDIRECT_CODE = {
    "wf-confirm": "workflow/confirm-application",
    "wf-approve": "workflow/approve-application",
  };
  public static REGEX = {
    HIRAGANA_FULL_WIDTH: /^[ぁ-ん]*$/,
    KATAKANA_FULL_WIDTH: /^[ァ-ンー]*$/,
    KATAKANA_HALF_WIDTH: /^[ｧ-ﾝﾞﾟ]*$/,
    KANJI: /^[一-龥]*$/,
    JP_CHAR_NUMBER: /^[A-Za-zぁ-んァ-ンー一-龥ｧ-ﾝﾞﾟ0-9]*$/,
    JP_CHAR_NUMBER_SPACE: /^[A-Za-zぁ-んァ-ンー一-龥ｧ-ﾝﾞﾟ0-9 　]*$/,
    CHAR_NUMBER: /^[A-Za-z0-9]*$/,
    CHAR_NUMBER_SPACE: /^[A-Za-z0-9 　]*$/,
    KEY_INI: /^[a-zA-Z0-9\_\.\-]*$/,
    KEY_ATTRIBUTE: /^\([a-zA-Z0-9\_\.\-]*\)$/,
  };
  public static TYPE_USE_CODE = {
    SCREEN_DISPLAY_SETTING: 'screen_display_setting',
    MAPPING_SETTING: 'mapping_setting'
  }
  public static SUMMARY_PROCESS = {
    NONE: 0,
    SUCCESS: 1,
    ERROR: 2,
    WAITTING: 3
  };
  public static PAGE_FLAGS = {
    USER: 2,
    ADMIN: 1,
    OTHER: 0
  };
  public static LOCALE_DEFAULT = 'ja';
  public static STORAGE_KEY = {
    USER_LOCALE: 'user.locale',
    USER_LANG: 'user.lang',
    ADMIN_LOCALE: 'admin.locale',
    ADMIN_LANG: 'admin.lang'
  };
  public static HTTP_NOT_ALLOW_ACCESS = 460;
  public static HTTP_EXISTED_DEFAULT_IDP = 461;
}
