export const dashboard = {
  nameScreen: 'Dashboard',
  numberOfRecord: 'Number of Records',
  user: 'Users',
  userUseLicense: 'License consumption rate',
  before: 'before',
  other: 'Others',
  organization: 'Organization',
  group: 'Group',

  processing_result_summary : "Processing result summary",
  compare_difference : "compare difference",
  last_sync_time : "Last Sync Time",
  total : "Total",
  create : "Create",
  update : "Update",
  delete : "Delete",
  error : "Error",
  more : "More",
  one_week : "One week",
  one_month : "One month",
  summary : "summary",


  result_summary : "Result Summary",
  view_log : "View log",
  start_datetime : "Start DateTime",
  end_datetime : "End DateTime",
  success : "Success",
  processing_logs : "Processing Logs",

  select_date : "Select Date",
  processing_chart : "Processing chart",
  comparison_method : "Comparison method",
  csv_file_upload : "CSV File Upload",
  auto : "Auto",
  user_attribute_as_attribute : "User attribute as comparison key",
  start : "Start",
  exported : "Exported",
  privileged : "Privileged"

};
