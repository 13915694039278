import { NgModule,ModuleWithProviders } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TableModule } from "primeng/table";
import { PaginatorModule } from "primeng/paginator";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService } from "primeng/api";
import { CardModule } from "primeng/card";
import { CheckboxModule } from "primeng/checkbox";
import { RatingModule } from "primeng/rating";
import { FieldsetModule } from "primeng/fieldset";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { CalendarModule } from "primeng/calendar";
import { TooltipModule } from "primeng/tooltip";
import { TabViewModule } from "primeng/tabview";
import { FileUploadModule } from "primeng/fileupload";
import { AccordionModule } from "primeng/accordion";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { SidebarModule } from "primeng/sidebar";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SelectButtonModule } from "primeng/selectbutton";
import { CountdownModule } from "ngx-countdown";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MultiSelectModule } from "primeng/multiselect";
import { DropdownModule } from "primeng/dropdown";
import { InputSwitchModule } from "primeng/inputswitch";
import { TranslateModule } from "@ngx-translate/core";
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
@NgModule({
  imports: [
    NgbModule,
    TranslateModule,
    TableModule,
    PaginatorModule,
    ToastModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    CardModule,
    CheckboxModule,
    RatingModule,
    FieldsetModule,
    ScrollPanelModule,
    CalendarModule,
    TooltipModule,
    TabViewModule,
    FileUploadModule,
    AccordionModule,
    VirtualScrollerModule,
    SidebarModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    DropdownModule,
    SelectButtonModule,
    CountdownModule,
    InputSwitchModule,
    CodemirrorModule
  ],
  exports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TableModule,
    PaginatorModule,
    ToastModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    CardModule,
    CheckboxModule,
    RatingModule,
    FieldsetModule,
    ScrollPanelModule,
    CalendarModule,
    TooltipModule,
    TabViewModule,
    FileUploadModule,
    AccordionModule,
    VirtualScrollerModule,
    SidebarModule,
    ProgressSpinnerModule,
    SelectButtonModule,
    MultiSelectModule,
    DropdownModule,
    CountdownModule,
    NgbModule,
    InputSwitchModule,
  ],
  providers: [MessageService, ConfirmationService],
})
export class SharedLibsModule {
  static forRoot(): ModuleWithProviders<SharedLibsModule> {
    return {
        ngModule: SharedLibsModule,
    };
}
}
