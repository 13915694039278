import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { AutoComplete } from "primeng";
import { ISelectOption } from "src/app/core/models/select-option.model";

@Component({
  selector: "incremental-search",
  templateUrl: "./incremental-search.html",
  styleUrls: ["./incremental-search.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class IncrementalSearch implements OnInit, OnDestroy, OnChanges {
  @ViewChild("incrementalSearch") incrementalSearch: AutoComplete;
  @Input() value: ISelectOption;
  @Input() options: ISelectOption[];
  @Input() delay: number = 500;
  @Input() disabled: boolean = false;
  @Input() styleClass: string;
  @Input() clearAfterSelect: boolean = false;
  @Output() valueChange = new EventEmitter();
  @Output() onSearch = new EventEmitter();

  _styleClass: string = "incremental-search";
  _selectOptions: any[] = [];

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
  }

  ngOnDestroy(): void {}

  updateData() {
    // selectOptions
    if (this.options && this.options.length) {
      this._selectOptions = [...this.options];
    } else {
      this._selectOptions = [];
    }
    // styleClass
    const classList = ["incremental-search"];
    if (this.styleClass) {
      classList.push(this.styleClass);
    }
    this._styleClass = classList.join(" ");
  }

  handleCompleteMethod(event: any) {
    // TODO implement timeout
    // TODO implement cancelable
    this.onSearch.emit(event.query);
  }

  handleValueChange() {
    if (!(this.value && this.value.ID)) {
      this.valueChange.emit(null);
    }
  }

  handleOnFocus(event: any) {
    if (this.value && this._selectOptions && this._selectOptions.length) {
      this._selectOptions = [...this._selectOptions];
      this.incrementalSearch.show();
    }
  }
  clearItem(event){
    let input:any = document.querySelector('.ui-autocomplete-input');
    input.blur();
    input.focus();
  }
  handleSelect() {
    this.valueChange.emit(this.value);
    if (this.clearAfterSelect) {
      setTimeout(() => {
        this.value = undefined;
        let input:any = document.querySelector('.ui-autocomplete-input');
        input.blur();
      }, 0);
    }
  }
}
