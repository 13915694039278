import {Component, OnInit, Renderer2, RendererFactory2} from "@angular/core";
import { LoadingService } from "./service/loading";

@Component({
  selector: "loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.css"],
})
export class LoadingComponent implements OnInit{
  private renderer: Renderer2;
  loading: boolean = false;
  constructor(
    private loadingService: LoadingService,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  ngOnInit() {
    this.loadingService.loading.subscribe((loading: boolean) => {
      if (loading) {
        this.renderer.addClass(document.body, 'overflow-hidden');
      } else {
        this.renderer.removeClass(document.body, 'overflow-hidden');
      }
      this.loading = loading;
    });
  }
}
