export const change_password = {
  change_admin_password : '管理者パスワード変更',
  password_after_change : '変更後のパスワード',
  please_enter_your_password : 'パスワードを入力してください',
  for_confirmation : '確認用',
  please_enter_a_confirmation_password : '確認用パスワードを入力してください',
  change_the_password : 'パスワードを変更する',
  please_enter_the_password_for_confirmation : '確認用パスワードを入力してください。',
  passwords_do_not_match : 'パスワードが一致していません。',
  failed_to_change_password : 'パスワードの変更に失敗しました。',
  password_changed : 'パスワードを変更しました。',
};