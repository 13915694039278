export const setting_permission_user = {
    select_permissions : '権限を選択',
    reflect : '反映',
    item_name : '項目名',
    browse : '閲覧',
    edit : '編集',
    no_matching_data_found : '該当データが見つかりません',
    acquiring_data : 'データ取得中',
    user_screen_settings : '利用者画面設定',
    user_maintenance_for_users : 'ユーザー管理情報',
    workflow : 'ワークフロー',
    profile_information : 'プロファイル',
    updated_data : 'データを更新しました。',
    none: 'なし',
    this_is_a_required_item_and_cannot_be_changed: '必須項目のため変更できません。',
    it_is_set_to_be_editable_and_cannot_be_changed: '編集可能に設定しているため変更できません。', 
    data_can_not_found: '利用者ユーザー管理の権限がすべて無効になっています。Keyspider基本設定で権限を追加してください。'
};
  