import { ValidationErrors, ValidatorFn, AbstractControl } from "@angular/forms";

export class CustomValidators {
  static patternValidator(
    regexArr,
    error: ValidationErrors,
    number?
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let count = 0;
      if (!control.value) {
        return null;
      }

      for (const regex of regexArr) {
        if (control.value.match(regex)) {
          count++;
        }
        if (count === number) {
          return null;
        }
      }
      return error;
    };
  }
}
